<!-- <div class="container">
    <div class="col-lg-12 col-md-6 " style="margin-left:9%;">
        <div class="card">
            <div class="card-header card-header-warning">
                <h4 class="card-title"> Visitor</h4>
                <p class="card-category">New employees on 15th September, 2016</p>
            </div>
            <div class="card-body table-responsive text-center">
                <table class="table table-hover">
                    <thead class="text-warning">
                        <th>No</th>
                        <th>Name</th>
                        <th>Mail Id</th>
                        <th>Phone No</th>
                        <th>briefcase</th>
                        <th>Create</th>
                        <th>View</th>
                        <th>Block</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>

                            <td class="fa fa-briefcase fa-2x"></td>
                            <td class="fa fa-plus-circle fa-2x"></td>
                            <td class="fa fa-pencil-square-o fa-2x"></td>
                            <td class="fa fa-ban fa-2x"></td>

                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                            <td class="fa fa-briefcase fa-2x"></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                            <td class="fa fa-briefcase fa-2x"></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                            <td class="fa fa-briefcase fa-2x"></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                            <td class="fa fa-briefcase fa-2x"></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>





    <div class="col-lg-12 col-md-6" style="margin-left:9%;">
        <div class="card">
            <div class="card-header card-header-warning">
                <h4 class="card-title">Visitor Request</h4>
                <p class="card-category">New employees on 15th September, 2016</p>
            </div>
            <div class="card-body table-responsive text-center">
                <table class="table table-hover">
                    <thead class="text-warning">
                        <th>No</th>
                        <th>Name</th>
                        <th>Mail Id</th>
                        <th>Phone No</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>

                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>


    <div class="col-lg-12 col-md-6" style="margin-left:9%;">
        <div class="card">
            <div class="card-header card-header-warning">
                <h4 class="card-title">Today Activity</h4>
                <p class="card-category">New employees on 15th September, 2016</p>
            </div>
            <div class="card-body table-responsive text-center">
                <table class="table table-hover">
                    <thead class="text-warning">
                        <th>No</th>
                        <th>Time</th>
                        <th>Title</th>

                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>

                            <td>10.00 am - 11.00am</td>
                            <td>Event Title</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>10.00 am - 11.00am</td>
                            <td>Event Title</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>10.00 am - 11.00am</td>
                            <td>Event Title</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>10.00 am - 11.00am</td>
                            <td>Event Title</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>10.00 am - 11.00am</td>
                            <td>Event Title</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>




    <div class="col-lg-12 col-md-6" style="margin-left:9%;">
        <div class="card">
            <div class="card-header card-header-warning">
                <h4 class="card-title">Live and Webinar</h4>
                <p class="card-category">New employees on 15th September, 2016</p>
            </div>
            <div class="card-body table-responsive text-center">
                <table class="table table-hover">
                    <thead class="text-warning">
                        <th>No</th>
                        <th>Event Name</th>
                        <th>Desctription</th>
                        <th>Url</th>
                        <th>Uploaded image</th>
                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>

                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                            <td>img</td>
                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                            <td>img</td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                            <td>img</td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                            <td>img</td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Name</td>
                            <td>Event Title</td>
                            <td>Phone No</td>
                            <td>img</td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div> -->
<router-outlet></router-outlet>
<!-- <h1>asdads</h1> -->