import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/auth.service';


export interface DialogDatacp {
  email : string
  id:string
  
}
@Component({
  selector: 'app-createpopupsadex',
  templateUrl: './createpopupsadex.component.html',
  styleUrls: ['./createpopupsadex.component.scss']
})
export class CreatepopupsadexComponent implements OnInit {

  email=this.data.email;
  password="";
  message = '';
  errorMessage = ''; // validation error handle
  error: { name: string, message: string } = { name: '', message: '' }; // for firbase error handle

  constructor( @Inject(MAT_DIALOG_DATA) public data: DialogDatacp,public dialog: MatDialog, private authservice: AuthService) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialog.closeAll();
  }

  clearErrorMessage()
  {
    this.errorMessage = '';
    this.error = {name : '' , message:''};
  }

  register()
  {
    this.clearErrorMessage();
    if (this.validateForm(this.email, this.password)) {
      this.authservice.registerWithEmailforex(this.email, this.password,this.data.id["id"])
        .then(() => {
          this.message = "User  Registered"
         

        }).catch(_error => {
          this.error = _error
        })
    }
  }

  validateForm(email, password)
  {
    if(email.lenght === 0)
    {
      this.errorMessage = "please enter email id";
      return false;
    }

    if (password.lenght === 0) {
      this.errorMessage = "please enter password";
      return false;
    }

    if (password.lenght < 6)
    {
      this.errorMessage = "password should be at least 6 char";
      return false;
    }

    this.errorMessage = '';
    return true;

  }
}
