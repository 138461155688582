import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { CountService } from 'src/app/service/count.service';
import { CrudService } from 'src/app/service/crud.service';
import { StallService} from 'src/app/service/stall.service'
import {Location} from '@angular/common';
@Component({
  selector: 'app-pfheader',
  templateUrl: './pfheader.component.html',
  styleUrls: ['./pfheader.component.scss']
})
export class PfheaderComponent implements OnInit {

  customer: any;
  vis: any;
  count: number;


  constructor( private afu: AngularFireAuth, private _location: Location,public countservice:CountService  ,private actRoute: ActivatedRoute,public StallService:StallService,public authservice: AuthService,public crudservice:CrudService,private router: Router) { }

   id="OeUx7yk4uEK2k8e4rfk6";
  ngOnInit(): void {
    this.id = this.actRoute.snapshot.paramMap.get('id');

    this.StallService.visitorcount(this.id)
    .get().toPromise()
    .then(query => this.count=query.size);



    // this.countservice.get_visitor(this.id).then(data => {
    //   this.vis=data
    //   this.countservice.update_visitor(data,this.id,true)
    // })
    this.crudservice.get_Allcustomer().subscribe(data => {
      this.customer = data.map(e => {
        return {
          id: e.payload.doc.id,
          email: e.payload.doc.data()['email'],
        };})
  })
 }

 backClicked() {
  this._location.back();
}

check()
{
  
  var name=localStorage.getItem('username');
  if (name==null){
    this.router.navigate(['/login'])
  }
  else{
    this.router.navigate(['/profile'])
  
    
  }  
}

close(url){


  this.countservice.get_visitor(this.id).then(data => {
    this.countservice.update_visitor(data,this.id,false)
    window.location.replace(url);
  })


}
checkout(){
  this.afu.signOut().then(function() {
    // this.router.navigate('/home')
    window.location.assign("/home")
    // Sign-out successful.
  }, function(error) {
    // An error happened.
  });
}
}
