// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyDY0tZ1FcQf5rXRryUeFXkS0jXxzzLT5hA",
    authDomain: "angboot-49fb1.firebaseapp.com",
    databaseURL: "https://angboot-49fb1.firebaseio.com",
    projectId: "angboot-49fb1",
    storageBucket: "angboot-49fb1.appspot.com",
    messagingSenderId: "630541667366",
    appId: "1:630541667366:web:f27a5c35ea0b41e7b22ae7",
    measurementId: "G-EX1X9B8TWP"
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
