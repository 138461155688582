<!-- <div class="container" style="margin-top:15%;">
    <form action="/action_page.php">
        <div class="row">
            <div class="col-25">
                <label for="fname">First Name</label>
            </div>
            <div class="col-75">
                <input type="text" id="fname" name="firstname" placeholder="Your name..">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="lname">Last Name</label>
            </div>
            <div class="col-75">
                <input type="text" id="lname" name="lastname" placeholder="Your last name..">
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="country">Country</label>
            </div>
            <div class="col-75">
                <select id="country" name="country">
            <option value="australia">Australia</option>
            <option value="canada">Canada</option>
            <option value="usa">USA</option>
          </select>
            </div>
        </div>
        <div class="row">
            <div class="col-25">
                <label for="subject">Subject</label>
            </div>
            <div class="col-75">
                <textarea id="subject" name="subject" placeholder="Write something.." style="height:200px"></textarea>
            </div>
        </div>
        <div class="row">
            <input type="submit" value="Submit">
        </div>
    </form>
</div> -->

<h1 class="text-center">Chat Now!</h1>
<div class="container">
    <img src="https://firebasestorage.googleapis.com/v0/b/angboot-49fb1.appspot.com/o/bandmember.jpg?alt=media&token=f7eaf6d3-73f8-43ba-884c-dac476344b64" alt="Avatar">
    <p>Hi sir?</p>
    <span class="time-right">11:00</span>
</div>

<div class="container darker">
    <img src="https://firebasestorage.googleapis.com/v0/b/angboot-49fb1.appspot.com/o/avatar_g2.jpg?alt=media&token=1d3460af-077b-4bf9-a8d1-440fdf95c3f9" alt="Avatar" class="right">
    <p>How Can I Help You?</p>
    <span class="time-left">11:01</span>
</div>


<form class="form-container">
    <label for="name"></label>
    <input type="text" placeholder="Chat Here" name="Chat" required>
    <button type="submit" class="btn">Submit</button>
</form>