<div class="container" style="background:none;height:auto;margin-top:5%;">


    <div class=" col-lg-12 col-md-6 " style="margin-left:9%; ">
        <div class="card ">
            <div class="card-header card-header-warning ">
                <h2 class="card-title ">Attendees Count</h2>
                <h4 class="card-category " style="color:white; ">Total No of Attendies : 34</h4>
            </div>
            <div class="card-body table-responsive text-center table-wrapper-scroll-y my-custom-scrollbar ">
                <table class="table table-hover ">
                    <thead class="text-warning ">
                        <th>No</th>
                        <th>Stall Name</th>
                        <th>No of Visitor</th>
                        <th>Visitor Count</th>
                        <th>View</th>

                    </thead>
                    <tbody>
                        <tr>
                            <td>1</td>
                            <td>Name</td>
                            <td>9876543210</td>
                            <td>6</td>
                            <td><i class="fa fa-pencil-square-o fa-2x "></i></td>


                        </tr>
                        <tr>
                            <td>2</td>
                            <td>Name</td>
                            <td>9876543210</td>
                            <td>6</td>
                            <td><i class="fa fa-pencil-square-o fa-2x "></i></td>
                        </tr>
                        <tr>
                            <td>3</td>
                            <td>Name</td>
                            <td>9876543210</td>
                            <td>6</td>
                            <td><i class="fa fa-pencil-square-o fa-2x "></i></td>
                        </tr>
                        <tr>
                            <td>4</td>
                            <td>Name</td>
                            <td>9876543210</td>
                            <td>6</td>
                            <td><i class="fa fa-pencil-square-o fa-2x "></i></td>
                        </tr>
                        <tr>
                            <td>5</td>
                            <td>Name</td>
                            <td>9876543210</td>
                            <td>6</td>
                            <td><i class="fa fa-pencil-square-o fa-2x "></i></td>
                        </tr>

                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>