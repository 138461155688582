<app-exheader></app-exheader>

<div class="container " style=" margin-top: 100px;">
    <div id='checkdetails' class="card">
        <div class="card-header card-header-warning" style="padding:0px 10px;">
            <h1 class="card-title">Enter Cridentials </h1>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
        </div>
        <div class="card-body table-responsive">

            <div class="container " style=" margin-top: 10px;">

                <div class="row">

                    <form (ngSubmit)="check()">
                        <div class="form-group col-md-12">
                            <label style="font-weight: 800;">Ex Name</label>
                            <input type="text" class="form-control" name="exname" [(ngModel)]="exname">
                        </div>

                        <div class="form-group col-md-12">
                            <label style="font-weight: 800;">ex Number</label>
                            <input type="text" class="form-control" name="exnumber" [(ngModel)]="exnumber">

                        </div>




                        <div class="col text-center" style="margin-top: 10px; margin-bottom: 10px;">
                            <button type="submit" class="btn buttoncolor" style="background-color:#fe9d18; ">Submit</button>
                        </div>

                    </form>

                </div>



            </div>


        </div>

    </div>
       
    <div id="already" style="display:none " >    <div class="card-header card-header-warning" style="padding:0px 10px;">
        <h1 class="card-title">Already have stall </h1>
        <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
    </div></div>




    <div id="upload" style="display:none ">

        <form (ngSubmit)="upload()">
            <div class="col-lg-12 col-md-12 ">
                <div class="card ">
                    <div class="card-header card-header-warning " style="padding:0px 10px; ">
                        <h1 class="card-title ">Stall </h1>
                        <!-- <p class="card-category ">New employees on 15th September, 2016</p> -->
                    </div>
                    <div class="card-body table-responsive ">

                        <div class="container " style=" margin-top: 10px; ">

                            <div class="row ">


                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; ">Stall Name</label>
                                    <input type="text " class="form-control " name="sname" [(ngModel)]="sname">
                                </div>

                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; ">Stall Number</label>
                                    <input type="text " class="form-control " name="snumber" [(ngModel)]="snumber">

                                </div>



                                <!-- <div class="col text-center " style="margin-top: 10px; margin-bottom: 10px; ">
                                <button type="submit " class="btn buttoncolor " style="background-color:#fe9d18; ">Submit</button>
                            </div> -->
                            </div>



                        </div>


                    </div>

                </div>



            </div>

            <div class="container " style="padding:0px; ">

                <div class="col-lg-12 col-md-12 ">
                    <div class="card ">
                        <div class="card-header card-header-warning " style="padding:0px 10px; ">
                            <h1 class="card-title ">Stall Details</h1>
                            <!-- <p class="card-category ">New employees on 15th September, 2016</p> -->
                        </div>
                        <div class="card-body table-responsive ">

                            <div class="container " style=" margin-top: 10px;overflow:hidden; ">

                                <div class="row ">


                                    <div class="form-group col-md-12 ">
                                        <label style="font-weight: 800; ">Title</label>
                                        <input type="text " class="form-control " name="stitle" [(ngModel)]="stitle">
                                    </div>

                                    <div class="form-group col-md-12 ">
                                        <label style="font-weight: 800; ">Description</label>
                                        <input type="text " class="form-control " name="sdesc" [(ngModel)]="sdesc">

                                    </div>
                                    <!-- <div class="form-group col-md-12 ">
                                        <label style="font-weight: 800; ">Upload Files</label>
                                        <div class="input-group ">
                                            <div class="form-group col-12">
                                                <button (click)="hiddenfileinput.click()" class="waves-effect waves-light btn"><i class="material-icons left">cloud</i>Select PDF</button>

                                                <input type="file" id="file" #userPhoto (change)="onFileSelected($event)" name="image" autocomplete="off" #hiddenfileinput/>
                                            </div>
                                           

                                        </div>




                                        
                                    </div> -->
                                    <!-- <div class="form-group col-md-12 ">
                                        <input type="checkbox " id="vehicle1 " name="vehicle1 " value="Bike ">
                                        <label for="vehicle1 " style="padding-left:10px;font-weight:bold; "> For Secure</label>
                                    </div> -->

                                </div>

                                <!-- <div class="col text-center " style="margin-top: 10px; margin-bottom: 10px; ">
                                <button type="submit " class="btn buttoncolor " style="background-color:#fe9d18; ">Submit</button>
                            </div> -->
                            </div>



                        </div>


                    </div>

                </div>
                <div style="margin-bottom: 100px; color: white; ">
                    .
                </div>





              



                <div class="col-lg-12 col-md-12 " style="margin-top:40px;margin-bottom:100px; ">
                    <div class="card ">
                        <div class="card-header card-header-warning " style="padding:0px 10px; ">
                            <h1 class="card-title ">Social Media</h1>
                            <!-- <p class="card-category ">New employees on 15th September, 2016</p> -->
                        </div>
                        <div class="card-body table-responsive ">

                            <div class="container " style=" margin-top: 10%;overflow:hidden; ">

                                <div class="row ">



                                    <div class="form-group col-md-12 ">

                                        <div class="input-group socialicons ">

                                            <i class=" fa fa-instagram icon " style="font-size:24px;margin-right:10%; "></i>
                                        </div>


                                        <input type="text " class="form-control " placeholder="Instagram Link " name="insta" [(ngModel)]="insta">
                                    </div>

                                    <div class="form-group col-md-12 ">

                                        <div class="input-group socialicons ">

                                            <i class=" fa fa-youtube icon " style="font-size:24px;margin-right:10%; "></i>
                                        </div>


                                        <input type="text " class="form-control " placeholder="Youtube Link " name="youtube" [(ngModel)]="youtube">
                                    </div>


                                    <div class="form-group col-md-12 ">

                                        <div class="input-group socialicons">

                                            <i class=" fa fa-video-camera icon " style="font-size:24px;margin-right:10%; "></i>
                                        </div>


                                        <input type="text " class="form-control " placeholder="Zoom Link" name="zoom" [(ngModel)]="zoom">
                                    </div>
                                    <div class="form-group col-md-12 ">

                                        <div class="input-group socialicons ">

                                            <i class=" fa fa-whatsapp icon " style="font-size:24px;margin-right:10%; "></i>
                                        </div>


                                        <input type="text " class="form-control " placeholder="Whatsapp Link " name="whatsapp" [(ngModel)]="whatsapp">
                                    </div>
                                    <div class="form-group col-md-12 ">

                                        <div class="input-group socialicons">

                                            <i class=" fa fa-facebook icon " style="font-size:24px;margin-right:10%; "></i>
                                        </div>


                                        <input type="text " class="form-control " placeholder="Facebook Link " name="facebook" [(ngModel)]="facebook">
                                    </div>
                                    <div class="form-group col-md-12 ">

                                        <div class="input-group socialicons">

                                            <i class="fa fa-linkedin icon " style="font-size:24px;margin-right:10%; "></i>
                                        </div>


                                        <input type="text " class="form-control " placeholder="Linkedin Link " name="linkedin" [(ngModel)]="linkedin">
                                    </div>

                                    <div class="form-group col-md-12 ">

                                        <div class="input-group socialicons">

                                            <i class="fa fa-twitter icon " style="font-size:24px;margin-right:10%; "></i>
                                        </div>


                                        <input type="text " class="form-control " placeholder="twitter Link " name="twitter" [(ngModel)]="twitter">
                                    </div>



                                    <div class="col text-center " style="margin-top: 10px; margin-bottom: 10%; ">
                                        <button type="submit " class="btn buttoncolor " style="background-color:#fe9d18; ">Save</button>
                                    </div>
                                </div>


                            </div>



                        </div>


                    </div>

                </div>


















            </div>

        </form>

    </div>


    <app-exfooter></app-exfooter>