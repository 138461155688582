import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  authState: any = null;

  constructor(
    private afu: AngularFireAuth,
    private router: Router,
    public fireservices: AngularFirestore
  ) {
    this.afu.authState.subscribe((auth) => {
      this.authState = auth;
    });
  }

  // all firebase getdata functions

  getAuth() {
    return this.afu;
  }

  get isUserAnonymousLoggedIn(): boolean {
    return this.authState !== null ? this.authState.isAnonymous : false;
  }

  get currentUserId(): string {
    return this.authState !== null ? this.authState.uid : '';
  }

  get currentUserName(): string {
    // return this.authState['email']
    const username =
      this.authState !== null ? this.authState['email'] : 'Guest';
    if (username != 'Guest') localStorage.setItem('username', username);
    return username;
  }

  get currentUser(): any {
    return this.authState !== null ? this.authState : null;
  }

  get isUserEmailLoggedIn(): boolean {
    if (this.authState !== null && !this.isUserAnonymousLoggedIn) {
      return true;
    } else {
      return false;
    }
  }

  registerWithEmail(email: string, password: string, id: string) {
    return this.afu
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        this.fireservices.collection('vrcustomer/').doc(id).update({
          password: password,
        });
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  registerWithEmailforex(email: string, password: string, id: string) {
    return this.afu
      .createUserWithEmailAndPassword(email, password)
      .then((user) => {
        this.fireservices
          .collection('exhibitor/')
          .doc(id)
          .update({
            password: password,
          })
          .then(new alert('registered'));
      })
      .catch((error) => {
        console.log(error);
        throw error;
      });
  }

  createuser(obj) {
    this.fireservices.collection('vrcustomer').add(obj);
  }

  createexhibitor(obj) {
    return this.fireservices.collection('exhibitor').add(obj);
  }
  createoverseas(obj) {
    return this.fireservices.collection('vrcustomer').add(obj);
  }
  createagent(obj) {
    return this.fireservices.collection('vrcustomer').add(obj);
  }
  createdomestic(obj) {
    return this.fireservices.collection('vrcustomer').add(obj);
  }

  loginWithCustomerEmail(email: string, password: string) {
    return this.afu
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        console.log(user, 'vrcustomer details');
        this.authState = user;

        this.fireservices
          .collection('vrcustomer', (ref) =>
            ref.where('email', '==', user.user.email)
          )
          .snapshotChanges()
          .subscribe((data) => {
            localStorage.setItem('uid', data[0].payload.doc.id);
            this.fireservices
              .collection('vrcustomer')
              .doc(data[0].payload.doc.id)
              .update({
                uid: data[0].payload.doc.id,
              });
          });
      })
      .catch((error) => {
        alert(error);
        throw error;
      });
  }

  loginWithEmail(email: string, password: string) {
    return this.afu
      .signInWithEmailAndPassword(email, password)
      .then((user) => {
        this.authState = user;
        this.fireservices
          .collection('exhibitor', (ref) =>
            ref.where('email', '==', user.user.email)
          )
          .snapshotChanges()
          .subscribe((data) => {
            localStorage.setItem('uid', data[0].payload.doc.id);
            this.fireservices
              .collection('exhibitor')
              .doc(data[0].payload.doc.id)
              .update({
                uid: data[0].payload.doc.id,
              });
          });
      })
      .catch((error) => {
        alert(error);
        throw error;
      });
  }

  singout(): void {
    this.afu.signOut();
    this.router.navigate(['/login']);
  }
}
