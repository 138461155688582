import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { StallService} from 'src/app/service/stall.service'
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { StalldocComponent } from '../../stalldoc/stalldoc.component';


@Component({
  selector: 'app-exresource',
  templateUrl: './exresource.component.html',
  styleUrls: ['./exresource.component.scss']
})
export class ExresourceComponent implements OnInit {
  stall: any;

  id: string;
  j=0;
  pin: { pinid: string; pinname: any; pinleft: any; pintop: any; pinyou: any; pinloc: string; }[];


  constructor(public dialog: MatDialog,public StallService:StallService,public fireservices:AngularFirestore,private actRoute: ActivatedRoute,public authservice: AuthService,private router: Router) { }

  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }
  ngOnInit(): void {
    this.id = this.actRoute.snapshot.paramMap.get('id');
    
    
        this.StallService.get_allres().subscribe(data => {
          this.stall = data.map(e => {
            return {
              id: e.payload.doc.id,
              name: e.payload.doc.data()['name'],
              bgimage : e.payload.doc.data()['bgimage'],
              linkedin : e.payload.doc.data()['linkedin'],
              insta : e.payload.doc.data()['insta'],  
              facebook : e.payload.doc.data()['facebook'],
              twitter : e.payload.doc.data()['twitter'], 
              whatsapp : e.payload.doc.data()['whatsapp'], 
              zoom : e.payload.doc.data()['zoom'], 
            };
          })
          for (var i=0;i<this.stall.length;i++){
            console.log(this.stall[i]['id'])
            this.StallService.getallpinforres(this.stall[i]['id']).subscribe(data => {
              this.pin = data.map(e => {
                return {
                  pinid: e.payload.doc.id,
                  pinname: e.payload.doc.data()['name'],
                  pinleft: e.payload.doc.data()['left'],
                  pintop: e.payload.doc.data()['top'],
                  pinyou: e.payload.doc.data()['youtube'],
                  pinloc:" top:"+e.payload.doc.data()['top']+"%;  left:"+ e.payload.doc.data()['left']+"%;"
                };
              })
               
        
              this.stall[this.j].pin=(this.pin);
              this.j=this.j+1; 
              console.log(this.stall)
             
          })}
    
    
        
          // console.log("hi")
          // console.log(this.pin)
        
        })
  }

  openDialog(a,b,c,d): void {
    console.log(a,b,c,d)
    const dialogRef = this.dialog.open(StalldocComponent, {
      width: '900px',
      height: '700px',
      data: {stallid : a , stallname:b , pinid:c ,pinname:d,res:true}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
   
    });
  }
}