<nav class="navbar navbar-expand-lg navbar-light  navbar-default upper-overlay" style="padding: 10px;">
    <a class="navbar-brand" href="#" style="padding-left: 10px;margin-top:12px;">
        <h2>Virtual Expo 2020 Exhibitor Panel</h2>
    </a>


    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
            <!-- <li class="nav-item active">
                <a class="nav-link fa fa-file-text fa-2x" style="padding-left: 20px; padding-right: 20px; font-size-adjust: initial;" aria-hidden="true" href="/exregister" data-toggle="tooltip" data-placement="top" title="Hooray!"></a>
            </li> -->
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" (click)="checkout()" class="nav-link fa fa-sign-out  fa-2x" style="font-weight:bold;color:black;padding-left: 20px; padding-right: 20px;margin-top:10px;" aria-hidden="true"></a>
                <p style="font-size: 10px;margin-top:-10px;margin-left:16%;">Logout</p>
            </li>
            <li class="nav-item active">
                <a href="/home" class=" nav-link fa fa-home fa-2x " style="padding-left: 20px; padding-right: 20px; font-size-adjust: initial; " aria-hidden="true " data-toggle="tooltip " data-placement="top " title="Home "></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link fa fa-arrow-circle-o-left fa-2x " style="padding-left: 10px; padding-right: 20px; " aria-hidden="true " (click)="backClicked() "></a>
            </li>



            <li class="nav-item ">
                <a class="nav-link " aria-hidden="true " class="nav-link fa fa-user-circle-o fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true " (click)="check() "></a>
            </li>
            <!-- {{authservice.currentUserName}} -->
            <!-- (click)="check() " -->



            <!-- <li class="nav-item ">
                <a class="nav-link " aria-hidden="true " class="nav-link fa fa-upload fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true " href="/stalldocupload "></a>
            </li> -->
            <!-- <li class="nav-item active ">
                <a class="nav-link fa fa-home fa-2x " style="padding-left: 20px; padding-right: 20px; font-size-adjust: initial; " aria-hidden="true " href="# " data-toggle="tooltip " data-placement="top " title="Hooray! "></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link fa fa-arrow-circle-o-left fa-2x " style="padding-left: 10px; padding-right: 20px; " aria-hidden="true "></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link fa fa-map-marker fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true "></a>
            </li>
            <li class="nav-item ">
                <a class="nav-link " aria-hidden="true " (click)="check() " class="nav-link fa fa-user-circle-o fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true "></a>
            </li> -->

            <!-- <li class="nav-item ">
         <button  (click)="check() ">
          <a class="nav-link fa fa-user-circle-o fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true " href="# " ></a>
          </button>
         </li> -->
            <!-- <li class="nav-item ">
                <a class="nav-link " aria-hidden="true " class="nav-link fa-2x " aria-hidden="true "><span class="attend "style="font-size:18px;margin-left:450px;font-weight:bold;color:black; ">Attendies: </span> </a>
            </li>-->
            <!-- <li class="nav-item ">
                <a class="nav-link " aria-hidden="true " class="nav-link fa-2x " aria-hidden="true "><span style="font-size:18px;padding-left: 20px; padding-right:20px;font-weight:bold;color:black; "> Your Stall Visitor: </span> </a> {{count}}
            </li> -->



            <li style="margin-left:600px; margin-top:10px;font-weight: bold;font-size: 15px;">
                <p>Your Stall Visitor: <span> 01</span></p>
            </li>
            <li style=" margin-left:30px;margin-top:10px;font-weight: bold;font-size: 15px;">
                <p>Attendies: <span>02 </span></p>
            </li>




        </ul>
    </div>
</nav>