<div class="col-lg-12 bgimage" id="con">
    <img src="https://firebasestorage.googleapis.com/v0/b/angboot-49fb1.appspot.com/o/Stall-Floor-Plan.jpg?alt=media&token=5dd3be62-fe56-4dd9-99f2-b5f2c09d344c" alt="Snow" style="width:100%;height:900px;">

    <a href="/profile/home/a822F6H7f6HbUaEbQvQJ">
        <div class=" Allen ">
            <span class="Allen-tooltiptext ">Allen Solly</span>

        </div>
    </a>


    <a href="/profile/home/z5wxHYNO0VwtDpEjmhmB ">
        <div class="Wallmart ">
            <span class="Wallmart-tooltiptext ">Wallmart</span>

        </div>
    </a>


    <a href="/profile/home/BcM5SfiwQ4a5U7sP6JPa ">
        <div class="ikea ">
            <span class="ikea-tooltiptext ">ikea</span>

        </div>
    </a>

    <a href="/profile/home/KQnJrhPPidm9GzTT7JNN ">
        <div class="fashion ">
            <span class="fashion-tooltiptext ">Fashion World</span>

        </div>
    </a>


    <a href="/profile/home/Dd03srDsrea5yhetmUfe ">
        <div class="coaster ">
            <span class="coaster-tooltiptext ">coaster</span>

        </div>
    </a>


    <a href="/profile/home/uWEQmCplEH5Hxe7298AT ">
        <div class="handloom ">
            <span class="handloom-tooltiptext ">India Handloom</span>

        </div>
    </a>


    <a href="/profile/home/noZ2yx0sMPnQoW1a6X3M ">
        <div class="fashion2 ">
            <span class="fashion2-tooltiptext "> Fashion World</span>

        </div>
    </a>


    <a href="/profile/home/p1UJEKGIrBuahOuF2Yjy ">
        <div class="armani ">
            <span class="armani-tooltiptext "> Armani</span>

        </div>
    </a>

    <a href="/profile/home/1uV36mEfbuoVNCSZwmbF ">
        <div class="ashok ">
            <span class="ashok-tooltiptext ">Ashok Leyland</span>

        </div>
    </a>

    <a href="/profile/home/d6kMgIwmTUtfRQlpuFmJ ">
        <div class="toyota ">
            <span class="toyota-tooltiptext ">Toyota</span>

        </div>
    </a>

    <a href="/profile/home/PRAHFgRsGhd4M8y6LHKP ">
        <div class="express ">
            <span class="express-tooltiptext ">American Express</span>

        </div>
    </a>

    <a href="/profile/home/hLA7vXu2v20ucW0Y38mb ">
        <div class="rolex ">
            <span class="rolex-tooltiptext ">Rolex</span>

        </div>
    </a>

    <a href="/profile/home/mzwKFiWBQHZGusrCqeo8 ">
        <div class="redmi ">
            <span class="redmi-tooltiptext ">Redmi</span>

        </div>
    </a>

    <a href="/profile/home/UOdGeCOyXhWhUxuoyRf0 ">
        <div class="msi ">
            <span class="msi-tooltiptext ">MSI</span>

        </div>
    </a>

    <a href="/profile/home/S4qVPo82XQHmSwQt06wU ">
        <div class="samsung ">
            <span class="samsung-tooltiptext ">Samsung</span>

        </div>
    </a>


    <a href="/profile/home/damr39ROJNFQKVgt5N9v ">
        <div class="benz ">
            <span class="benz-tooltiptext ">Mercedes Benz</span>

        </div>
    </a>
    <a href="/profile/home/9rOtlwjUJs7pOLkYSRuo ">
        <div class="tvs ">
            <span class="tvs-tooltiptext ">Tvs</span>

        </div>
    </a>


    <a href="/profile/home/Ej1hxhc58A1UqJB18tcO ">
        <div class="microsoft ">
            <span class="microsoft-tooltiptext "> Microsoft</span>

        </div>
    </a>
    <a href="/profile/home/X9nB74SXKLtHOUxDkGzy ">
        <div class="apple ">
            <span class="apple-tooltiptext ">Apple</span>

        </div>
    </a>










</div>

<div class="icon-bar ">
    <a href="# " class="facebook " target="_blank "><i class="fa fa-facebook "></i></a>
    <a href="# " class="twitter " target="_blank "><i class="fa fa-twitter "></i></a>
    <!-- <a href="# " class="whatsapp " target="_blank "><i class="fa fa-whatsapp "></i></a> -->
    <a href="# " class="linkedin " target="_blank "><i class="fa fa-linkedin "></i></a>
    <a href="# " class="youtube1 " target="_blank "><i class="fa fa-youtube "></i></a>
</div>