<app-exheader></app-exheader>
<div class="main-content">


    <div class="container-fluid">
        <div class="container" style=" margin-top: 100px; width: 100%; height: 200px;">
            <div class="container col-md-12" style="margin-bottom: 20px;">
                <div class="row " style="background-color:#ffffff;padding-bottom:20px;padding-top: 20px;margin:0px;border-radius: 5px;">

                    <div class="col-md-12 text-center" style="padding-left:35px;">

                        <h1 id="welcomeheading" style="margin-top:0px;">welcome </h1>
                        <h2>{{authservice.currentUserName}}</h2>
                        <!-- <h2>{{authservice.currentUserName}}</h2> -->
                        <p id="welcomepara">Thanks for registering. This dashboard has the complete details of the event. You can connect with exhibitor or visitor.</p>
                        <!-- <button (click)="open()" class="btn buttoncolor ">Take a Tour</button> -->
                        <!-- <p>Your Stall Visitor: <span> {{count}}</span></p> -->
                    </div>
                </div>

            </div>


            <div class="container " style="width: 100%; height: 20px;padding:0px;margin-bottom:10px; ">
                <div class="container col-md-12 " style="margin-bottom: 20px; ">
                    <div class="row " style="background-color:#ffffff;padding-bottom:10px;padding-top: 20px;margin:0px;border-radius: 5px; ">

                        <a class=" fa fa-file-text fa-2x" style="margin-left:15.5%;padding-left: 20px; padding-right: 20px; font-size-adjust: initial;color:black;" aria-hidden="true" href="/exregister" data-toggle="tooltip" data-placement="top"> </a>


                        <!-- <a class=" fa fa-upload  fa-2x" style="padding-left: 20px; padding-right: 20px;font-size-adjust: initial;color:black" aria-hidden="true" href="/stalldocupload">stall Details</a> -->
                        <p style=" border-left:1px solid lightgray;height:40px;margin-left:3%; "></p>
                        <a href="/stalldocupload" style="font-size:20px;font-weight:500;margin-top:0.5%;margin-left:4%;color:black">
                            <p>About </p>
                        </a>
                        <!-- <a href="" style="font-size:20px;font-weight:500;margin-top:0.5%;margin-left:4%;color:black">
                            <p>About</p>
                        </a> -->
                        <!-- <a href=" " style="font-size:20px;font-weight:500;margin-top:0.5%;margin-left:3%;color:black">
                            <p>Contact</p>
                        </a> -->
                        <a href="productup" style="font-size:20px;font-weight:500;margin-top:0.5%;margin-left:3%;color:black">
                            <p>Product</p>
                        </a>
                        <!-- <a href=" " style="font-size:20px;font-weight:500;margin-top:0.5%;margin-left:3%;color:black">
                            <p>Product2</p>
                        </a> -->
                        <a href="productvideoup" style="font-size:20px;font-weight:500;margin-top:0.5%;margin-left:3%;color:black">
                            <p>Video</p>
                        </a>
                    </div>

                </div>
            </div>



            <div class="row " style="margin-top:75px; ">
                <div class="col-lg-6 col-md-12 ">
                    <!-- <div class="card ">
                        <div class="card-header card-header-warning ">
                            <h4 class="card-title ">Live visitors</h4>
                            <p class="card-category "> 15th September, 2020</p>
                        </div>
                        <div class="card-body table-responsive text-center  table-wrapper-scroll-y my-custom-scrollbar">
                            <table class="table table-hover ">
                                <thead class="text-warning ">
                                    <th class="">No</th>
                                    <th class=""> Name</th>
                                    <th class=""> Phone No</th>


                                </thead>
                                <tbody>
                                    <tr>
                                        <td>1</td>
                                        <td>Book Fare</td>
                                        <td>amazon</td>



                                    </tr>
                                    <tr>
                                        <td>2</td>
                                        <td>Minerva Hooper</td>
                                        <td>$23,789</td>

                                    </tr>
                                    <tr>
                                        <td>3</td>
                                        <td>Sage Rodriguez</td>
                                        <td>$56,142</td>

                                    </tr>
                                    <tr>
                                        <td>4</td>
                                        <td>Philip Chaney</td>
                                        <td>$38,735</td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div> -->
                </div>

                <div class="col-lg-12 col-md-12 ">
                    <div class="card ">
                        <div class="card-header card-header-warning ">
                            <h4 class="card-title ">Today Activity</h4>
                            <p class="card-category ">15th September, 2020</p>
                        </div>
                        <div class="card-body table-responsive text-center  table-wrapper-scroll-y my-custom-scrollbar ">
                            <table class="table table-hover ">
                                <thead class="text-warning ">
                                    <th class="">No</th>
                                    <th class="">Time</th>
                                    <th class="">Title</th>

                                </thead>
                                <tbody *ngFor="let user of activity; ">
                                    <tr>

                                        <td style="text-align:left;">{{user.title}}</td>
                                        <td>{{user.stime}} - {{user.etime}}</td>

                                        <td>{{user.des}}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <div class="col-lg-12 col-md-6 ">
                    <div class="card ">
                        <div class="card-header card-header-warning ">
                            <h4 class="card-title ">Stall Visitors</h4>
                            <p class="card-category ">15th September, 2020</p>
                        </div>
                        <div class="card-body table-responsive text-center  table-wrapper-scroll-y my-custom-scrollbaring ">
                            <table class="table table-hover ">
                                <thead class="text-warning ">

                                    <th class="sticky">Mail Id</th>

                                </thead>
                                <tbody>
                                    <tr *ngFor="let exuser of visitor">


                                        <td>{{exuser.email}}</td>

                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>





            <div class=" row ">
                <div class="col ">

                    <div class="container ">
                        <h1 style="top: 0px; left: 0px; ">Live Event Videos</h1>
                    </div>

                    <div class="container col-12 " style="margin-bottom: 8px; left: 0; " *ngFor="let item of Live; index as i ">
                        <div class="row " *ngIf="i<3 " style="background-color:white; padding-bottom:10px; padding-top: 10px;margin:0px; border-radius: 5px; ">
                            <div class="col-sm-4 " style="padding-right:0px;padding-left:10px; ">
                                <!-- <iframe width="150 " height="150 " src="https://www.youtube.com/embed/tgbNymZ7vqY ">
                                    </iframe> -->
                                <!-- <iframe width="150 " height="150 " src="{{item.videoDes}} " frameborder="0 " allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen>
                                    </iframe> -->
                                <div style="width:auto;height:50%;">
                                    <img src="https://lh3.googleusercontent.com/S4wylkvt2jz16hnG9IG0pAZosbB82nWWy8P-rQkb54uH-SCVd5L2j7z7x1Vz5pZvIRc" width="150px" height="150px;">
                                </div>
                            </div>
                            <div class="col-md-8 " style="padding-left: 10px; ">
                                <h1 class="card-title " style="margin-top:0px; ">{{item.title}} </h1>
                                <p class="card-category ">{{item.videoDes}}</p>
                                <!-- <p id="link ">test.com</p> -->
                                <button (click)="openlive(item.videolink) " style="background: linear-gradient(60deg, #ffa726, #fb8c00);border:none;padding:5px 10px;color:white;border-radius:5px;margin-left:125px;margin-bottom:10px;font-size:16px; ">view</button>
                            </div>
                        </div>
                    </div>


                </div>

                <div class="col ">

                    <div class="container ">
                        <h1 style="top: 0px; left: 0px; ">Webinar Videos</h1>
                    </div>
                    <div class="container col-12 " style="margin-bottom: 8px; left: 0; " *ngFor="let item of webinar; index as i ">
                        <div class="row " *ngIf="i<3 " style="background-color:white; padding-bottom:10px; padding-top: 10px;margin:0px; border-radius: 5px; ">

                            <div class="col-sm-4 " style="padding-right:0px;padding-left:10px; ">
                                <!-- <iframe width="150 " height="150 " src="https://www.youtube.com/embed/tgbNymZ7vqY ">
                                    </iframe> -->
                                <!-- <iframe width="150 " height="150 " src="https://www.youtube.com/embed/{{item.videolink}} " frameborder="0 " allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen>
                                    </iframe> -->
                                <div style="width:auto;height:50%;">
                                    <img src="https://lh3.googleusercontent.com/S4wylkvt2jz16hnG9IG0pAZosbB82nWWy8P-rQkb54uH-SCVd5L2j7z7x1Vz5pZvIRc" width="150px" height="150px;">
                                </div>

                            </div>
                            <div class="col-md-8 " style="padding-left: 10px; ">
                                <h1 class="card-title " style="margin-top:0px; ">{{item.title}} </h1>
                                <p class="card-category ">{{item.videoDes}}</p>
                                <!-- <p id="link ">test.com</p> -->
                                <button (click)="openlive(item.videolink) " style="background: linear-gradient(60deg, #ffa726, #fb8c00);border:none;padding:5px 10px;color:white;border-radius:5px;margin-left:125px;margin-bottom:10px;font-size:16px; ">view</button>
                            </div>
                        </div>
                    </div>



                </div>

            </div>












            <div class="col-lg-12 col-md-12 " style="padding:0px; margin-bottom:150px;">
                <div class="card ">
                    <div class="card-header card-header-warning ">
                        <h1 class="card-title ">Testimonial</h1>
                    </div>
                    <div class="card-body ">





                        <div class="container " id="testimonial " *ngFor="let exuser of testimonial">
                            <p class="circle "></p>

                            <p><span style="font-size:15px; ">{{exuser.name}}</span></p>

                            <span class="fa fa-star checked " style="font-size:15px; "></span>
                            <span class="fa fa-star checked " style="font-size:15px; "></span>
                            <span class="fa fa-star checked " style="font-size:15px; "></span>
                            <!-- <span class="fa fa-star " style="font-size:15px;color:#c4c4c4; "></span>
                            <span class="fa fa-star " style="font-size:15px;color:#c4c4c4; "></span> -->


                            <p style="color:#c4c4c4; ">{{exuser.des}}</p>
                        </div>
                    </div>
                </div>
            </div>













        </div>
    </div>

    <app-exfooter></app-exfooter>