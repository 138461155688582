import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class CountService {

  constructor(public fireservices:AngularFirestore) { }

  get_visitor(id){
    return this.fireservices.collection('stall/').doc(id).get().toPromise().then(function(doc) {
      if (doc.exists) {
       return doc.data().visitor;
       
      }});
  }

  update_visitor(vis,id,state){
    if(state==true)
        vis=1+vis
    else
      vis=vis-1
    this.fireservices.collection('stall/').doc(id).update({visitor: vis})
  
  }
  
}
