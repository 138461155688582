<app-exheader></app-exheader>
<div class="messaging" style="margin-top: 4.5rem">
  <div class="inbox_msg">
    <div class="inbox_people">
      <div class="headind_srch">
        <div class="recent_heading">
          <h4>Chat with users</h4>
        </div>
        <div class="srch_bar">
          <div class="stylish-input-group">
            <input
              type="text"
              class="search-bar"
              placeholder="Search"
              [(ngModel)]="userFilter.name"
            />
            <span class="input-group-addon">
              <button type="button">
                <i class="fa fa-search" aria-hidden="true"></i>
              </button>
            </span>
          </div>
        </div>
      </div>
      <div class="inbox_chat">
        <div
          class="chat_list active_chat"
          *ngFor="
            let x of api?.currentUser?.conversations | filterBy: userFilter
          "
          (click)="selectUser(x)"
        >
          <div class="chat_people">
            <div class="chat_ib">
              <h5>
                {{ x?.name }}
              </h5>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="mesgs">
      <div class="msg_history">
        <cdk-virtual-scroll-viewport
          itemSize="40"
          class="msg_history"
          *ngIf="showMessages"
        >
          <div *cdkVirtualFor="let x of messages; let i = index">
            <div
              class="incoming_msg"
              *ngIf="x.senderId !== api.currentUser.uid"
            >
              <div class="incoming_msg_img">
                <img
                  src="https://ptetutorials.com/images/user-profile.png"
                  alt="sunil"
                />
              </div>
              <div class="received_msg">
                <span style="color: #05728f">{{ x?.senderName }}</span>
                <div class="received_withd_msg">
                  <p>{{ x?.content }}</p>
                  <span
                    class="time_date"
                    id="{{ i == messages.length - 3 ? 'destination' : 'abc' }}"
                  >
                    {{ x?.timestamp?.toDate() | date: "dd MMM hh:mm" }}</span
                  >
                </div>
              </div>
            </div>

            <div class="outgoing_msg" *ngIf="x.senderId == api.currentUser.uid">
              <div class="sent_msg">
                <span style="color: #05728f">You</span>
                <p>{{ x?.content }}</p>
                <span
                  class="time_date"
                  id="{{ i == messages.length - 3 ? 'destination' : 'abc' }}"
                >
                  {{ x.timestamp.toDate() | date: "dd MMM hh:mm" }}</span
                >
              </div>
            </div>
          </div>
        </cdk-virtual-scroll-viewport>
        <!-- <div class="incoming_msg">
          <div class="incoming_msg_img">
            <img
              src="https://ptetutorials.com/images/user-profile.png"
              alt="sunil"
            />
          </div>
          <div class="received_msg">
            <div class="received_withd_msg">
              <p>Test which is a new approach to have all solutions</p>
              <span class="time_date"> 11:01 AM | June 9</span>
            </div>
          </div>
        </div>
        <div class="outgoing_msg">
          <div class="sent_msg">
            <p>Test which is a new approach to have all solutions</p>
            <span class="time_date"> 11:01 AM | June 9</span>
          </div>
        </div>
        <div class="incoming_msg">
          <div class="incoming_msg_img">
            <img
              src="https://ptetutorials.com/images/user-profile.png"
              alt="sunil"
            />
          </div>
          <div class="received_msg">
            <div class="received_withd_msg">
              <p>Test, which is a new approach to have</p>
              <span class="time_date"> 11:01 AM | Yesterday</span>
            </div>
          </div>
        </div>
        <div class="outgoing_msg">
          <div class="sent_msg">
            <p>Apollo University, Delhi, India Test</p>
            <span class="time_date"> 11:01 AM | Today</span>
          </div>
        </div>
        <div class="incoming_msg">
          <div class="incoming_msg_img">
            <img
              src="https://ptetutorials.com/images/user-profile.png"
              alt="sunil"
            />
          </div>
          <div class="received_msg">
            <div class="received_withd_msg">
              <p>
                We work directly with our designers and suppliers, and sell
                direct to you, which means quality, exclusive products, at a
                price anyone can afford.
              </p>
              <span class="time_date"> 11:01 AM | Today</span>
            </div>
          </div>
        </div> -->
      </div>
      <div class="type_msg">
        <div class="input_msg_write">
          <input
            type="text"
            class="write_msg"
            name="message"
            placeholder="Type a message"
            (keydown.enter)="sendMessage()"
            [(ngModel)]="message"
          />
          <button class="msg_send_btn" type="button" (click)="sendMessage()">
            <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>
