import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-visitordomesticform',
  templateUrl: './visitordomesticform.component.html',
  styleUrls: ['./visitordomesticform.component.scss']
})
export class VisitordomesticformComponent implements OnInit {

  

  @ViewChild('Tablelinen1') Tablelinen1: ElementRef;
  @ViewChild('Tablelinen2') Tablelinen2: ElementRef;
  @ViewChild('Tablelinen3') Tablelinen3: ElementRef;
  @ViewChild('Tablelinen4') Tablelinen4: ElementRef;

  @ViewChild('Bedlinen1') Bedlinen1: ElementRef;
  @ViewChild('Bedlinen2') Bedlinen2: ElementRef;
  @ViewChild('Bedlinen3') Bedlinen3: ElementRef;
  @ViewChild('Bedlinen4') Bedlinen4: ElementRef;
  @ViewChild('Bedlinen5') Bedlinen5: ElementRef;
  @ViewChild('Bedlinen6') Bedlinen6: ElementRef;

  @ViewChild('Bathlinen1') Bathlinen1: ElementRef;
  @ViewChild('Bathlinen2') Bathlinen2: ElementRef;

  @ViewChild('Kitchenlinen1') Kitchenlinen1: ElementRef;
  @ViewChild('Kitchenlinen2') Kitchenlinen2: ElementRef;
  @ViewChild('Kitchenlinen3') Kitchenlinen3: ElementRef;
  @ViewChild('Kitchenlinen4') Kitchenlinen4: ElementRef;
  @ViewChild('Kitchenlinen5') Kitchenlinen5: ElementRef;
  @ViewChild('Kitchenlinen6') Kitchenlinen6: ElementRef;

  @ViewChild('FurnishingArticles1') FurnishingArticles1: ElementRef;
  @ViewChild('FurnishingArticles2') FurnishingArticles2: ElementRef;
  @ViewChild('FurnishingArticles3') FurnishingArticles3: ElementRef;
  @ViewChild('FurnishingArticles4') FurnishingArticles4: ElementRef;

  @ViewChild('Curtains1') Curtains1: ElementRef;
  @ViewChild('Curtains2') Curtains2: ElementRef;
  @ViewChild('Curtains3') Curtains3: ElementRef;
  @ViewChild('Curtains4') Curtains4: ElementRef;
  @ViewChild('Curtains5') Curtains5: ElementRef;

  @ViewChild('FloorCovering1') FloorCovering1: ElementRef;
  @ViewChild('FloorCovering2') FloorCovering2: ElementRef;
  @ViewChild('FloorCovering3') FloorCovering3: ElementRef;

  @ViewChild('Fabrics1') Fabrics1: ElementRef;
  @ViewChild('Fabrics2') Fabrics2: ElementRef;
  @ViewChild('Fabrics3') Fabrics3: ElementRef;
  @ViewChild('Fabrics4') Fabrics4: ElementRef;
  @ViewChild('Fabrics5') Fabrics5: ElementRef;

  @ViewChild('FashionAccessories1') FashionAccessories1: ElementRef;
  @ViewChild('FashionAccessories2') FashionAccessories2: ElementRef;
  @ViewChild('FashionAccessories3') FashionAccessories3: ElementRef;
  @ViewChild('FashionAccessories4') FashionAccessories4: ElementRef;
  @ViewChild('FashionAccessories5') FashionAccessories5: ElementRef;
  @ViewChild('FashionAccessories6') FashionAccessories6: ElementRef;
   @ViewChild('FashionAccessories7') FashionAccessories7: ElementRef;




  
  email="";
  password="";
  name='';
  lname='';
  title='';
  CompanyName='';
  Address='';
  Country='';
  Telephone='';
  errorMessage = ''; // validation error handle
  error: { name: string, message: string } = { name: '', message: '' }; // for firbase error handle
  Designation= "";
  Mobile="";
  Website='';
  year='';
  otherspecify='';
  volumetitle='';
  manufacture='';
  option='';
 
  requirement='';

  ref='';
  fax='';
  volume='';
  IndianSupplier1='';
  IndianSupplier2='';
  IndianSupplier3='';
  IndianSupplier='';

  tex1='';
  tex2='';
  tex3='';

  inr1='';
  inr2='';
  inr3='';
  volumetitle1='';
  volumetitle2='';
  volumetitle3='';



  buyername1='';
  buyername2='';
  buyername3='';


  buyercountry1='';
  buyercountry2='';
  buyercountry3='';

  buyerproduct1='';
  buyerproduct2='';
  buyerproduct3='';

  buspart='';
  bustitle='';

  brand='';
  specify='';
  retail='';
 







  
  @ViewChild('rdefaultChecked1') rdefaultChecked1: ElementRef;
  @ViewChild('rdefaultChecked2') rdefaultChecked2: ElementRef;
    @ViewChild('rdefaultChecked3') rdefaultChecked3: ElementRef;
      @ViewChild('rdefaultChecked4') rdefaultChecked4: ElementRef;
        @ViewChild('rdefaultChecked5') rdefaultChecked5: ElementRef;
@ViewChild('rdefaultChecked6') rdefaultChecked6: ElementRef;



@ViewChild('rdefaultChecked11') rdefaultChecked11: ElementRef;
// @ViewChild('defaultUnchecked1') defaultUnchecked1: ElementRef;
@ViewChild('defaultUnchecked2') defaultUnchecked2: ElementRef;

Tablelinen=[];
 
  Bedlinen=[];

  Bathlinen=[];
 
  Kitchenlinen=[];
  FurnishingArticles=[];
  Curtains=[];
  FloorCovering=[];
  Fabrics=[];
  FashionAccessories=[];

  product: { Tablelinen: any[]; Bedlinen: any[]; bathlinen: any[]; Kitchenlinen: any[]; FurnishingArticles: any[]; Curtains: any[]; FloorCovering: any[]; Fabrics: any[]; FashionAccessories: any[]; };
  TableLinen:{Tablelinen:any[];}
  BedLinen:{Bedlinen:any[];}
  bathLinen:{bathlinen:any[];}
  KitchenLinen:{Kitchenlinen:any[];}
  Furnishingarticles:{FurnishingArticles:any[];}
  Curtainss:{Curtains:any[];}
  FloorCoverings:{FloorCovering:any[];}
  Fabricss:{Fabrics:any[];}
  FashionAccessoriess:{FashionAccessories:any[];}
  clicked: boolean;

  constructor(private authservice: AuthService,private router: Router) { }

  ngOnInit(): void {
  }
  register(){


    
    if( this.Tablelinen1.nativeElement.checked)
      this.Tablelinen.push("Table Linen")
    if( this.Tablelinen2.nativeElement.checked)
      this.Tablelinen.push(" Place mats")
    if( this.Tablelinen3.nativeElement.checked)
      this.Tablelinen.push(" Table runners")
    if( this.Tablelinen4.nativeElement.checked)
      this.Tablelinen.push(" Napkins")

      if( this.Bedlinen1.nativeElement.checked)
      this.Bedlinen.push("Bed spreads")
    if( this.Bedlinen2.nativeElement.checked)
      this.Bedlinen.push("Bed covers")
    if( this.Bedlinen3.nativeElement.checked)
      this.Bedlinen.push("Pillows")
    if( this.Bedlinen4.nativeElement.checked)
      this.Bedlinen.push("Quilts")
      if( this.Bedlinen5.nativeElement.checked)
      this.Bedlinen.push("Duvet covers")
    if( this.Bedlinen6.nativeElement.checked)
      this.Bedlinen.push("Comforters")

      if( this.Bathlinen1.nativeElement.checked)
      this.Bathlinen.push("Towels")
    if( this.Bathlinen2.nativeElement.checked)
      this.Bathlinen.push("Bath robes")

      if( this.Kitchenlinen1.nativeElement.checked)
      this.Kitchenlinen.push("Tea towel")
    if( this.Kitchenlinen2.nativeElement.checked)
      this.Kitchenlinen.push("Dish towel")
    if( this.Kitchenlinen3.nativeElement.checked)
      this.Kitchenlinen.push("Hand towel")
    if( this.Kitchenlinen4.nativeElement.checked)
      this.Kitchenlinen.push("Aprons")
      if( this.Kitchenlinen5.nativeElement.checked)
      this.Kitchenlinen.push("Mittens")
    if( this.Kitchenlinen6.nativeElement.checked)
      this.Kitchenlinen.push("Tea cosy")

      if( this.FurnishingArticles1.nativeElement.checked)
      this.FurnishingArticles.push("Cushions")
    if( this.FurnishingArticles2.nativeElement.checked)
      this.FurnishingArticles.push("Throws")
    if( this.FurnishingArticles3.nativeElement.checked)
      this.FurnishingArticles.push("Tapestry")
      if( this.FurnishingArticles4.nativeElement.checked)
      this.FurnishingArticles.push("Bolsters")
   
      if( this.Curtains1.nativeElement.checked)
      this.Curtains.push("Drapers")
    if( this.Curtains2.nativeElement.checked)
      this.Curtains.push("Blinds")
    if( this.Curtains3.nativeElement.checked)
      this.Curtains.push("Window dressings")
    if( this.Curtains4.nativeElement.checked)
      this.Curtains.push("Shower curtains")
      if( this.Curtains5.nativeElement.checked)
      this.Curtains.push("Room dividers")

      if( this.FloorCovering1.nativeElement.checked)
      this.FloorCovering.push("Floor mat")
    if( this.FloorCovering2.nativeElement.checked)
      this.FloorCovering.push("Bath mat")
      if( this.FloorCovering3.nativeElement.checked)
      this.FloorCovering.push("Floor carpet")

      if( this.Fabrics1.nativeElement.checked)
      this.Fabrics.push("Lungies")
    if( this.Fabrics2.nativeElement.checked)
      this.Fabrics.push("Sarees")
    if( this.Fabrics3.nativeElement.checked)
      this.Fabrics.push("Dhoties")
    if( this.Fabrics4.nativeElement.checked)
      this.Fabrics.push("Sarongs")
      if( this.Fabrics5.nativeElement.checked)
      this.Fabrics.push("RMHK")

      if( this.FashionAccessories1.nativeElement.checked)
      this.FashionAccessories.push("Shawls")
    if( this.FashionAccessories2.nativeElement.checked)
      this.FashionAccessories.push("Stoles")
    if( this.FashionAccessories3.nativeElement.checked)
      this.FashionAccessories.push("Scarves")
      if( this.FashionAccessories4.nativeElement.checked)
      this.FashionAccessories.push("Bags")
      if( this.FashionAccessories5.nativeElement.checked)
      this.FashionAccessories.push("Wall hangings")
    if( this.FashionAccessories6.nativeElement.checked)
      this.FashionAccessories.push("Gift")
       if( this.FashionAccessories7.nativeElement.checked)
       this.FashionAccessories.push("Home")


      

 


     

       this.product={
        Tablelinen:this.Tablelinen,
        Bedlinen:this.Bedlinen,
        bathlinen:this.Bathlinen,
        Kitchenlinen:this.Kitchenlinen,
        FurnishingArticles:this.FurnishingArticles,
        Curtains:this.Curtains,
        FloorCovering:this.FloorCovering,
        Fabrics:this.Fabrics,
        FashionAccessories:this.FashionAccessories
     }
    

    
  

      if( this.rdefaultChecked1.nativeElement.checked)
          this.ref='Direct Mail (invitation from HEPC)'
      if( this.rdefaultChecked2.nativeElement.checked)
        this.ref='PR Agency'
      if( this.rdefaultChecked3.nativeElement.checked)
        this.ref='Magazine'
    if( this.rdefaultChecked4.nativeElement.checked)
      this.ref='Embassy'

      if( this.rdefaultChecked5.nativeElement.checked)
        this.ref='Associations'

      if( this.rdefaultChecked6.nativeElement.checked)
        this.ref='Others'






      
        if( this.rdefaultChecked11.nativeElement.checked)
            this.manufacture="Yes"
          else
            this.manufacture="No"

            
            if( this.defaultUnchecked2.nativeElement.checked)
            this.option="No"
          else
            this.option="Yes"

      console.log( this.name)
      var obj = {
        form:2,
        lname:this.lname,
        Designation: this.Designation,
        name:this.name,
        manufacture:this.manufacture,
         option:this.option,
        title:this.title,
        Address:this.Address,
        Country:this.Country,
        Telephone:this.Telephone,
        Mobile:this.Mobile,
        CompanyName:this.CompanyName,
        email:this.email,
        Website:this.Website,
        year:this.year,
        buspart:this.buspart,
     
        volumetitle1:this.volumetitle1,
        
        volumetitle2:this.volumetitle2,
        
        volumetitle3:this.volumetitle3,
        inr1:this.inr1,
        inr2:this.inr2,
        inr3:this.inr3,


        IndianSupplier1:this.IndianSupplier1,
        
        IndianSupplier2:this.IndianSupplier2,
        
        IndianSupplier3:this.IndianSupplier3,
        IndianSupplier:this.IndianSupplier,

        tex1:this.tex1,
        tex2:this.tex2,
        tex3:this.tex3,


        product : this.product,
      
        requirement:this.requirement,
        retail:this.retail,
        buyername1:this.buyername1,
        buyername2:this.buyername2,
        buyername3:this.buyername3,

        buyercountry1:this.buyercountry1,
        
        buyercountry2:this.buyercountry2,
        
        buyercountry3:this.buyercountry3,


        buyerproduct1:this.buyerproduct1,
        buyerproduct2:this.buyerproduct2,
        buyerproduct3:this.buyerproduct3,

        bustitle:this.bustitle,
  
        brand:this.brand,
        specify:this.specify,
     
        ref:this.ref,
        acess:true,
        password:'',


        Tablelinen:this.Tablelinen,
        Bedlinen:this.Bedlinen,
        Bathlinen:this.Bathlinen,
        Kitchenlinen:this.Kitchenlinen,
        FurnishingArticles:this.FurnishingArticles,
        Curtains:this.Curtains,
        FloorCovering:this.FloorCovering,
        Fabrics:this.Fabrics,
        FashionAccessories:this.FashionAccessories,
     
    };
   console.log(obj)
      this.authservice.createdomestic(obj).then(new alert("Successfully registered please wait for admin to confirm you credentials")).then(() => {
        this.router.navigate(['/home'])
  
  })
  
  }
}

