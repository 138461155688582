import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute } from '@angular/router';
import { StallService} from 'src/app/service/stall.service'

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  stall: { id: string; name: any; bgimage: any; linkedin: any; insta: any; facebook: any; youtube: any; twitter: any; whatsapp: any; zoom: any; }[];
  id: string;

  constructor(private actRoute: ActivatedRoute,public StallService:StallService,public dialog: MatDialog,public fireservices:AngularFirestore) { }

  ngOnInit(): void {
    this.id = this.actRoute.snapshot.paramMap.get('id');

    this.StallService.get_allstall().subscribe(data => {
      this.stall = data.map(e => {
        return {
          id: e.payload.doc.id,
          name: e.payload.doc.data()['name'],
          bgimage : e.payload.doc.data()['bgimage'],
          linkedin : e.payload.doc.data()['linkedin'],
          insta : e.payload.doc.data()['insta'],  
          facebook : e.payload.doc.data()['facebook'],
          
          youtube:e.payload.doc.data()['youtube'],
          twitter : e.payload.doc.data()['twitter'], 
          whatsapp : e.payload.doc.data()['whatsapp'], 
          zoom : e.payload.doc.data()['zoom'], 
        

          


        };
      }
    
      )
      console.log(this.stall)
    
    }
      
      )
  }

}
