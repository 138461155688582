import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-adminlogin',
  templateUrl: './adminlogin.component.html',
  styleUrls: ['./adminlogin.component.scss']
})
export class AdminloginComponent implements OnInit {

  email = "";
  password = "";
  errorMessage = ''; // validation error handle
  error: { name: string, message: string } = { name: '', message: '' }; // for firbase error handle

  constructor(private authservice: AuthService,private router: Router,private afAuth: AngularFireAuth) {  }

  ngOnInit(): void {
  }

  clearErrorMessage() {
    this.errorMessage = '';
    this.error = { name: '', message: '' };
  }

  login()
  {
  
    this.clearErrorMessage();
    if (this.validateForm(this.email, this.password)) {
      // this.authservice.loginWithEmail(this.email, this.password)
      //   .then(() => {
      //    this.router.navigate(['/admin'])
      //   }).catch(_error => {
      //     this.error = _error
      //     this.router.navigate(['/adminlogin'])
      //   })
      console.log("asas")
      if(this.email=='admin'&&this.password=='admin'){
        this.router.navigate(['/admin'])
      }
      else{
        this.router.navigate(['/adminlogin'])
      }
      
      }
    }

  validateForm(email, password) {
    if (email.lenght === 0) {
      this.errorMessage = "please enter email id";
      return false;
    }

    if (password.lenght === 0) {
      this.errorMessage = "please enter password";
      return false;
    }

    // if (password.lenght < 6) {
    //   this.errorMessage = "password should be at least 6 char";
    //   return false;
    // }

    this.errorMessage = '';
    return true;

  }

}
