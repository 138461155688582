<!-- Password change
 -->





<!-- <ng-container *ngIf="actionCodeChecked" [ngSwitch]="mode"> 
  
      <ng-container *ngSwitchCase="'resetPassword'"> 
        <input [(ngModel)]="oldPassword" type="text" id="oldPassword"
        name="oldPassword" placeholder="Old Password"> <br> 
        <input [(ngModel)]="newPassword" type="text" id="newPassword"
        name="newPassword" placeholder="New Password"> <br> 
        <input [(ngModel)]="confirmPassword" type="text"
        id="confirmPassword" name="confirmPassword" placeholder="Confirm
        Password"> 
        <button (click)="handleResetPassword()">Confirm</button> 
      </ng-container> 
  
      <ng-container *ngSwitchCase="'recoverEmail'"> 
      </ng-container> 
      
  
      <ng-container *ngSwitchCase="'verifyEmail'"> 
      </ng-container> 
    </ng-container> -->


<div class="map" *ngFor="let item of stall">
    <div *ngIf="item.id==id">
        <img src="{{item.bgimage}}" alt="" style="height:800px;" />
        <div *ngFor="let pin of item.pin ">
            <div id="pin " class="box " style={{pin.pinloc}}>
                <div class="blobs-container ">
                    <div class="blob red ">
                        <div> </div>
                        <button class="btn fa fa-info fa-1x " (click)="openDialog(item.id,item.name,pin.pinid,pin.pinname) "></button>
                    </div>
                </div>
            </div>


        </div>




    </div>
</div>
<!-- <app-profile></app-profile> -->