<app-pfheader>

</app-pfheader>
<div class="col-lg-12 bgimage" style="padding-right:0px;padding-left:0px;">
    <img src="https://firebasestorage.googleapis.com/v0/b/angboot-49fb1.appspot.com/o/adudit.jpg?alt=media&token=b5ffba34-7342-48bd-ad50-41e59a31bdc0" style="width:100%;">
    <!-- <iframe width="1450" height="600" src="https://www.youtube.com/embed/tgbNymZ7vqY" class="youtube">

   


        
    </iframe> -->

    <iframe width="50%" height="50%" src="https://www.youtube.com/embed/yJpT-72gveA?autoplay=1" class="youtube"></iframe>


</div>

<!-- <div class="icon-bar">
    <a href="#" class="facebook"><i class="fa fa-facebook"></i></a>
    <a href="#" class="twitter"><i class="fa fa-twitter"></i></a>
    <a href="#" class="whatsapp"><i class="fa fa-whatsapp"></i></a>
    <a href="#" class="linkedin"><i class="fa fa-linkedin"></i></a>
    <a href="#" class="youtube1"><i class="fa fa-youtube"></i></a>
</div> -->

<app-profile></app-profile>