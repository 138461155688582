<div class="container   " style="background:none;height:auto;margin-top:5%;">
    <div class="col-lg-12 col-md-6 ">
        <div class="card">
            <div class="card-header card-header-green">
                <h4 class="card-title">Current Visitor</h4>
                <p class="card-category">New employees on 15th September, 2016</p>
            </div>
            <div class="card-body table-responsive text-center  table-wrapper-scroll-y my-custom-scrollbaring" id="green">
                <table class="table table-hover">
                    <thead class="text-green">

                        <th>Name</th>
                        <th>Mail Id</th>
                        <th>Phone No</th>
                        <th>Briefcase</th>
                        <!-- <th>Create</th> -->
                        <th>View</th>
                        <th>Block</th>
                    </thead>
                    <tbody *ngFor="let user of customer;let i=index;">
                        <tr *ngIf="user.acess && user.password!=''">


                            <td>{{user.name}} </td>
                            <td>{{user.email}}</td>
                            <td>{{user.Mobile}}</td>
                            <td class="fa fa-briefcase fa-2x" (click)="openDialogbrief(user.id)"></td>
                            <!-- <td><i class="fa fa-plus-circle fa-2x"></i></td> -->
                            <td><i class="fa fa-pencil-square-o fa-2x" (click)="openDialogview(user)"></i></td>
                            <td><i class="fa fa-ban  fa-2x" (click)="updateacessforvis(user.id,user.acess )"></i></td>
                            <!-- <button class="btnicon"><td><i class="fa fa-plus-circle fa-2x"></i></td></button>
                            <button class="btnicon"><td><i class="fa fa-pencil-square-o fa-2x"></i></td></button>
                            <button class="btnicon"> <td><i class="fa fa-ban  fa-2x"></i></td></button> -->

                    </tbody>
                </table>
            </div>
        </div>
    </div>





    <div class="col-lg-12 col-md-6">
        <div class="card">
            <div class="card-header card-header-warning">
                <h4 class="card-title">Visitor Request</h4>
                <p class="card-category">New employees on 15th September, 2016</p>
            </div>
            <div class="card-body table-responsive text-center  table-wrapper-scroll-y my-custom-scrollbaring" id="orange">
                <table class="table table-hover">
                    <thead class="text-warning">

                        <th>Name</th>
                        <th>Mail Id</th>
                        <th>Phone No</th>
                        <th>Briefcase</th>
                        <!-- <th>Create</th> -->
                        <th>View</th>
                        <th>Approve</th>
                        <th>Reject</th>
                    </thead>
                    <tbody *ngFor="let user of customer;let i=index;">
                        <tr *ngIf="user.password==''">


                            <td>{{user.name}}</td>
                            <td>{{user.email}}</td>
                            <td>{{user.Mobile}}</td>
                            <td class="fa fa-briefcase fa-2x" (click)="openDialogbrief(user.id)"></td>
                            <!-- <td><i class="fa fa-plus-circle fa-2x"></i></td> -->
                            <td><i class="fa fa-pencil-square-o fa-2x" (click)="openDialogview(user)"></i></td>
                            <td><img src="https://upload.wikimedia.org/wikipedia/commons/thumb/9/90/Check_mark_23x20_02.svg/811px-Check_mark_23x20_02.svg.png" width="30px" height="30px" (click)="openDialog(user.email,user,id)"></td>
                            <!-- <td><i class="fa fa-plus-circle fa-2x"  ></i></td> -->
                            <td><i class="fa fa-ban  fa-2x" (click)="updateacessforvis(user.id,user.acess )"></i></td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>
    </div>





    <div class="col-lg-12 col-md-6">
        <div class="card">
            <div class="card-header card-header-red">
                <h4 class="card-title">Blocked Visitor</h4>
                <p class="card-category">New employees on 15th September, 2016</p>
            </div>
            <div class="card-body table-responsive text-center  table-wrapper-scroll-y my-custom-scrollbaring" id="red">
                <table class="table table-hover">
                    <thead class="text-red">

                        <th>Name</th>
                        <th>Mail Id</th>
                        <th>Phone No</th>
                        <th>Briefcase</th>
                        <!-- <th>Create</th> -->
                        <th>View</th>
                        <th>Restore</th>
                    </thead>
                    <tbody *ngFor="let user of customer;let i=index;">
                        <tr *ngIf="!user.acess&& user.password!=''">


                            <td>{{user.name}} </td>
                            <td>{{user.email}}</td>
                            <td>{{user.Mobile}}</td>
                            <td class="fa fa-briefcase fa-2x" (click)="openDialogbrief(user.id)"></td>

                            <td><i class="fa fa-pencil-square-o fa-2x" (click)="openDialogview(user)"></i></td>
                            <td><img src="http://rbvi.ucsf.edu/chimerax/docs/user/tools/mouse-icons/rotate.png" width="30px" height="30px" (click)="updateacessforvis(user.id,user.acess )"></td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>
    </div>

</div>