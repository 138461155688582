import { Component, OnInit } from '@angular/core';
import { CrudService } from 'src/app/service/crud.service';
import { AuthService } from 'src/app/service/auth.service';
import { StalldocComponent } from '../stalldoc/stalldoc.component';
import { MatDialog } from '@angular/material/dialog';
import { PbrifecaseComponent } from './pbrifecase/pbrifecase.component';
import { LiveComponent } from '../live/live.component';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {





 
   customer: any;
  selectedcustomer: any;
  save: {      id: string,
    pinname: string,
    pinid: string,
    sid: string,
    sname:string           }[];
  pins: any;


  constructor(public dialog: MatDialog,public crudservice:CrudService, public authservice: AuthService) { }

  ngOnInit(): void {
 
    this.crudservice.get_Allcustomer().subscribe(data => {
      this.customer = data.map(e => {
        return {
          id: e.payload.doc.id,
          email: e.payload.doc.data()['email'],
          CompanyName : e.payload.doc.data()['CompanyName'],
          Designation : e.payload.doc.data()['Designation'],
            Address  : e.payload.doc.data()['Address'],
              Country: e.payload.doc.data()['Country'],
      IndiasuppliersName:e.payload.doc.data()['IndiasuppliersName'],
            Mobile:e.payload.doc.data()['Mobile'],
            Telephone :e.payload.doc.data()['Telephone'],
            Website:e.payload.doc.data()['Website'],
          importing:e.payload.doc.data()['importing'],
              name:e.payload.doc.data()['name'],
              other:e.payload.doc.data()['other'],
            password:e.payload.doc.data()['password'],
            products:e.payload.doc.data()['products'],
      textilesValueMUS:e.payload.doc.data()['textilesValueMUS'],
title:e.payload.doc.data()['textilesValueMUS'],
type:e.payload.doc.data()['type'],
        };})
        // console.log(this.customer)
        var name=localStorage.getItem('username');
        for (var i=0;i<this.customer.length;i++){
          if(this.customer[i]['email']==name)
          {
            this.selectedcustomer=this.customer[i];
          }
        }
        // console.log(this.selectedcustomer)
  
        this.crudservice.get_saveddoc(this.selectedcustomer.id).subscribe(data => {
          this.save = data.map(e => {
           
            return {
             
              id: e.payload.doc.id,
              pinname: e.payload.doc.data()['pname'],
              pinid: e.payload.doc.data()['pid'],
              sid: e.payload.doc.data()['sid'],
              sname: e.payload.doc.data()['sname'],
            };})
        
          //  console.log(this.save)
            
        })
        
      
    
  })
 
  }

  openDialogbrief(){
    const dialogRef = this.dialog.open(PbrifecaseComponent, {
      width: '900px',
      height: '700px',
      data: {save:this.save}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });

  }

  openDialog(a,b,c,d): void {
    const dialogRef = this.dialog.open(StalldocComponent, {
      width: '900px',
      height: '700px',
      data: {stallid : a , stallname:b , pinid:c ,pinname:d}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  } 
  openlive(){
    const a="1WRj1so9lMU"
    // console.log(a,"ennna tha nadakattu ")

    const dialogRef = this.dialog.open(LiveComponent, {
      data :{id:a}
       
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });

  }
}
