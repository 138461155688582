import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { AngularFireStorage } from "@angular/fire/storage";
import { finalize } from 'rxjs/operators';
import { StallService} from 'src/app/service/stall.service'

@Component({
  selector: 'app-exstalldocup',
  templateUrl: './exstalldocup.component.html',
  styleUrls: ['./exstalldocup.component.scss']
})
export class ExstalldocupComponent implements OnInit {
  exname: any;
  exnumber: any;
  sname: any;
  snumber: any;
  downloadURL: any;
  fb: any;
  url: any;
  stitle: any;
  sdesc: any;
  pdownloadURL: any;
  pfb: "";
  purl: any;
  insta = "";
  youtube = "";
  zoom = "";
  whatsapp = "";
  facebook = "";
  linkedin = "";
  
  ptitle: any;
  pdes: any;
  product: { name: any; des: any; pdfsrc: any; };
  datas: any;
 
  twitter ="";
  stall: {
    name: any; exnumber: any; sname: any; snumber: any; stitle: any; sdesc: any;
    // bgimage :this.fb,
    youtube: string; whatsapp: string; zoom: string; facebook: string; linkedin: string; insta: string; twitter: any;
  };
 
 


  constructor(public fireservices:AngularFirestore,private storage: AngularFireStorage,public StallService:StallService) { }

  ngOnInit(): void {
  }

  check(){
    if(this.exnumber&&this.exname){ //  console.log(this.exname,this.exnumber)
      this.fireservices.collection('exhibitor/'+this.exnumber+'/stall').get().toPromise().then(function(doc){
        console.log(doc.size)
       if (doc.size>0) {
        
         document.getElementById("already").style.display = "block";
         document.getElementById("checkdetails").style.display = "none";
           // console.log("Document data:", doc.data());
           
       } else {
       
   
           document.getElementById("upload").style.display = "block";
           document.getElementById("checkdetails").style.display = "none";
           // doc.data() will be undefined in this case
          
       }
       
   }).catch(function(error) {
     window.alert(error);
   });}
   else{
    window.alert("enter correct details");
   }
 
  }


  upload(){


    this.stall ={
      name:this.exname,
     exnumber:this.exnumber, 
        sname:this.sname,
    snumber:this.snumber,
   stitle:this.stitle,
    sdesc:this.sdesc,
    // bgimage :this.fb,
    youtube:this.youtube,
    whatsapp:this.whatsapp,
    zoom:this.zoom,
    facebook:this.facebook,
    linkedin:this.linkedin,
    insta:this.insta,
    twitter:this.twitter
    }
    // this.product={
    //   name: this.ptitle,
    //   des:this.pdes,
    //   pdfsrc:""
    // }
    
    // console.log(this.stall,this.product)
    console.log(this.stall)
   

this.StallService.uploadstall(this.stall).then(data => {
  // console.log("engga engaa")
  // console.log(data) 
  this.datas=data
    // this.StallService.uploadproduct(this.product,this.datas)  
    this.StallService.updatestallonex(this.exnumber,this.datas)
    window.alert("use this stall id to upload product "+this.datas)
  })

  }

  onFileSelected(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `Stall/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`Stall/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.downloadURL = fileRef.getDownloadURL();
          this.downloadURL.subscribe(url => {
            if (url) {
              this.fb = url;
            }
            // console.log("hi")
            // console.log(this.fb);
          });
        })
      )
      .subscribe(url => {
        if (url) {
          this.url=url
          // console.log(url);
        }
      });
  }

  onFileSelectedp(event) {
    var n = Date.now();
    const file = event.target.files[0];
    const filePath = `Stall/${n}`;
    const fileRef = this.storage.ref(filePath);
    const task = this.storage.upload(`Stall/${n}`, file);
    task
      .snapshotChanges()
      .pipe(
        finalize(() => {
          this.pdownloadURL = fileRef.getDownloadURL();
          this.pdownloadURL.subscribe(url => {
            if (url) {
              this.pfb = url;
            }
            // console.log("hi")
            // console.log(this.pfb);
          });
        })
      )
      .subscribe(url => {
        if (url) {
          this.purl=url
          // console.log(url);
        }
      });
  }

}
