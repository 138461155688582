<app-exheader></app-exheader>
<div class="container " style=" margin-top: 100px;">
    <div id='checkdetails' class="card">
        <div class="card-header card-header-warning" style="padding:0px 10px;">
            <h1 class="card-title">Enter Cridentials </h1>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
        </div>
        <div class="card-body table-responsive">

            <div class="container " style=" margin-top: 10px;">

                <div class="row">

                    <form (ngSubmit)="check()">


                        <div class="form-group col-md-12">
                            <label style="font-weight: 800;">Stall Id</label>
                            <input type="text" class="form-control" name="stallnumber" [(ngModel)]="stallnumber">

                        </div>




                        <div class="col text-center" style="margin-top: 10px; margin-bottom: 10px;">
                            <button type="submit" class="btn buttoncolor" style="background-color:#fe9d18; ">Submit</button>
                        </div>

                    </form>

                </div>



            </div>


        </div>

    </div>

    <div id="upload" style="display:none ">
        <form (ngSubmit)="upload() ;clicked = true;">
            <div class="col-lg-12 col-md-12 ">
                <div class="card ">
                    <div class="card-header card-header-warning " style="padding:0px 10px; ">
                        <h1 class="card-title ">Stall </h1>
                        <!-- <p class="card-category ">New employees on 15th September, 2016</p> -->
                    </div>
                    <div class="card-body table-responsive ">

                        <div class="container " style=" margin-top: 10px; ">

                            <div class="row ">


                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; ">Product Name</label>
                                    <input type="text " class="form-control " name="pname" [(ngModel)]="pname">
                                </div>

                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; ">Product Des</label>
                                    <input type="text " class="form-control " name="pdes" [(ngModel)]="pdes">

                                </div>

                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; ">Pdf src link</label>
                                    <input type="file " class="form-control " name="plink" [(ngModel)]="plink">

                                </div>

                                <form action="">
                                    <label for="myfile" style="margin-left:10px;margin-right:10px;">Select a file:  </label>
                                    <input type="file" id="myfile" name="myfile">

                                </form>


                                <div class="text-center" style="margin-top:50px;margin-left:120px;">
                                    <button type="submit " class="btn buttoncolor " style="background-color:#fe9d18; " [disabled]="clicked">Submit</button>
                                </div>
                            </div>



                        </div>


                    </div>

                </div>



            </div>
        </form>

    </div>


</div>