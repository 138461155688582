import { Component, OnInit } from '@angular/core';
import { StallService} from 'src/app/service/stall.service'
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { StalldocComponent } from '../../stalldoc/stalldoc.component';

@Component({
  selector: 'app-viewicon',
  templateUrl: './viewicon.component.html',
  styleUrls: ['./viewicon.component.scss']
})
export class ViewiconComponent implements OnInit {
  stall: any;
  pin: { pinid: string; pinname: any; }[];
  j=0;
  selectedstall: any;
   id : any;    
   animal: string;
   name: string;
  ethuku: Promise<any>;
  visitor: { id: string; email: any; }[];
  flag=1;
  count: number;


  constructor(public dialog: MatDialog,public StallService:StallService,public fireservices:AngularFirestore,private actRoute: ActivatedRoute,public authservice: AuthService,private router: Router) { }

  ngOnInit(): void {

  this.id = this.actRoute.snapshot.paramMap.get('id');
//  this.id="vBB2ZtnLcl5nODkZBDCQ";
   this.StallService.visitorcount(this.id)
    .get().toPromise()
    .then(query => this.count=query.size);

var name=localStorage.getItem('username');

this.StallService.get_vis(this.id).subscribe(data => {
  this.visitor = data.map(e => {
    return {
      id: e.payload.doc.id,
      email :e.payload.doc.data()['email'],
    };
  })

 
  for(var i=0;i<this.visitor.length;i++){
    console.log(this.visitor[i].email)
    if((this.visitor[i].email)==name){
      this.flag=0
    }
  }
  if(this.flag==1)
  {
    console.log("new")
    this.StallService.addvisior(name,this.id)
  }
  else{
    console.log("entered")
  }
})



 

//  this.StallService.get_visitor(this.id).then(data => {
//   this.StallService.update_visitor(data,this.id,true)
// })
 
   
     
// else {
//       // doc.data() will be undefined in this case
//       console.log("No such document!");
//     }
//   }).catch(function(error) {
//     console.log("Error getting document:", error);
//   });;

//   this.selectedstall= this.fireservices.collection("stall/").doc(this.id).get()
//   .toPromise()
//   .then(function(doc) {

//     if (doc.exists) {
//       const a = doc.data() as StallModule ;
//      a.id=doc.id
//       return a
//     } else {
//         console.log("No such document!");
//     }})
// .catch(function(error) {
//     console.log("Error getting document:", error);
// }); AsyncSubject


    this.StallService.get_allstall().subscribe(data => {
      this.stall = data.map(e => {
        return {
          id: e.payload.doc.id,
          name: e.payload.doc.data()['name'],
          bgimage : e.payload.doc.data()['bgimage'],
          linkedin : e.payload.doc.data()['linkedin'],
          insta : e.payload.doc.data()['insta'],  
          facebook : e.payload.doc.data()['facebook'],
          
          
          twitter : e.payload.doc.data()['twitter'], 
          whatsapp : e.payload.doc.data()['whatsapp'], 
          zoom : e.payload.doc.data()['zoom'], 
          youtube : e.payload.doc.data()['youtube'], 
        

          


        };
      })
      for (var i=0;i<this.stall.length;i++){
        this.StallService.getallpin(this.stall[i]['id']).subscribe(data => {
          this.pin = data.map(e => {
            return {
              pinid: e.payload.doc.id,
              pinname: e.payload.doc.data()['name'],
              pinleft: e.payload.doc.data()['left'],
              pintop: e.payload.doc.data()['top'],
              pinyou: e.payload.doc.data()['youtube'],
              pinloc:" top:"+e.payload.doc.data()['top']+"%;  left:"+ e.payload.doc.data()['left']+"%;"
            };
          })
           
    
          this.stall[this.j].pin=(this.pin);
          this.j=this.j+1; 
          console.log(this.stall)
         
      })}


    
      // console.log("hi")
      // console.log(this.pin)
    
    })


}

getid(){return this.id}

openDialog(a,b,c,d): void {
  const dialogRef = this.dialog.open(StalldocComponent, {
    width: 'auto',
    height: 'auto',
    data: {stallid : a , stallname:b , pinid:c ,pinname:d}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
    this.animal = result;
  });
}


}