<div class="container " style="background:none;height:auto;margin-top:5%;">

    <div class="col-lg-12 col-md-6 responsive">
        <div class="card">
            <div class="card-header card-header-warning" style="padding:0px 10px;">
                <h2 class="card-title">Live</h2>
                <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
            </div>
            <div class="card-body table-responsive table-wrapper-scroll-y my-custom-scrollbaring" id="orange">

                <div class="container " style=" margin-top: 10px;overflow:hidden;">

                    <div class="row">
                        <form (ngSubmit)="add()" style="width:100%;">
                            <div class="form-group col-md-12">
                                <label style="font-weight: 800;">Title</label>

                                <div class="input-group">



                                </div>


                                <input type="text" class="form-control" placeholder="Title Here" name="title" [(ngModel)]="title">
                            </div>



                            <div class="form-group col-md-12">
                                <label style="font-weight: 800;">Live Video code</label>

                                <div class="input-group">


                                    <i class=" 	fa fa-youtube icon" style="font-size:35px;margin-right:10%;"></i>
                                </div>


                                <input type="text" class="form-control" placeholder="Youtube Link" name="videolink" [(ngModel)]="videolink">
                            </div>


                            <div class="form-group col-md-12">
                                <label style="font-weight: 800;">Live Video Descritption</label>

                                <div class="input-group">

                                </div>


                                <input type="text" class="form-control" placeholder="Youtube Link" name="videoDes" [(ngModel)]="videoDes">
                            </div>
                            <button class="button btn1">Save</button>

                            <!-- <button class="button btn1" style="margin-left:10px;">Clear</button> -->

                        </form>



                        <table class="table table-hover text-center " style="margin-left:3%;">
                            <thead class="text-warning ">

                                <th>Title</th>
                                <th>code</th>
                                <th>Description</th>
                                <th>Delete</th>



                            </thead>
                            <tbody *ngFor="let user of Live;">
                                <tr>
                                    <td>{{user.title}}</td>
                                    <td>{{user.videolink}}</td>
                                    <td>{{user.videoDes}}</td>
                                    <td><i class="fa fa-trash fa-2x " (click)="delete(user.id)"></i></td>

                                </tr>
                            </tbody>
                        </table>


                    </div>

                </div>
            </div>

        </div>
    </div>










    <div class="col-lg-12 col-md-12 responsive">
        <div class="card">
            <div class="card-header card-header-warning" style="padding:0px 10px;">
                <h2 class="card-title">Webinar</h2>
                <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
            </div>
            <div class="card-body table-responsive table-wrapper-scroll-y my-custom-scrollbaring" id="orange">

                <div class="container " style=" margin-top: 10px;overflow:hidden;">

                    <div class="row">
                        <form (ngSubmit)="addweb()" style="width:100%;">
                            <div class="form-group col-md-12">
                                <label style="font-weight: 800;">Title</label>

                                <div class="input-group">



                                </div>


                                <input type="text" class="form-control" placeholder="Title Here" name="webtitle" [(ngModel)]="webtitle">
                            </div>



                            <div class="form-group col-md-12">
                                <label style="font-weight: 800;">webinar Video code</label>

                                <div class="input-group">


                                    <img src="https://www.process-insight.eu/wp-content/uploads/2017/05/webinar_icon.png" class=" icon" style="width:40px; height:38px;margin-right:5%;">
                                </div>


                                <input type="text" class="form-control" placeholder="Youtube Link" name="webvideolink" [(ngModel)]="webvideolink">
                            </div>


                            <div class="form-group col-md-12">
                                <label style="font-weight: 800;">webinar Video Description</label>

                                <div class="input-group">

                                </div>


                                <input type="text" class="form-control" placeholder="Youtube Link" name="webvideoDes" [(ngModel)]="webvideoDes">
                            </div>
                            <button class="button btn1">Save</button>

                            <!-- <button class="button btn1" style="margin-left:10px;">Clear</button> -->

                        </form>



                        <table class="table table-hover text-center " style="margin-left:3%;">
                            <thead class="text-warning ">

                                <th>Title</th>
                                <th>code</th>
                                <th>Description</th>
                                <th>Delete</th>



                            </thead>
                            <tbody *ngFor="let user of webinar;">
                                <tr>
                                    <td>{{user.title}}</td>
                                    <td>{{user.videolink}}</td>
                                    <td>{{user.videoDes}}</td>
                                    <td><i class="fa fa-trash fa-2x " (click)="deleteweb(user.id)"></i></td>

                                </tr>
                            </tbody>
                        </table>


                    </div>

                </div>
            </div>

        </div>
    </div>





    <!-- 

    <div class="col-lg-12 col-md-12" style="margin-left:9%;">
        <div class="card">
            <div class="card-header card-header-warning" style="padding:0px 10px;">
                <h2 class="card-title">Webinar</h2>
            
            </div>
            <div class="card-body table-responsive  table-wrapper-scroll-y my-custom-scrollbar">

                <div class="container " style=" margin-top: 10px;overflow:hidden;">

                    <div class="row">




                        <div class="form-group col-md-12">
                            <label style="font-weight: 800;">Title</label>

                            <div class="input-group">



                            </div>


                            <input type="text" class="form-control" placeholder="Title Here">
                        </div>

                        <div class="form-group col-md-12">
                            <label style="font-weight: 800;">Live Webinar Link</label>

                            <div class="input-group">


                                <img src="https://www.process-insight.eu/wp-content/uploads/2017/05/webinar_icon.png" class=" icon" style="width:40px; height:38px;margin-right:10%;">
                            </div>


                            <input type="text" class="form-control" placeholder="Webinar Link">
                        </div>

                        <button class="button btn1">Save</button>

                        <button class="button btn1" style="margin-left:10px;">Clear</button>

                        <table class="table table-hover text-center" style="margin-left:3%;">
                            <thead class="text-warning ">
                                <th>No</th>
                                <th>Title</th>
                                <th>Url</th>
                                <th>Delete</th>



                            </thead>
                            <tbody>
                                <tr>
                                    <td>1</td>
                                    <td>test</td>

                                    <td>test</td>
                                    <td><i class="fa fa-trash fa-2x "></i></td>

                                </tr>
                                <tr>
                                    <td>2</td>
                                    <td>test</td>
                                    <td>test</td>
                                    <td><i class="fa fa-trash fa-2x "></i></td>

                                </tr>
                                <tr>
                                    <td>3</td>
                                    <td>test</td>

                                    <td>test</td>
                                    <td><i class="fa fa-trash fa-2x "></i></td>

                                </tr>
                                <tr>
                                    <td>4</td>
                                    <td>test</td>

                                    <td>test</td>
                                    <td><i class="fa fa-trash fa-2x "></i></td>

                                </tr>
                                <tr>
                                    <td>5</td>
                                    <td>test</td>

                                    <td>test</td>
                                    <td><i class="fa fa-trash fa-2x "></i></td>

                                </tr>

                            </tbody>
                        </table>




                    </div>

                </div>
            </div>

        </div>
    </div>
</div> -->