<div class="row">
    <div class=" col-md-12">
        <div class="card" style="height:590px;">
            <div class="card-header card-header-warning">
                <h4 class="card-title">Visited Stalls</h4>
                <p class="card-category"> 15th September, 2020</p>
                <!-- <i class="material-icons" style="float:right;color:black;border:1px solid black;border-radius:5px;margin-left:650px;margin-top:-50px;">close</i> -->

                <div class="smcolumn" style="margin-top:-30px;margin-left:700px;">
                    <button (click)="onNoClick()" style="background:none;border:none;">
            <a  class="fa fa-window-close fa-2x iconsty" style="color: black;"> </a>
          </button>

                </div>

            </div>
            <div class="card-body table-responsive table-wrapper-scroll-y my-custom-scrollbar ">
                <table class=" table table-hover ">
                    <thead class="text-warning ">
                        <!-- <th>No</th> -->
                        <th>Exhibitor Name</th>
                        <th>Product Name</th>
                        <th>Files</th>
                        <!-- <th>Download</th> -->

                    </thead>
                    <tbody *ngFor="let item of save; let i=i ndex ">
                        <tr>
                            <!-- <td>{{i+1}}</td> -->
                            <td>{{item.sname}}</td>
                            <td>{{item.pinname}}</td>


                            <button class="fa fa-briefcase fa-2x " (click)="openDialog(item.sid,item.sname,item.pinid,item.pinname) " style="border:none;background:none; "></button>
                            <button class="fa fa-Download fa-2x " style="border:none;background:none; "></button>
                        </tr>

                    </tbody>
                </table>

            </div>
        </div>
    </div>