<app-pfheader></app-pfheader>
<div class="col-lg-12" id="con">
    <img src="https://firebasestorage.googleapis.com/v0/b/angboot-49fb1.appspot.com/o/stalls1.jpg?alt=media&token=d7f83a67-c089-4756-bf51-ee578fce5fc5" alt="Snow" style="width:100%;height:900px;">
    <!-- <div class="bottom-left">Bottom Left</div>
    <div class="top-left">Top Left</div>
    <div class="top-right">Top Right</div>
    <div class="bottom-right">Bottom Right</div> -->
    <a href="/profile/home/S4qVPo82XQHmSwQt06wU ">
        <div class="centered">
            <span class="tooltiptext">Samsung</span>

        </div>
    </a>


    <a href="/profile/home/S4qVPo82XQHmSwQt06wU ">
        <div class="stall2">
            <span class="stall2text">Samsung</span>

        </div>
    </a>

    <a href="/profile/home/UOdGeCOyXhWhUxuoyRf0 ">
        <div class="stall3">
            <span class="stall3text">Msi</span>

        </div>
    </a>

    <a href="/profile/home/mzwKFiWBQHZGusrCqeo8/">
        <div class="stall4">
            <span class="stall4text">Redmi</span>

        </div>
    </a>
    <a href="/profile/home/hLA7vXu2v20ucW0Y38mb">
        <div class="stall5">
            <span class="stall5text">Rolex</span>

        </div>
    </a>
    <a href="/profile/home/a822F6H7f6HbUaEbQvQJ">
        <div class="stall6">
            <span class="stall6text">Allen Solly</span>

        </div>
    </a>

    <div class=" footer " style="height:145px;background-color:rgba(255, 253, 253,1); ">

        <div class="smpd " style="margin-left:30%; ">






            <div class="smcolumn ">
                <a href="https://api.whatsapp.com/send?phone=+91 9500788278 " target="_blank " class=" fa-2x iconsty " style="font-size:40px;margin-left:170px; ">
                    <i class="fa fa-whatsapp "></i>
                    <p style="font-size: 10px;margin-left:160px; ">whatsappChat</p>
                </a>

            </div>

            <div class="smcolumn ">
                <a href="" class=" fa fa-briefcase fa-2x iconsty " style="font-size:40px;margin-left:140px;margin-top:5px;">
                    <p style="font-size: 10px; ">Briefcase</p>
                </a>

            </div>

            <div class="smcolumn ">

                <a href=" " class=" fa fa-youtube-play fa-2x iconsty " style="font-size:40px;margin-left:105px;margin-top:5px; ">
                    <p style="font-size: 10px;margin-left:0px; ">video</p>
                </a>


            </div>

            <div class="smcolumn ">
                <a href=" " class=" fa-2x iconsty " style="font-size:38px;margin-left:93px; ">
                    <img src="https://image.flaticon.com/icons/png/512/1484/1484147.png " width="40 " height="40 ">
                    <p style="font-size: 10px;margin-left:90px; ">FloorPlan</p>
                </a>

            </div>

            <div class="smcolumn ">
                <a href="auditorium " class=" fa-2x iconsty " style="font-size:38px;margin-left:70px; ">
                    <img src="https://www.fullbasketproperty.com/uploads/amenities/8befaeea578a051f1ed0db4c7f10b755.png " width="40 " height="40 ">
                    <p style="font-size: 10px;margin-left:65px; ">Auditorium</p>
                </a>

            </div>
            <div class="smcolumn ">
                <a href="profile/res/o1yHRVf4X3VTAZH3eWJV " class=" fa-2x iconsty " style="font-size:40px;margin-left:35px; ">
                    <img src="https://cdn.iconscout.com/icon/premium/png-512-thumb/form-62-795552.png " width="40 " height="40 ">
                    <p style="font-size: 10px;margin-left:20px;margin-top:2.5%; ">Resourcecenter</p>
                </a>

            </div>


            <div class="smcolumn ">
                <a href="https://noticeperiod.xyz/sandbox2/ " class=" fa-2x iconsty " style="font-size:40px; ">
                    <img src="https://www.iconfinder.com/data/icons/3d-printer-5/64/10_map_location_pin_3d_printer_technology_gadget-512.png " width="40 " height="40 ">
                    <p style="font-size: 10px; ">3dstall</p>
                </a>

            </div>

        </div>

    </div>