import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { StallService} from 'src/app/service/stall.service'
import { ExstallpopupComponent } from 'src/app/component/exstallpopup/exstallpopup.component';
import { ActivatedRoute } from '@angular/router';
import { LiveComponent } from '../live/live.component';
@Component({
  selector: 'app-homefooter',
  templateUrl: './homefooter.component.html',
  styleUrls: ['./homefooter.component.scss']
})
export class HomefooterComponent implements OnInit {
  stall: { id: string; name: any; bgimage: any; linkedin: any; insta: any; facebook: any; twitter: any; whatsapp: any; zoom: any; }[];
  id: string;

  constructor(private actRoute: ActivatedRoute,public StallService:StallService,public dialog: MatDialog,public fireservices:AngularFirestore) { }


  ngOnInit(): void {
   
    
  }

  open(){
    const dialogRef = this.dialog.open(ExstallpopupComponent, {
      width: '900px',
      height: '700px',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  openlive(){
    // console.log(a,"ennna tha nadakattu ")

    const dialogRef = this.dialog.open(LiveComponent, {
      data :{id:"aEcnUVo0gtc"}
       
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });

  }

}
