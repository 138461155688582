<nav class="navbar navbar-expand-lg navbar-light  navbar-default upper-overlay" style="padding: 10px;">
    <a class="navbar-brand" href="#" style="padding-left: 10px;">
        <h2 style="margin-top:10%;">Virtual Expo 2020 Admin Panel</h2>
    </a>

    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link fa fa-home fa-2x" style="font-weight:bold;padding-left: 20px; padding-right: 20px; font-size-adjust: initial;" aria-hidden="true" href="/home" data-toggle="tooltip" data-placement="top" title="Hooray!"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" (click)="checkout()" class="nav-link fa fa-sign-out  fa-2x" style="font-weight:bold;color:black;padding-left: 20px; padding-right: 20px;margin-top:10px;" aria-hidden="true"></a>
                <p style="font-size: 10px;margin-top:-10px;margin-left:16%;">Logout</p>
            </li>
            <!--  <li class="nav-item">
                <a class="nav-link fa fa-arrow-circle-o-left fa-2x" href='http://localhost:3000/index.html' style="font-weight:bold;color:black;padding-left: 10px; padding-right: 20px;" aria-hidden="true"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link fa fa-map-marker fa-2x" style="font-weight:bold;color:black;padding-left: 20px; padding-right: 20px; " aria-hidden="true"></a>
            </li>

            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" class="nav-link fa fa-user-circle-o  fa-2x" style="font-weight:bold;color:black;padding-left: 20px; padding-right: 20px;" aria-hidden="true"></a>
            </li> -->

            <!-- <li class="nav-item">
                <a class="nav-link " aria-hidden="true" (click)="close(' ')" class="nav-link fa fa-close  fa-2x" style="padding-left: 20px; padding-right: 20px;" aria-hidden="true"></a>
            </li> -->


            <!-- <li class="nav-item">
         <button  (click)="check()">
          <a class="nav-link fa fa-user-circle-o  fa-2x" style="padding-left: 20px; padding-right: 20px; " aria-hidden="true" href="#" ></a>
          </button>
         </li> -->
            <!-- <li class="nav-item">
                <a class="nav-link " aria-hidden="true" class="nav-link  fa-2x" aria-hidden="true"><span style="font-size:18px;margin-left:400px;padding-left: 20px; padding-right:20px; font-weight:bold;color:black;">Attendies: 50</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" class="nav-link  fa-2x" aria-hidden="true"><span style="font-size:18px;padding-left: 20px; padding-right:20px; font-weight:bold;color:black;"> Your Stall Visitor: 4</span></a>
            </li> -->


        </ul>
    </div>
</nav>