import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { AuthService } from 'src/app/service/auth.service';
import { StallService} from 'src/app/service/stall.service';
import { StalldocComponent } from 'src/app/component/stalldoc/stalldoc.component';


export interface DialogDatacp {

  id:string
  
}



@Component({
  selector: 'app-briefpopupsadex',
  templateUrl: './briefpopupsadex.component.html',
  styleUrls: ['./briefpopupsadex.component.scss']
})
export class BriefpopupsadexComponent implements OnInit {

  pin: { pinid: string; pinname: any; pinleft: any; pintop: any; pinyou: any; pinloc: string; }[];
  j=0;
  stall: any;

  constructor(public dialog: MatDialog,public StallService:StallService,@Inject(MAT_DIALOG_DATA) public data: DialogDatacp ) { }

  ngOnInit(): void {

    console.log(this.data.id,"hiiiiiiiiiii")
    this.StallService.get_stallidfromex(this.data.id['id']).subscribe(data => {
      this.stall = data.map(e => {
        return {
          id: e.payload.doc.id,
          stallid: e.payload.doc.data()['stallid'],
        

        };
      })
      // console.log(this.stall)
      for (var i=0;i<this.stall.length;i++){
        this.StallService.getallpin(this.stall[i]['stallid']).subscribe(data => {
          this.pin = data.map(e => {
            return {
              pinid: e.payload.doc.id,
              pinname: e.payload.doc.data()['name'],
              pinleft: e.payload.doc.data()['left'],
              pintop: e.payload.doc.data()['top'],
              pinyou: e.payload.doc.data()['youtube'],
              pinloc:" top:"+e.payload.doc.data()['top']+"%;  left:"+ e.payload.doc.data()['left']+"%;"
            };
          })
           
    
          this.stall[this.j].pin=(this.pin);
          this.j=this.j+1; 
          // console.log(this.stall)
      })}
    })
  }


  openDialog(a,b,c,d): void {
    const dialogRef = this.dialog.open(StalldocComponent, {
      width: '900px',
      height: '700px',
      data: {stallid : a , stallname:b , pinid:c ,pinname:d}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    
    });
  }
  onNoClick(): void {
    this.dialog.closeAll();
  }
  





}
