import { Component, OnInit } from '@angular/core';
import { StallService} from 'src/app/service/stall.service'

@Component({
  selector: 'app-adminactivity',
  templateUrl: './adminactivity.component.html',
  styleUrls: ['./adminactivity.component.scss']
})
export class AdminactivityComponent implements OnInit {

  title: any;
  des: any;

  etime: any;
  stime: any;
  record: { stime: any; etime: any; title: any; des: any; };
  activity: { id: string; des: any; etime: any; stime: any; title: any; }[];



  constructor(public StallService:StallService) { }

  ngOnInit(): void {
    this.StallService.get_allactivity().subscribe(data => {
      this.activity = data.map(e => {
        return {
          id: e.payload.doc.id,
          des:e.payload.doc.data()['des'],
          etime :e.payload.doc.data()['etime'],
          stime :e.payload.doc.data()['stime'],
          title :e.payload.doc.data()['title'],


        
        };
      })
      // console.log(this.activity)
    })
  }

  add()
  {
    this.record={
      stime: this.stime,
      etime:this.etime,
      title:  this.title,
      des:  this.des,
    }
 this.StallService.addactivity(this.record)
  
  }

  delete(id){
    this.StallService.deleteactivity(id)

  }

}
