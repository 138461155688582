<!-- <div class="footer" style="height:160px;">

    <div class="smpd" style="margin-left:40%;margin-top: 20px;">

        <div class="smcolumn ">
            <a (click)="openDialogbrief() " class="fa fa-info fa-2x iconsty " style="padding-left:50px;font-size:44px;margin-top:-2px; "></a>
        </div>
        <div class="smcolumn ">
            <a href=" " class="fa fa-envelope fa-2x iconsty " style="font-size:38px;padding-left:42px; "></a>
        </div>

        <div class="smcolumn ">
            <a href=" " class="fa fa-youtube-play fa-2x iconsty " style="font-size:41px; "></a>
        </div>

        <div class="smcolumn ">
            <a href="auditorium" class=" fa-2x iconsty " style="font-size:38px; ">
                <img src="https://www.fullbasketproperty.com/uploads/amenities/8befaeea578a051f1ed0db4c7f10b755.png " width="40 " height="33 " style="margin-top:10%; ">


            </a>

        </div>


        <div class="smcolumn ">
            <a href="res/o1yHRVf4X3VTAZH3eWJV" class=" fa-2x iconsty " style="font-size:40px; ">
                <img src="https://cdn.iconscout.com/icon/premium/png-512-thumb/form-62-795552.png " width="40 " height="40 ">
                <p style="font-size:10px;">Resourcecenter
                </p>
            </a>
        </div>

    </div>

</div> -->

<div class="footer" style="height: 100px; background-color: rgba(255, 253, 253, 1)">
    <div class="smpd" style="margin-left: 35%">
        <div class="smcolumn">
            <a [routerLink]="['/owner']" class="fa-2x iconsty" style="font-size: 40px; margin-left: 150px">
                <i class="fa fa-commenting-o"></i>
                <p style="font-size: 10px; margin-left: 157px">Chat</p>
            </a>
        </div>
        <div class="smcolumn">
            <a href="/view" class="fa-2x iconsty" target="_blank" style="font-size: 40px; margin-left: 130px">
                <i class="fa fa-eye"></i>
                <p style="font-size: 10px; margin-left: 130px">YourStall</p>
            </a>
        </div>

        <div class="smcolumn ">
            <a (click)="openDialogbrief() " class=" fa-2x iconsty " style="font-size:40px;margin-left:130px; ">

                <i class="fa fa-info "></i>
                <p style="font-size: 10px;margin-left:130px; ">Info</p>
            </a>

        </div>

        <div class="smcolumn">
            <a href="https://api.whatsapp.com/send?phone=+91 9500788278 " target="_blank " class="fa-2x iconsty" style="font-size: 40px; margin-left: 110px">
                <i class="fa fa-whatsapp"></i>
                <p style="font-size: 10px; margin-left: 100px">whatsappChat</p>
            </a>
        </div>

        <div class="smcolumn">
            <a (click)="openlive()" class="fa-2x iconsty" style="margin-left: 82px">
                <i class="fa fa-youtube-play fa-2x iconsty" style="font-size: 40px"></i>
                <p style="font-size: 10px; margin-left: 90px">Video</p>
            </a>
        </div>

        <div class="smcolumn">
            <a href="exauditorium " class="fa-2x iconsty" style="font-size: 38px; margin-left: 70px">
                <img src="https://www.fullbasketproperty.com/uploads/amenities/8befaeea578a051f1ed0db4c7f10b755.png " width="40 " height="40 " />
                <p style="font-size: 10px; margin-left: 65px">Auditorium</p>
            </a>
        </div>
        <div class="smcolumn">
            <a href="exresource/o1yHRVf4X3VTAZH3eWJV " class="fa-2x iconsty" style="font-size: 38px; margin-left: 35px">
                <img src="https://cdn.iconscout.com/icon/premium/png-512-thumb/form-62-795552.png " width="40 " height="40 " />
                <p style="font-size: 10px; margin-left: 20px; margin-top: 1.5%">
                    Resourcecenter
                </p>
            </a>
        </div>
    </div>
</div>