import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StalldocComponent } from 'src/app/component/stalldoc/stalldoc.component';

import { AuthService } from 'src/app/service/auth.service';
import { CrudService } from 'src/app/service/crud.service';

export interface DialogData {
  id : string
}


@Component({
  selector: 'app-briefpopup',
  templateUrl: './briefpopup.component.html',
  styleUrls: ['./briefpopup.component.scss']
})
export class BriefpopupComponent implements OnInit {

  save: any;

  constructor(public dialog: MatDialog,public crudservice:CrudService,@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {

    this.crudservice.get_saveddoc(this.data.id).subscribe(data => {
      this.save = data.map(e => {
       
        return {
         
          id: e.payload.doc.id,
          pinname: e.payload.doc.data()['pname'],
          pinid: e.payload.doc.data()['pid'],
          sid: e.payload.doc.data()['sid'],
          sname: e.payload.doc.data()['sname'],
        };})
    
      //  console.log(this.save)
        
    })
  }

  openDialog(a,b,c,d): void {
    const dialogRef = this.dialog.open(StalldocComponent, {
      width: '900px',
      height: '700px',
      data: {stallid : a , stallname:b , pinid:c ,pinname:d}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  onNoClick(): void {
    this.dialog.closeAll();
  }
  

}
