import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'app-exstallpopup',
  templateUrl: './exstallpopup.component.html',
  styleUrls: ['./exstallpopup.component.scss']
})
export class ExstallpopupComponent implements OnInit {


  constructor(public dialog: MatDialog,) { }

  ngOnInit(): void {
  }
  onNoClick(): void {
    this.dialog.closeAll();
  }

}
