<body>
    <div class="container">
        <div class="d-flex justify-content-center h-100">
            <div class="card" style="box-shadow: 0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.1);">
                <div class="card-header" style="  background: linear-gradient(60deg, #ffa726, #fb8c00);">
                    <h2 class="signinheading text-center" style="color:orange;margin-top:5%;color:white;">SIGN IN HERE - ADMIN</h2>
                    <!-- <div class="d-flex justify-content-end social_icon">
                        <span><i class="fa fa-facebook-square"></i></span>
                        <span><i class="fa fa-google-plus-square"></i></span>
                        <span><i class="fa fa-twitter-square"></i></span>
                    </div> -->
                </div>
                <div class="card-body">
                    <form (ngSubmit)="login()">
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fa fa-user"></i></span>
                            </div>
                            <input type="text" class="form-control" placeholder="username" name="email"  [(ngModel)]="email">

                        </div>
                        <div class="input-group form-group">
                            <div class="input-group-prepend">
                                <span class="input-group-text"><i class="fa fa-key"></i></span>
                            </div>
                            <input type="password" class="form-control" placeholder="password" name="password" [(ngModel)]="password" >
                        </div>
                        <div class="row align-items-center remember" style="margin-left:12%;margin-top:2%;color:black;">
                            <input type="checkbox" style="margin-top: 2%;;">Remember Me
                        </div>
                        <div class="form-group" style="margin-right:35%;     margin-top:4%;">
                            <input type="submit" value="Login" class="btn float-right login_btn">
                        </div>
                    </form>
                </div>
                <!-- <div class="card-footer">
                    <div class="d-flex justify-content-center links">
                        Don't have an account?<a href="/register" >Sign Up</a>
                    </div>
                    <div class="d-flex justify-content-center">
                        <a href="#">Forgot your password?</a>
                    </div>
                </div> -->
            </div>
        </div>
    </div>


    <!-- <div class="container">
        <div class="row justify-content-center p-4">
            <div class="col-md-6">
                <form (ngSubmit)="login()">
                <h3>Login</h3>
                <div class="form-group p-2">
                <input type="email" name="email" class="form-control" [(ngModel)]="email" placeholder="Enter email Id" required>
            </div>
            <div class="form-group p-2">
                <input type="password" name="password" class="form-control" [(ngModel)]="password" placeholder="Enter Password" required>
            </div>
            <div class="form-group p-2">
                <button type="submit" class="btn btn-primary">Login</button>
                <div class="register"><a href="/register" routerLink="/register">New User Register</a></div>
          
            </div>
            </form>
    
            <button  (click)="resetpass()">Request Password Change</button>
            <div class="form-group">
                <p *ngIf="errorMessage.length > 0" class="text-danger">
                    {{errorMessage}}</p>
            
                <p *ngIf="error.message.length > 0" class="text-danger">
                    {{error.message}} </p>
            
               
            </div>
            </div>
            
        </div>
    </div> -->

</body>