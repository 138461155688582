import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import {StallModule} from 'src/app/module/stall/stall.module'

@Injectable({
  providedIn: 'root'
})
export class StallService {

  a: StallModule;
  visitor: { id: string; videolink: any; }[];


  ngOnInit(){}
  constructor(public fireservices:AngularFirestore) { }
  get_allactivity(){
    return this.fireservices.collection('activity/', ref => ref.orderBy('title','asc')).snapshotChanges();

  }
  visitorcount(id){
return this.fireservices
.collection('stall')
.doc(id)
.collection('visitor')

  }

  get_visitor(id){
    return this.fireservices.collection('stall/'+id+'/visitor').snapshotChanges();
  }

  addvisior(currentUser,id){
    const visitor={
      email:currentUser

    }
    console.log("id",id)
    this.fireservices.collection("stall/"+id+"/visitor").add(visitor)
 

    
  }
  get_vis(id) {
    return this.fireservices.collection("stall/"+id+"/visitor").snapshotChanges()
  }

 

  updatestallonex(exno,id){
    const stallid={
stallid:id
    }
    this.fireservices.collection('exhibitor/'+exno+'/stall').add(stallid)

  }

  get_allLiveEvent(){
    return this.fireservices.collection('Live/').snapshotChanges();

  }


  get_allwebinarEvent(){
    return this.fireservices.collection('webinar/').snapshotChanges();

  }

  

  addlive(record)
  {
    console.log(record)
    this.fireservices.collection('Live').add(record)
  }
  deleteLive(id){
    this.fireservices.collection('Live/').doc(id).delete().then(function() {
      console.log("Document successfully deleted!");
  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });

  }


  
  addwebinar(record)
  {
    console.log(record)
    this.fireservices.collection('webinar').add(record)
  }
  deletewebinar(id){
    this.fireservices.collection('webinar/').doc(id).delete().then(function() {
      console.log("Document successfully deleted!");
  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });

  }

  deleteactivity(id){
    this.fireservices.collection('activity/').doc(id).delete().then(function() {
      console.log("Document successfully deleted!");
  }).catch(function(error) {
      console.error("Error removing document: ", error);
  });

  }

  addactivity(record)
  {
    console.log(record)
    this.fireservices.collection('activity/').add(record)
  }

  updateuserstatus(id,acess){ 
    const eod=!acess
    this.fireservices.collection('vrcustomer/').doc(id).update({
      acess: eod
  })

  }
 
  updateexstatus(id,acess){
    const eod=!acess
    this.fireservices.collection('exhibitor/').doc(id).update({
      acess: eod
  })

  }



  get_allvisitor(){
    return this.fireservices.collection('vrcustomer/').snapshotChanges();
  }
  get_allexhibitor(){
    return this.fireservices.collection('exhibitor/').snapshotChanges();
  }

  get_allex(){
    return this.fireservices.collection('exhibitor/').snapshotChanges();
  }
get_allstall(){
  return this.fireservices.collection('stall/').snapshotChanges();
}

get_allres(){
  return this.fireservices.collection('Resource/').snapshotChanges();
}

uploadstall(stall){
 return this.fireservices.collection("stall").add(stall).then(function(docRef) {
    return docRef.id
    
 })
}

uploadproduct(product,stalldocid){
 return this.fireservices.collection("stall/"+stalldocid+'/pin').add(product)

}

get_stallidfromex(id){
return this.fireservices.collection("exhibitor/"+id+"/stall").snapshotChanges();
}

gettestmoni(id){
  return this.fireservices.collection("exhibitor/"+id+"/Testimonial").snapshotChanges();
}



  async get_stall(id)
  {
  this.fireservices.collection("stall/").doc(id).get()
    .toPromise()
    .then(function(doc) {

      if (doc.exists) {
          // console.log("Document data:", doc.data())
        const a = doc.data() as StallModule ;
       a.id=doc.id
        return a
      } else {
          // doc.data() will be undefined in this case
          console.log("No such document!");
      }})
  .catch(function(error) {
      console.log("Error getting document:", error);
  });
 
    
  }





  getallpin(stallid) {
    return this.fireservices.collection('stall/'+stallid+'/pin').snapshotChanges();
  }

    getallpinforres(stallid) {
      
    return this.fireservices.collection('Resource/'+stallid+'/pin').snapshotChanges();
  }

  getpin(stallid) {

    return this.fireservices.collection('stall/'+stallid+'/pin/').snapshotChanges();
  }

  getrespin(stallid) {

    return this.fireservices.collection('Resource/'+stallid+'/pin/').snapshotChanges();
  }
}
