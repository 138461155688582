<nav class="navbar navbar-expand-lg navbar-light  navbar-default upper-overlay" style="padding: 10px;">
    <a class="navbar-brand" href="#" style="padding-left: 10px;">
        <h2>Virtual Expo 2020</h2>
    </a>

    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link fa fa-home fa-2x" style="padding-left: 20px; padding-right: 20px; font-size-adjust: initial;" aria-hidden="true" data-toggle="tooltip" data-placement="top" title="Hooray!"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link fa fa-arrow-circle-o-left fa-2x" style="padding-left: 10px; padding-right: 20px;" aria-hidden="true"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link fa fa-map-marker fa-2x" style="padding-left: 20px; padding-right: 20px; " aria-hidden="true" href='home'></a>
            </li>
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" class="nav-link fa fa-user-circle-o  fa-2x" style="padding-left: 20px; padding-right: 20px;" aria-hidden="true"></a>
            </li>




        </ul>
    </div>
</nav>