import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-auditorium',
  templateUrl: './auditorium.component.html',
  styleUrls: ['./auditorium.component.scss']
})
export class AuditoriumComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
