import { Component, OnInit } from '@angular/core';
import { StallService} from 'src/app/service/stall.service'
import { AngularFirestore } from '@angular/fire/firestore';
import {MatDialog} from '@angular/material/dialog';
import { StalldocComponent } from '../stalldoc/stalldoc.component';
import { async } from 'rxjs/internal/scheduler/async';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-user-management',
  templateUrl: './user-management.component.html',
  styleUrls: ['./user-management.component.scss']
})
export class UserManagementComponent implements OnInit {
  stall: any;

  id: string;
  j=0;
  pin: { pinid: string; pinname: any; pinleft: any; pintop: any; pinyou: any; pinloc: string; }[];

  
  constructor(public dialog: MatDialog,public StallService:StallService,public fireservices:AngularFirestore,private actRoute: ActivatedRoute,public authservice: AuthService,private router: Router) { }
  ngOnDestroy(): void {
    throw new Error("Method not implemented.");
  }
  ngOnInit(): void {
    this.id = this.actRoute.snapshot.paramMap.get('id');
    
    
        this.StallService.get_allres().subscribe(data => {
          this.stall = data.map(e => {
            return {
              id: e.payload.doc.id,
              name: e.payload.doc.data()['name'],
              bgimage : e.payload.doc.data()['bgimage'],
              linkedin : e.payload.doc.data()['linkedin'],
              insta : e.payload.doc.data()['insta'],  
              facebook : e.payload.doc.data()['facebook'],
              twitter : e.payload.doc.data()['twitter'], 
              whatsapp : e.payload.doc.data()['whatsapp'], 
              zoom : e.payload.doc.data()['zoom'], 
            };
          })
          for (var i=0;i<this.stall.length;i++){
            console.log(this.stall[i]['id'])
            this.StallService.getallpinforres(this.stall[i]['id']).subscribe(data => {
              this.pin = data.map(e => {
                return {
                  pinid: e.payload.doc.id,
                  pinname: e.payload.doc.data()['name'],
                  pinleft: e.payload.doc.data()['left'],
                  pintop: e.payload.doc.data()['top'],
                  pinyou: e.payload.doc.data()['youtube'],
                  pinloc:" top:"+e.payload.doc.data()['top']+"%;  left:"+ e.payload.doc.data()['left']+"%;"
                };
              })
               
        
              this.stall[this.j].pin=(this.pin);
              this.j=this.j+1; 
              console.log(this.stall)
             
          })}
    
    
        
          // console.log("hi")
          // console.log(this.pin)
        
        })
  }

  openDialog(a,b,c,d): void {
    console.log(a,b,c,d)
    const dialogRef = this.dialog.open(StalldocComponent, {
      width: 'auto',
      height: 'auto',
      data: {stallid : a , stallname:b , pinid:c ,pinname:d,res:true}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
   
    });
  }
  // ngUnsubscribe: Subject<any> = new Subject<any>();
  // actions = UserManagementActions;

  // // The user management actoin to be completed
  // mode: string;
  // // Just a code Firebase uses to prove that
  // // this is a real password reset.
  // actionCode: string;

  // oldPassword: string;
  // newPassword: string;
  // confirmPassword: string;

  // actionCodeChecked: boolean;

  // constructor(
  //   private router: Router,
  //   private activatedRoute: ActivatedRoute,
  //   private authService: AuthService
  // ) { }

  // ngOnInit() {
  //   this.activatedRoute.queryParams
  //     .pipe(takeUntil(this.ngUnsubscribe))
  //     .subscribe(params => {
  //       // if we didn't receive any parameters, 
  //       // we can't do anything
  //       if (!params) this.router.navigate(['/home']);

  //       this.mode = params['mode'];
  //       this.actionCode = params['oobCode'];

  //       switch (params['mode']) {
  //         case UserManagementActions.resetPassword: {
  //           // Verify the password reset code is valid.
  //           this.authService
  //           .getAuth()
  //           .verifyPasswordResetCode(this.actionCode)
  //           .then(email => {
  //             this.actionCodeChecked = true;
  //           }).catch(e => {
  //             // Invalid or expired action code. Ask user to try to
  //             // reset the password again.
  //             alert(e);
  //             this.router.navigate(['/auth/login']);
  //           });
  //         } break
  //         case UserManagementActions.recoverEmail: {

  //         } break
  //         case UserManagementActions.verifyEmail: {

  //         } break
  //         default: {
  //           console.log('query parameters are missing');
  //           this.router.navigate(['/auth/login']);
  //         }
  //       }
  //     })
  // }

  // ngOnDestroy() {
  //   // End all subscriptions listening to ngUnsubscribe
  //   // to avoid memory leaks.
  //   this.ngUnsubscribe.next();
  //   this.ngUnsubscribe.complete();
  // }

  // /**
  //  * Attempt to confirm the password reset with firebase and
  //  * navigate user back to home.
  //  */
  // handleResetPassword() {
  //   if (this.newPassword != this.confirmPassword) {
  //     alert('New Password and Confirm Password do not match');
  //     return;
  //   }
  //   // Save the new password.
  //   this.authService.getAuth().confirmPasswordReset(
  //       this.actionCode,   
  //       this.newPassword
  //   )
  //   .then(resp => {
  //     // Password reset has been confirmed and new password updated.
  //     alert('New password has been saved');
  //     this.router.navigate(['/auth/login']);
  //   }).catch(e => {
  //     // Error occurred during confirmation. The code might have
  //     // expired or the password is too weak.
  //     alert(e);
  //   });
  // }
     
}
