import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { Router } from '@angular/router';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  email = '';
  password = '';
  errorMessage = ''; // validation error handle
  error: { name: string; message: string } = { name: '', message: '' }; // for firbase error handle

  constructor(
    private authservice: AuthService,
    private router: Router,
    private afAuth: AngularFireAuth
  ) {}

  ngOnInit(): void {}

  clearErrorMessage() {
    this.errorMessage = '';
    this.error = { name: '', message: '' };
  }

  login() {
    this.clearErrorMessage();
    if (this.validateForm(this.email, this.password)) {
      this.authservice
        .loginWithCustomerEmail(this.email, this.password)
        .then(() => {
          this.router.navigate(['/profile']);
        })
        .catch((_error) => {
          this.error = _error;
          this.router.navigate(['/login']);
        });
    }
  }

  validateForm(email, password) {
    if (email.lenght === 0) {
      this.errorMessage = 'please enter email id';
      return false;
    }

    if (password.lenght === 0) {
      this.errorMessage = 'please enter password';
      return false;
    }

    if (password.lenght < 6) {
      this.errorMessage = 'password should be at least 6 char';
      return false;
    }

    this.errorMessage = '';
    return true;
  }

  resetpass() {
    return this.afAuth
      .sendPasswordResetEmail('bharathinfoclg@gmail.com', {
        url: 'http://localhost:4200/auth',
      })
      .then(
        () => alert('Password request has been sent to admin'),
        (rejectionReason) => alert(rejectionReason)
      )
      .catch((e) =>
        alert('An error occurred while attempting to connect with admin')
      );
  }
}
