import { Component, OnInit } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-vfx',
  templateUrl: './vfx.component.html',
  styleUrls: ['./vfx.component.scss']
})
export class VfxComponent implements OnInit {
  name = 'Set iframe source';
  url: string = "https://noticeperiod.xyz/file/sandbox2/index.html";
  urlSafe: SafeResourceUrl;


  constructor(public sanitizer: DomSanitizer,public authservice: AuthService,private router: Router) { }

  ngOnInit(): void {
    
    this.urlSafe= this.sanitizer.bypassSecurityTrustResourceUrl(this.url);
  }


  check()
{
  
  console.log(this.authservice.currentUser)
  if (this.authservice.currentUser==null){
    this.router.navigate(['/login'])
  }
  else{
    

    this.router.navigate(['/profile'])

    
  }  
}

}
