<app-exheader></app-exheader>
<div class="container " style=" margin-top: 100px;">
    <div id='checkdetails' class="card">
        <div class="card-header card-header-warning" style="padding:0px 10px;">
            <h1 class="card-title">Enter Cridentials </h1>
            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
        </div>
        <div class="card-body table-responsive">

            <div class="container " style=" margin-top: 10px;">

                <div class="row">

                    <form (ngSubmit)="check()">
                    

                        <div class="form-group col-md-12">
                            <label style="font-weight: 800;">Stall Id</label>
                            <input type="text" class="form-control" name="stallnumber" [(ngModel)]="stallnumber">

                        </div>




                        <div class="col text-center" style="margin-top: 10px; margin-bottom: 10px;">
                            <button type="submit" class="btn buttoncolor" style="background-color:#fe9d18; ">Submit</button>
                        </div>

                    </form>

                </div>



            </div>


        </div>

    </div>

    <div id="upload" style="display:none ">
        <form (ngSubmit)="upload() ;clicked = true;">
            <div class="col-lg-12 col-md-12 ">
                <div class="card ">
                    <div class="card-header card-header-warning " style="padding:0px 10px; ">
                        <h1 class="card-title ">Stall </h1>
                        <!-- <p class="card-category ">New employees on 15th September, 2016</p> -->
                    </div>
                    <div class="card-body table-responsive ">

                        <div class="container " style=" margin-top: 10px; ">

                            <div class="row ">


                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; "> Name</label>
                                    <input type="text " class="form-control " name="pname" [(ngModel)]="pname">
                                </div>

                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; "> Video link</label>
                                    <input type="text " class="form-control " name="pdes" [(ngModel)]="pdes">

                                </div>

                               



                                <div class="col text-center " style="margin-top: 10px; margin-bottom: 10px; ">
                                <button type="submit " class="btn buttoncolor " style="background-color:#fe9d18; " [disabled]="clicked">Submit</button>
                            </div>
                            </div>



                        </div>


                    </div>

                </div>



            </div>
            </form>
    
    </div>
    
</div>