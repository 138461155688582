<nav class="navbar navbar-expand-lg navbar-light  navbar-default upper-overlay" style="padding: 10px;">
    <a class="navbar-brand" href="#" style="padding-left: 10px;">
        <h2>Expo 2020 Visitor Dashboard</h2>
    </a>

    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" (click)="checkout()" class="nav-link fa fa-sign-out  fa-2x" style="font-weight:bold;color:black;padding-left: 20px; padding-right: 20px;margin-top: 10px;" aria-hidden="true">
                    <p style="font-size: 10px;">Logout</p>
                </a>

            </li>

            <li class="nav-item active">
                <a class="nav-link fa fa-home fa-2x" style="padding-left: 20px; padding-right: 20px; font-size-adjust: initial;" aria-hidden="true" href="/home" data-toggle="tooltip" data-placement="top" title="Hooray!"></a>
            </li>

            <!-- <li class="nav-item">
                <a class="nav-link fa-2x" href="/home" style="padding-left: 10px; padding-right: 20px;" aria-hidden="true">

                    <img src="https://www.iconfinder.com/data/icons/3d-printer-5/64/10_map_location_pin_3d_printer_technology_gadget-512.png" width="32 " height="29 "></a>

            </li> -->

            <li class="nav-item">
                <a class="nav-link fa fa-arrow-circle-o-left fa-2x" (click)="backClicked() " style="padding-left: 10px; padding-right: 20px;" aria-hidden="true"></a>
            </li>
            <li class="nav-item">
                <a href="/mapicon" class=" nav-link fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true ">
                    <!-- (click)="close( '/exprofile') "  -->
                    <img src="https://firebasestorage.googleapis.com/v0/b/angboot-49fb1.appspot.com/o/79-797480_markers-clipart-whiteboard-marker-map-icon-png-black.png?alt=media&token=0b403969-3bfb-4642-82fd-593a28ac4a5a" width="28 " height="25 "></a>
            </li>
            {{vis}}
            <li class="nav-item ">
                <a class="nav-link " aria-hidden="true " (click)="check() " class="nav-link fa fa-user-circle-o fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true "></a>
            </li>
            {{authservice.currentUserName}}
            <!-- <li class="nav-item ">
                <a class="nav-link " aria-hidden="true " (click)="close( '') " class="nav-link fa fa-close fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true "></a>
            </li> -->


            <!-- <li class="nav-item ">
         <button  (click)="check() ">
          <a class="nav-link fa fa-user-circle-o fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true " href="# " ></a>
          </button>
         </li> -->

            <!-- <li style="margin-left:600px; margin-top:10px;font-weight: bold;font-size: 15px;">
                <p>Your Stall Visitor: <span> {{count}}</span></p>
            </li> -->


            <li style="margin-left:600px; margin-top:10px;font-weight: bold;font-size: 15px;">
                <p> Stall Visitor: <span> 01</span></p>
            </li>
            <li style=" margin-left:30px;margin-top:10px;font-weight: bold;font-size: 15px;">
                <p>Attendies: <span>02 </span></p>
            </li>


        </ul>
    </div>
</nav>