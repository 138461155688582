import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { StallService} from 'src/app/service/stall.service'

export interface DialogDatacp {

  id:string
  
}

@Component({
  selector: 'app-live',
  templateUrl: './live.component.html',
  styleUrls: ['./live.component.scss']
})
export class LiveComponent implements OnInit {
  Live: { id: string; videolink: any; videoDes: any; title: any; }[];
  selectedlive: any;

  constructor(public StallService:StallService,@Inject(MAT_DIALOG_DATA) public data: DialogDatacp) { }

  ngOnInit() {
    this.selectedlive=this.data.id

    // this.StallService.get_allLiveEvent().subscribe(data => {
    //   this.Live = data.map(e => {
    //     return {
    //       id: e.payload.doc.id,
    //       videolink :e.payload.doc.data()['videolink'],
    //       videoDes :e.payload.doc.data()['videoDes'],
    //       title :e.payload.doc.data()['Title'],


        
    //     };
    //   })
  
    //   console.log(this.Live[0])
    //   this.selectedlive=this.Live[this.data.id]
    //   console.log( this.selectedlive,"asddddddddddddddddddddd")
    // })
    const tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);
  }

}
