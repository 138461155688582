<div class="col-lg-12 col-md-12" style="padding:0px;">

    <div class="card" style="height:590px;">
        <div class="card-header card-header-warning">
            <h4 class="card-title">Stall Saved Files</h4>
            <p>Username:<span>  Name</span></p>
            <p style="float:right;margin-top:-30px;">No of exbitor:<span> 14</span></p>
            <!-- <i class="material-icons" style="float:right;color:black;border:1px solid black;border-radius:5px;margin-left:650px;margin-top:-65px;">close</i> -->

            <div class="smcolumn" style="margin-left:730px;margin-top:-65px;">
                <button (click)="onNoClick()" style="background:none;border:none;">
            <a  class="fa fa-window-close fa-2x iconsty" style="color: black;"> </a>
          </button>

            </div>
            <!-- <form action="">
                <label for="filter" style="color:white;">Filter:</label>
                <select name="filter" id="filter">
            
      <option value="volvo">Search </option>
      <option value="volvo">Volvo</option>
      <option value="saab">Saab</option>
      <option value="opel">Opel</option>
      <option value="audi">Audi</option>
    </select>

            </form> -->
            <!--             
<div class="dropdown">
    <button onclick="myFunction()" class="dropbtn">Filter</button>
    <div id="myDropdown" class="dropdown-content">
      <input type="text" placeholder="Search.." id="myInput" onkeyup="filterFunction()">
      <a href="#about">About</a>
      <a href="#base">Base</a>
      <a href="#blog">Blog</a>
      <a href="#contact">Contact</a>
      <a href="#custom">Custom</a>
      <a href="#support">Support</a>
      <a href="#tools">Tools</a>
    </div>
  </div> -->

            <!-- 

  <script>
    /* When the user clicks on the button,
    toggle between hiding and showing the dropdown content */
    function myFunction() {
      document.getElementById("myDropdown").classList.toggle("show");
    }
    
    function filterFunction() {
      var input, filter, ul, li, a, i;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      div = document.getElementById("myDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    }
    </script> -->



            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
        </div>

        <div class="card-body table-responsive text-center table-wrapper-scroll-y my-custom-scrollbar">
            <table class="table table-hover">
                <thead class="text-warning">
                    <th class="sticky">No</th>
                    <th class="sticky">Exhibitor Name</th>
                    <th class="sticky">Product Name</th>
                    <th class="sticky">Files</th>
                    <th class="sticky">Download</th>

                </thead>
                <tbody>
                    <tr>
                        <td>1</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>


                    </tr>
                    <tr>
                        <td>2</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>
                    <tr>
                        <td>3</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>
                    <tr>
                        <td>4</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>
                    <tr>
                        <td>5</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>
                    <tr>
                        <td>6</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>
                    <tr>
                        <td>7</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>
                    <tr>
                        <td>8</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>
                    <tr>
                        <td>9</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>
                    <tr>
                        <td>10</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>

                    <tr>
                        <td>11</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>

                    <tr>
                        <td>12</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>

                    <tr>
                        <td>13</td>
                        <td>Fare</td>

                        <td>Book Fare</td>
                        <td><i class="fa fa-briefcase fa-2x"></i></td>
                        <td><i class="fa fa-download fa-2x"></i></td>
                    </tr>

                </tbody>
            </table>
        </div>
    </div>
</div>