<!-- <h1 class="centeredheading" style="margin-top: 2%;"> Virtual Indian Handloom Expo 2020</h1>



<a href="login">

    <h1 class="top-left ">Visitor Login</h1>
</a>
<a href="exlogin">
    <h1 class="top-lefting ">Exhibitor Login</h1>
</a>
<a href="profile">
    <h1 class="centerprofile ">Visitor Profile</h1>
</a>
<a href="exprofile">
    <h1 class="centerexprofile ">Exhibitor Profile</h1>
</a>
<div class="dropdown top-righting ">
    <button class="dropbtn ">Visitor Registration Form</button>
    <div class="dropdown-content ">
        <a href="overseas">Overseas Trade  Visitor</a>
        <a href="agent">Buying Agent</a>
        <a href="domestic">Domestic Trade  Visitor </a>
    </div>
</div>

<a href="exregister ">
    <h1 class="top-right ">Exhibitor Registration Form</h1>
</a>

<a href="/adminlogin">
    <h1 class="top-rightt ">Admin</h1>
</a>






<a href="https://noticeperiod.xyz/sandbox2 ">
    <h1 href="http://localhost:3000/index.html " class="centered ">Virtual Expo Walkthrough</h1>
</a>
<img src="https://noticeperiod.xyz/stallimages/imgbghome.jpg " style="margin-top:11%;width: 100%; background-color: rgba(0, 0, 0, 0.5); "> -->




<body data-spy="scroll" data-target=".fixed-top">

    <!-- Preloader -->
    <!-- <div class="spinner-wrapper">
        <div class="spinner">
            <div class="bounce1"></div>
            <div class="bounce2"></div>
            <div class="bounce3"></div>
        </div>
    </div> -->
    <!-- end of preloader -->


    <!-- Navigation -->
    <nav class="navbar navbar-expand-md navbar-dark navbar-custom fixed-top" style="height:90px;">
        <!-- Text Logo - Use this if you don't have a graphic logo -->
        <!-- <a class="navbar-brand logo-text page-scroll" href="index.html">Leno</a> -->

        <!-- Image Logo -->
        <!-- <a class="navbar-brand logo-image" href="index.html"><img src="" alt="alternative"></a> -->
        <p style="font-size:20px;">Virtual Indian Handloom Expo 2020</p>

        <!-- Mobile Menu Toggle Button -->
        <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarsExampleDefault" aria-controls="navbarsExampleDefault" aria-expanded="false" aria-label="Toggle navigation">
            <span class="navbar-toggler-awesome fas fa-bars"></span>
            <span class="navbar-toggler-awesome fas fa-times"></span>
        </button>
        <!-- end of mobile menu toggle button -->

        <div class="collapse navbar-collapse" id="navbarsExampleDefault">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item">
                    <a class="nav-link page-scroll" href="login">Visitor Login <span class="sr-only">(current)</span></a>
                </li>
                <li class="nav-item">
                    <a class="nav-link page-scroll" href="exlogin">Exhibitor Login </a>
                </li>
                <li class="nav-item">
                    <a class="nav-link page-scroll" href="overseas">Overseas Form</a>
                </li>

                <!-- Dropdown Menu -->
                <!-- <li class="nav-item dropdown">
                    <a class="nav-link dropdown-toggle page-scroll" href="#details" id="navbarDropdown" role="button" aria-haspopup="true" aria-expanded="false">DETAILS</a>
                    <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                        <a class="dropdown-item" href="terms-conditions.html"><span class="item-text">TERMS CONDITIONS</span></a>
                        <div class="dropdown-items-divide-hr"></div>
                        <a class="dropdown-item" href="privacy-policy.html"><span class="item-text">PRIVACY POLICY</span></a>
                    </div>
                </li> -->
                <!-- end of dropdown menu -->

                <li class="nav-item">
                    <a class="nav-link page-scroll" href="agent">Agent Visitor Form</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link page-scroll" href="domestic">Domestic Visitor Form</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link page-scroll" href="exregister">Exhibitor Visitor Form</a>
                </li>
                <li class="nav-item">
                    <a class="nav-link page-scroll" href="/adminlogin"> Admin</a>
                </li>
            </ul>
            <!-- <span class="nav-item social-icons">
                <span class="fa-stack">
                    <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x"></i>
                        <i class="fab fa-facebook-f fa-stack-1x"></i>
                    </a>
                </span>
            <span class="fa-stack">
                    <a href="#your-link">
                        <i class="fas fa-circle fa-stack-2x"></i>
                        <i class="fab fa-twitter fa-stack-1x"></i>
                    </a>
                </span>
            </span> -->
        </div>
    </nav>
    <!-- end of navbar -->
    <!-- end of navigation -->


    <!-- Header -->
    <header id="header" class="header">
        <div class="header-content">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="text-container">
                            <h1> <span id="js-rotating" style="color:black;"></span>Virtual Indian Handloom Expo 2020</h1>
                            <!-- <p class="p-large">Leno is one of the easiest and feature packed marketing automation apps in the market. Download it today!</p> -->
                            <!-- <a class="btn-solid-lg page-scroll" href="#your-link"><i class="fab fa-apple"></i>APP STORE</a> -->
                            <a href="https://noticeperiod.xyz/sandbox2/ " class=" btn-solid-lg page-scroll ">Virtual Expo Walkthrough</a>
                        </div>
                    </div>
                    <!-- end of col -->
                    <div class="col-lg-6 ">
                        <!-- <div class="image-container ">
                            <img class="img-fluid " src="https://images.vexels.com/media/users/3/128109/isolated/preview/19620a1b49b2bd2385c816532c57f61d-isometric-mac-computer-by-vexels.png " lt="alternative ">

                        </div> -->

                    </div>
                    <!-- end of col -->
                </div>
                <!-- end of row -->
            </div>
            <!-- end of container -->
        </div>
        <!-- end of header-content -->
    </header>
    <!-- end of header -->
    <!-- end of header -->