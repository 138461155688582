import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-homenav',
  templateUrl: './homenav.component.html',
  styleUrls: ['./homenav.component.scss']
})
export class HomenavComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
