import { OwnerComponent } from './component/owner/owner.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './component/login/login.component';

import { FulllayoutComponent } from './component/fulllayout/fulllayout.component';
import { StalldocComponent } from './component/stalldoc/stalldoc.component';
import { UserManagementComponent } from './component/user-management/user-management.component';
import { ProfileComponent } from './component/profile/profile.component';

import { VfxComponent } from './component/vfx/vfx.component';
import { ExprofileComponent } from './component/exprofile/exprofile.component';
import { ExstalldocupComponent } from './component/exstalldocup/exstalldocup.component';

import { ExhibitorloginComponent } from './component/login/exhibitorlogin/exhibitorlogin.component';

import { ExproductupComponent } from './component/exproductup/exproductup.component';
import { ExproductvideoupComponent } from './component/exproductvideoup/exproductvideoup.component';
import { HomenavComponent } from './component/home/homenav/homenav.component';

import { PfdashComponent } from './component/profile/pfdash/pfdash.component';
import { FloorplanComponent } from './component/floorplan/floorplan.component';

import { ExresourceComponent } from './component/user-management/exresource/exresource.component';

import { ViewiconComponent } from './component/exprofile/viewicon/viewicon.component';

import { ChaticonComponent } from './component/chaticon/chaticon.component';

import { AdminloginComponent } from './component/login/adminlogin/adminlogin.component';
import { AdminComponent } from './component/admin/admin.component';
import { AdminvisitorComponent } from './component/admin/adminvisitor/adminvisitor.component';
 import { AdminexhibitorComponent } from './component/admin/adminexhibitor/adminexhibitor.component';
import { AdminliveComponent } from './component/admin/adminlive/adminlive.component';
import { AdminactivityComponent } from './component/admin/adminactivity/adminactivity.component';
import { AdminattendComponent } from './component/admin/adminattend/adminattend.component';

import { VisitoroverseasformComponent } from './component/visitorregform/visitoroverseasform/visitoroverseasform.component';
import { VisitordomesticformComponent } from './component/visitorregform/visitordomesticform/visitordomesticform.component';
import { VisitoragentformComponent } from './component/visitorregform/visitoragentform/visitoragentform.component';

import { ExhibitorsComponent } from './component/exhibitors/exhibitors.component';
import { MapiconComponent } from './component/mapicon/mapicon.component';
import { AuditoriumComponent } from './component/auditorium/auditorium.component';
import { ExauditoriumComponent } from './component/auditorium/exauditorium/exauditorium.component';
import { VisauditoriumComponent } from './component/auditorium/visauditorium/visauditorium.component';
// >>>>>>> f7f24ef8cb4cef950a5420ecc51100e6e414d8fa

export const routes: Routes = [
  {
    path: 'exlogin',
    component: ExhibitorloginComponent,
  },
  {
    path: 'home/:id',
    component: FulllayoutComponent,
  },
  {
    path: 'userMgmt',
    component: UserManagementComponent,
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'auditorium',
    component: AuditoriumComponent,

  },
  {
    path: 'visauditorium',
    component: VisauditoriumComponent,

  },
  {
    path: 'exauditorium',
    component:ExauditoriumComponent  ,
  }, 
  {
    path: 'owner',
    component: OwnerComponent,
  },
  {
    path: 'overseas',
    component: VisitoroverseasformComponent,
  },
  {
    path: 'domestic',
    component: VisitordomesticformComponent,
  },
  
  {
    path: 'agent',
    component: VisitoragentformComponent,
  },
  {
  
    path: 'exregister',
    component: ExhibitorsComponent,
  },
  {
    path: 'adminlogin',
    component: AdminloginComponent,

  },

  {
  
    path: 'admin',
    component:AdminComponent  ,
    children: [
      {
        path: 'adminvistior', // child route path
        component: AdminvisitorComponent, // child route component that the router renders
      },
      {
        path: 'adminexh', // child route path
        component: AdminexhibitorComponent, // child route component that the router renders
      },
      {
        path: 'adminlive', // child route path
        component: AdminliveComponent, // child route component that the router renders
      },
      {
        path: 'adminact', // child route path
        component: AdminactivityComponent, // child route component that the router renders
      },
      {
        path: 'adminattend', // child route path
        component: AdminattendComponent, // child route component that the router renders
      },
    
    ],

  }, 
  {
    path: 'profile',
    component: ProfileComponent,
    children: [
      {
        path: '', // child route path
        component: PfdashComponent, // child route component that the router renders
      },
      {
        path: 'home/:id',
        component: FulllayoutComponent,
      },

      {
        path: 'floorplan',
        component: FloorplanComponent,
      },
      {
        path: 'res/:id',
        component: UserManagementComponent,
      },
    ],
  },

  {
    path: 'stalldoc/:id/:eid/:ename',
    component: StalldocComponent,
  },
  {
    path: 'mapicon',
    component:MapiconComponent  ,
  }, 

  // {
  //   path: 'overseas',
  //   component: VisitoroverseasformComponent,
  // },
  // {
  //   path: 'domestic',
  //   component: VisitordomesticformComponent,
  // },

  // {
  //   path: 'agent',
  //   component: VisitoragentformComponent,
  // },

  {
    path: 'stalldoc/:id/:eid/:ename',
    component: StalldocComponent,
  },

  {
    path: 'exprofile',
    component: ExprofileComponent,
  },
  {
    path: 'exlogin',
    component: ExhibitorloginComponent,
  },

  {
    path: 'stalldocupload',
    component: ExstalldocupComponent,
  },
  {
    path: 'productup',
    component: ExproductupComponent,
  },
  {
    path: 'productvideoup',
    component: ExproductvideoupComponent,
  },

  {
    path: 'res/:id',
    component: UserManagementComponent,
  },

  {
    path: 'home',
    component: VfxComponent,
  },

  {
    path: 'floorplan',
    component: FloorplanComponent,
  },

  {
    path: 'exresource/:id',
    component: ExresourceComponent,
  },
  {
    path: 'view',
    component: ViewiconComponent,
  },
  {
    path: 'chaticon',
    component: ChaticonComponent,
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
