import { Component, OnInit } from '@angular/core';
import{StallService} from 'src/app/service/stall.service'

@Component({
  selector: 'app-adminlive',
  templateUrl: './adminlive.component.html',
  styleUrls: ['./adminlive.component.scss']
})
export class AdminliveComponent implements OnInit {
  title: any;
  videolink: any;
  videoDes: any;
  Live: { id: string; videolink: any; videoDes: any; title: any; }[];
  webtitle: any;
  webvideolink: any;
  webvideoDes: any;
  webinar: { id: string; videolink: any; videoDes: any; title: any; }[];
  
  constructor(public StallService:StallService) { }

  ngOnInit(): void {
    this.StallService.get_allLiveEvent().subscribe(data => {
      this.Live = data.map(e => {
        return {
          id: e.payload.doc.id,
          videolink :e.payload.doc.data()['videolink'],
          videoDes :e.payload.doc.data()['videoDes'],
          title :e.payload.doc.data()['Title'],


        
        };
      })
      // console.log(this.Live)
    })

    this.StallService.get_allwebinarEvent().subscribe(data => {
      this.webinar = data.map(e => {
        return {
          id: e.payload.doc.id,
          videolink :e.payload.doc.data()['videolink'],
          videoDes :e.payload.doc.data()['videoDes'],
          title :e.payload.doc.data()['Title'],


        
        };
      })
      // console.log(this.webinar)
    })
  }
  
  add(){
    const record={
      Title:this.title,
      videolink:this.videolink,
      videoDes:this.videoDes,

    }
    this.StallService.addlive(record)
    

  }

  
  delete(id){
    this.StallService.deleteLive(id)

  }



  addweb(){
    const record={
      Title:this.webtitle,
      videolink:this.webvideolink,
      videoDes:this.webvideoDes,

    }
    this.StallService.addwebinar(record)
    

  }

  
  deleteweb(id){
    this.StallService.deletewebinar(id)

  }

}
