<div class="container " style=" margin-top: 100px;">

    <div class="row">
        <div class="col">
            <h1 style="font-weight: 800;">Exhibitor Registration Form</h1>
        </div>

        <form (ngSubmit)="register() ; clicked = true;" ngNativeValidate>


            <div class="col-md-12">
                <label for="email" style="font-weight: 800;">Title:</label>
                <select class="browser-default custom-select" name="title" [(ngModel)]="title">
                    <option selected>MR</option>
                    <option value="1">MS</option>
                    <option value="2">MRS</option>           
                </select>
            </div>

            <div class="col-md-12 ">
                <label style="font-weight: 800;">First Name *</label>
                <input type="text" class="form-control" name="name" [(ngModel)]="name" required>
            </div>
            <!-- added -->
            <div class="col-md-12 ">
                <label style="font-weight: 800;">Last Name *</label>
                <input type="text" class="form-control" name="lnames" [(ngModel)]="lname" required>
            </div>
            <!-- added -->

            <div class="col-md-12 ">
                <label style="font-weight: 800;">Designation</label>
                <input type="text" class="form-control" name="Designation" [(ngModel)]="Designation">

            </div>
            <div class="col-md-12 ">
                <label style="font-weight: 800;">Country *</label>
                <input type="text" class="form-control" name="Country" [(ngModel)]="Country" required>
            </div>
            <div class="col-md-12 ">
                <label style="font-weight: 800;">Company name *</label>
                <input type="text" class="form-control" name="CompanyName" [(ngModel)]="CompanyName" required>
            </div>
            <div class="col-md-12 ">
                <label style="font-weight: 800;">Complete Address of the company *</label>
                <input type="text" class="form-control" name="Address" [(ngModel)]="Address" required>
            </div>


            <div class="col-md-12 ">
                <label style="font-weight: 800;">Telephone No </label>
                <input type="number" class="form-control" name="Telephone" [(ngModel)]="Telephone">
            </div>
            <div class="col-md-12 ">
                <label style="font-weight: 800;">Mobile No *</label>
                <input type="number" class="form-control" name="Mobile" [(ngModel)]="Mobile" required>
            </div>
            <div class="col-md-12">
                <label style="font-weight: 800;">Business Email ID *</label>
                <input type="email" class="form-control" name="email" [(ngModel)]="email" required>
            </div>
            <div class="col-md-12">
                <label style="font-weight: 800;">Company Website *</label>
                <input type="text" class="form-control" name="Website" [(ngModel)]="Website" required>
            </div>






            <div class="col-md-12 ">
                <label style="font-weight: 800;">Year of Establishment of your Company *</label>
                <input type="text" class="form-control" name="year" [(ngModel)]="year">
            </div>



            <div class="col-md-12 ">
                <label style="font-weight: 800;">Major Countries of Export</label>
                <input type="text" class="form-control" name="Countryofimport" [(ngModel)]="Countryofimport">
            </div>

            <div class="col-md-12">
                <label style="font-weight: 800;">Major Products of Export  </label>
                <input type="text" class="form-control" name="productofimport" [(ngModel)]="productsofimport">
            </div>


            <!-- added -->

            <div class="form-group" style="margin-left:1.5%;">
                <div class="row">

                    <div class=" col-md-12 ">
                        <label style="font-weight: 800;">Turnover of your company in USD Million</label>
                    </div>
                    <div class=" col-md-4 ">
                        <label style="font-weight: 800;">2016-2017</label>
                        <input type="text" class="form-control" name="turnover1" [(ngModel)]="turnover1617">
                    </div>
                    <div class=" col-md-4 ">
                        <label style="font-weight: 800;">2017-2018</label>
                        <input type="text" class="form-control" name="turnover2" [(ngModel)]="turnover1718">
                    </div>
                    <div class=" col-md-4 ">
                        <label style="font-weight: 800;">2018-2019</label>
                        <input type="text" class="form-control" name="turnover3" [(ngModel)]="turnover1819">
                    </div>
                </div>
            </div>



            <div class="form-group" style="margin-left:1.5%;">
                <div class="row">
                    <div class=" col-md-12 ">
                        <label style="font-weight: 800;">Details Of The Exports Of Textile Products For The Past 3 Years (In USD Million)</label>
                    </div>
                    <div class=" col-md-4 ">
                        <label style="font-weight: 800;">2016-17, Total Exports</label>
                        <input type="text" class="form-control" name="export1617" [(ngModel)]="export1617">
                    </div>

                    <div class=" col-md-4 ">
                        <label style="font-weight: 800;">2017-18, Total Exports</label>
                        <input type="text" class="form-control" name="export1718" [(ngModel)]="export1718">
                    </div>

                    <div class=" col-md-4 ">
                        <label style="font-weight: 800;"> 2018-2019, Total Exports</label>
                        <input type="text" class="form-control" name="export1819" [(ngModel)]="export1819">
                    </div>

                </div>
            </div>
            <!-- added -->




            <div class="col-md-12" style="margin-top: 30px; ">
                <div>
                    <label style="font-weight: 800; font-size: larger;">Please Specify The Products You Manufacture * </label>
                </div>




            </div>

            <div class="col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Table linen</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Tablelinen1 type="checkbox" class="custom-control-input" id="Tablelinen1">
                    <label class="custom-control-label" for="Tablelinen1">Table linen</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Tablelinen2 type="checkbox" class="custom-control-input" id="Tablelinen2">
                    <label class="custom-control-label" for="Tablelinen2">Place mats</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Tablelinen3 type="checkbox" class="custom-control-input" id="Tablelinen3">
                    <label class="custom-control-label" for="Tablelinen3">Table runners</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Tablelinen4 type="checkbox" class="custom-control-input" id="Tablelinen4">
                    <label class="custom-control-label" for="Tablelinen4">Napkins</label>
                </div>
            </div>

            <!-- Bed linen Start -->
            <div class="col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Bed linen</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen1 type="checkbox" class="custom-control-input" id="Bedlinen1">
                    <label class="custom-control-label" for="Bedlinen1">Bed spreads</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen2 type="checkbox" class="custom-control-input" id="Bedlinen2">
                    <label class="custom-control-label" for="Bedlinen2">Bed covers</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen3 type="checkbox" class="custom-control-input" id="Bedlinen3">
                    <label class="custom-control-label" for="Bedlinen3">Pillows</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen4 type="checkbox" class="custom-control-input" id="Bedlinen4">
                    <label class="custom-control-label" for="Bedlinen4">Quilts</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen5 type="checkbox" class="custom-control-input" id="Bedlinen5">
                    <label class="custom-control-label" for="Bedlinen5">Duvet covers</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen6 type="checkbox" class="custom-control-input" id="Bedlinen6">
                    <label class="custom-control-label" for="Bedlinen6">Comforters</label>
                </div>
            </div>
            <!-- Bed linen Start -->

            <!-- Bath linen Start -->
            <div class="col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Bath linen</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bathlinen1 type="checkbox" class="custom-control-input" id="Bathlinen1">
                    <label class="custom-control-label" for="Bathlinen1">Towels</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bathlinen2 type="checkbox" class="custom-control-input" id="Bathlinen2">
                    <label class="custom-control-label" for="Bathlinen2">Bath robes</label>
                </div>
            </div>
            <!-- Bath linen End -->


            <!-- Kitchen linen Start -->
            <div class="col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Kitchen linen</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen1 type="checkbox" class="custom-control-input" id="Kitchenlinen1">
                    <label class="custom-control-label" for="Kitchenlinen1">Tea towel</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen2 type="checkbox" class="custom-control-input" id="Kitchenlinen2">
                    <label class="custom-control-label" for="Kitchenlinen2">Dish towel</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen3 type="checkbox" class="custom-control-input" id="Kitchenlinen3">
                    <label class="custom-control-label" for="Kitchenlinen3">Hand towel</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen4 type="checkbox" class="custom-control-input" id="Kitchenlinen4">
                    <label class="custom-control-label" for="Kitchenlinen4">Aprons</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen5 type="checkbox" class="custom-control-input" id="Kitchenlinen5">
                    <label class="custom-control-label" for="Kitchenlinen5">Mittens</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen6 type="checkbox" class="custom-control-input" id="Kitchenlinen6">
                    <label class="custom-control-label" for="Kitchenlinen6">Tea cosy</label>
                </div>
            </div>
            <!-- Kitchen linen End -->


            <!-- Furnishing Articles Start -->
            <div class="col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Furnishing Articles</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FurnishingArticles1 type="checkbox" class="custom-control-input" id="FurnishingArticles1">
                    <label class="custom-control-label" for="FurnishingArticles1">Cushions</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FurnishingArticles2 type="checkbox" class="custom-control-input" id="FurnishingArticles2">
                    <label class="custom-control-label" for="FurnishingArticles2">Throws</label>
                </div>
                <!-- added -->

                <!-- added -->

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FurnishingArticles4 type="checkbox" class="custom-control-input" id="FurnishingArticles4">
                    <label class="custom-control-label" for="FurnishingArticles4">Bolsters</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FurnishingArticles3 type="checkbox" class="custom-control-input" id="FurnishingArticles3">
                    <label class="custom-control-label" for="FurnishingArticles3">Tapestry</label>
                </div>

            </div>
            <!-- Furnishing Articles End -->


            <!-- Curtains Start -->
            <div class="col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Curtains</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains1 type="checkbox" class="custom-control-input" id="Curtains1">
                    <label class="custom-control-label" for="Curtains1">Drapers</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains2 type="checkbox" class="custom-control-input" id="Curtains2">
                    <label class="custom-control-label" for="Curtains2">Blinds</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains3 type="checkbox" class="custom-control-input" id="Curtains3">
                    <label class="custom-control-label" for="Curtains3">Window dressings</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains4 type="checkbox" class="custom-control-input" id="Curtains4">
                    <label class="custom-control-label" for="Curtains4">Shower curtains</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains5 type="checkbox" class="custom-control-input" id="Curtains5">
                    <label class="custom-control-label" for="Curtains5">Room dividers</label>
                </div>
            </div>
            <!-- Curtains End -->

            <!-- Floor Covering Start -->
            <div class="col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Floor Covering</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FloorCovering1 type="checkbox" class="custom-control-input" id="FloorCovering1">
                    <label class="custom-control-label" for="FloorCovering1">Floor mat</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FloorCovering2 type="checkbox" class="custom-control-input" id="FloorCovering2">
                    <label class="custom-control-label" for="FloorCovering2">Bath mat</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FloorCovering3 type="checkbox" class="custom-control-input" id="FloorCovering3">
                    <label class="custom-control-label" for="FloorCovering3">Floor carpet</label>
                </div>
            </div>
            <!-- Floor Covering End -->

            <!-- Fabrics Start -->
            <div class="col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Fabrics</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics1 type="checkbox" class="custom-control-input" id="Fabrics1">
                    <label class="custom-control-label" for="Fabrics1">Lungies</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics2 type="checkbox" class="custom-control-input" id="Fabrics2">
                    <label class="custom-control-label" for="Fabrics2">Sarees</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics3 type="checkbox" class="custom-control-input" id="Fabrics3">
                    <label class="custom-control-label" for="Fabrics3">Dhoties</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics4 type="checkbox" class="custom-control-input" id="Fabrics4">
                    <label class="custom-control-label" for="Fabrics4">Sarongs</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics5 type="checkbox" class="custom-control-input" id="Fabrics5">
                    <label class="custom-control-label" for="Fabrics5">RMHK</label>
                </div>
            </div>
            <!-- Fabrics End -->

            <!-- Fashion Accessories Start -->
            <div class="col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Fashion Accessories</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories1 type="checkbox" class="custom-control-input" id="FashionAccessories1">
                    <label class="custom-control-label" for="FashionAccessories1">Shawls</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories2 type="checkbox" class="custom-control-input" id="FashionAccessories2">
                    <label class="custom-control-label" for="FashionAccessories2">Stoles</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories3 type="checkbox" class="custom-control-input" id="FashionAccessories3">
                    <label class="custom-control-label" for="FashionAccessories3">Scarves</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories4 type="checkbox" class="custom-control-input" id="FashionAccessories4">
                    <label class="custom-control-label" for="FashionAccessories4">Bags</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories5 type="checkbox" class="custom-control-input" id="FashionAccessories5">
                    <label class="custom-control-label" for="FashionAccessories5">Wall hangings</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories6 type="checkbox" class="custom-control-input" id="FashionAccessories6">
                    <label class="custom-control-label" for="FashionAccessories6">Gift</label>
                </div>
                <!-- added -->
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories7 type="checkbox" class="custom-control-input" id="FashionAccessories7">
                    <label class="custom-control-label" for="FashionAccessories7">Home Products</label>
                </div>
                <!-- added -->
            </div>
            <!-- Fashion Accessories End -->













            <div class="col">

                <div class="row">
                    <div class="col-md-12">
                        <label style="font-weight: 800;">Have you participated in any event organized by HEPC in India ? Yes / No</label>
                    </div>
                    <div class="col-md-6">
                        <div class="custom-control custom-radio" style="margin-left: 10px;">
                            <input #defaultUnchecked type="radio" class="custom-control-input" id="defaultUnchecked" name="defaultExampleRadios">
                            <label class="custom-control-label" for="defaultUnchecked">Yes</label>
                        </div>

                        <!-- Default checked -->
                        <div class="custom-control custom-radio" style="margin-left: 10px;">
                            <input type="radio" class="custom-control-input" id="defaultChecked" name="defaultExampleRadios" checked>
                            <label class="custom-control-label" for="defaultChecked">No</label>
                        </div>
                    </div>


                </div>


            </div>


            <div class="col-md-12">
                <label style="font-weight: 800;">If yes, which Year</label>
                <input type="text" class="form-control" name="IndianSupplier" [(ngModel)]="IndianSupplier">
            </div>



            <div class="row">
                <div class="col-md-12">
                    <label style="font-weight: 800;">How did you know about the event?</label>
                </div>
                <div class="row col-md-12">
                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked1 type="radio" class="custom-control-input" id="rdefaultChecked1" name="r">
                        <label class="custom-control-label" for="rdefaultChecked1">Direct Mail (invitation from HEPC)</label>
                    </div>

                    <!-- Default checked -->
                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked2 type="radio" class="custom-control-input" id="rdefaultChecked2" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked2">PR Agency</label>
                    </div>

                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked3 type="radio" class="custom-control-input" id="rdefaultChecked3" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked3">Magazine</label>
                    </div>

                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked4 type="radio" class="custom-control-input" id="rdefaultChecked4" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked4"> Embassy</label>
                    </div>

                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked5 type="radio" class="custom-control-input" id="rdefaultChecked5" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked5">Associations</label>
                    </div>

                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked6 type="radio" class="custom-control-input" id="rdefaultChecked6" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked6">Others</label>
                    </div>
                </div>


            </div>

            <div class="col text-center" style="margin-top: 50px; margin-bottom: 150px;">
                <button type="submit" class="btn btn-primary" [disabled]="clicked">Submit</button>
            </div>

        </form>




    </div>



</div>
<app-footer>
</app-footer>