import { Component, Inject, OnInit } from '@angular/core';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { StalldocComponent } from '../../stalldoc/stalldoc.component';


export interface DialogDatacp {

  save:string
  
}
@Component({
  selector: 'app-pbrifecase',
  templateUrl: './pbrifecase.component.html',
  styleUrls: ['./pbrifecase.component.scss']
})
export class PbrifecaseComponent implements OnInit {
  save: string;

  constructor(@Inject(MAT_DIALOG_DATA) public data: DialogDatacp ,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.save=this.data.save
  }

  openDialog(a,b,c,d): void {
    const dialogRef = this.dialog.open(StalldocComponent, {
      width: '900px',
      height: '700px',
      data: {stallid : a , stallname:b , pinid:c ,pinname:d}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
  onNoClick(): void {
    this.dialog.closeAll();
  }
}
