import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { AngularFirestore } from '@angular/fire/firestore';
import { ExstallpopupComponent } from 'src/app/component/exstallpopup/exstallpopup.component';
import { DomSanitizer, SafeResourceUrl, SafeUrl} from '@angular/platform-browser';

import { StallService} from 'src/app/service/stall.service'
import { LiveComponent } from 'src/app/component/live/live.component';
import { AuthService } from 'src/app/service/auth.service';

@Component({
  selector: 'app-exbody',
  templateUrl: './exbody.component.html',
  styleUrls: ['./exbody.component.scss']
})
export class ExbodyComponent implements OnInit {

  activity: any;
  Live: { id: string; videolink: any; videoDes: any; title: any; }[];
  webinar: { id: string; videolink: any; videoDes: any; title: any; }[];
  ex: { id: string; email: any; CompanyName: any; Designation: any; Address: any; Country: any; IndiasuppliersName: any; Mobile: any; Telephone: any; Website: any; importing: any; name: any; other: any; password: any; products: any; textilesValueMUS: any; title: any; type: any; }[];
  selectedex: { id: string; email: any; CompanyName: any; Designation: any; Address: any; Country: any; IndiasuppliersName: any; Mobile: any; Telephone: any; Website: any; importing: any; name: any; other: any; password: any; products: any; textilesValueMUS: any; title: any; type: any; };
  j=0;
  pin: { pinid: string; pinname: any; pinleft: any; pintop: any; pinyou: any; pinloc: string; }[];
  stall: any;
  visitor: { id: string; email: any; }[];

  constructor(public StallService:StallService,public dialog: MatDialog,public fireservices:AngularFirestore,private sanitizer: DomSanitizer,public authservice: AuthService) { }

  ngOnInit(): void {

    this.StallService.get_allex().subscribe(data => {
      this.ex = data.map(e => {
        return {
          id: e.payload.doc.id,
          email: e.payload.doc.data()['email'],
          CompanyName : e.payload.doc.data()['CompanyName'],
          Designation : e.payload.doc.data()['Designation'],
            Address  : e.payload.doc.data()['Address'],
              Country: e.payload.doc.data()['Country'],
      IndiasuppliersName:e.payload.doc.data()['IndiasuppliersName'],
            Mobile:e.payload.doc.data()['Mobile'],
            Telephone :e.payload.doc.data()['Telephone'],
            Website:e.payload.doc.data()['Website'],
          importing:e.payload.doc.data()['importing'],
              name:e.payload.doc.data()['name'],
              other:e.payload.doc.data()['other'],
            password:e.payload.doc.data()['password'],
            products:e.payload.doc.data()['products'],
      textilesValueMUS:e.payload.doc.data()['textilesValueMUS'],
  title:e.payload.doc.data()['textilesValueMUS'],
  type:e.payload.doc.data()['type'],
        };})
        console.log(this.ex)
        var name=localStorage.getItem('username');
        for (var i=0;i<this.ex.length;i++){
          if(this.ex[i]['email']==name)
          {
            this.selectedex=this.ex[i];
          }
        }
 
    this.StallService.get_stallidfromex(this.selectedex.id).subscribe(data => {
      this.stall = data.map(e => {
        return {
          id: e.payload.doc.id,
          stallid: e.payload.doc.data()['stallid'],
        

        };
      })
      // console.log(this.stall)
      for (var i=0;i<this.stall.length;i++){
        this.StallService.getallpin(this.stall[i]['stallid']).subscribe(data => {
          this.pin = data.map(e => {
            return {
              pinid: e.payload.doc.id,
              pinname: e.payload.doc.data()['name'],
              pinleft: e.payload.doc.data()['left'],
              pintop: e.payload.doc.data()['top'],
              pinyou: e.payload.doc.data()['youtube'],
              pinloc:" top:"+e.payload.doc.data()['top']+"%;  left:"+ e.payload.doc.data()['left']+"%;"
            };
          })
           
    
          this.stall[this.j].pin=(this.pin);
          this.j=this.j+1; 
          console.log(this.stall[0]['id'],"aaaaaaaaaaaaaaaaaaaaaaaaaaaaaa")

          this.StallService.get_visitor(this.stall[0]['stallid']).subscribe(data => {
            this.visitor = data.map(e => {
              return {
                id: e.payload.doc.id,
                email:e.payload.doc.data()['email'],
              
        
        
              
              };
            })
            console.log(this.visitor,"finalllll")
          })
      })}
    })
  }) 
    
    
    this.StallService.get_allactivity().subscribe(data => {
      this.activity = data.map(e => {
        return {
          id: e.payload.doc.id,
          des:e.payload.doc.data()['des'],
          etime :e.payload.doc.data()['etime'],
          stime :e.payload.doc.data()['stime'],
          title :e.payload.doc.data()['title'],
  
  
        
        };
      })
      // console.log(this.activity)
    })

    this.StallService.get_allLiveEvent().subscribe(data => {
      this.Live = data.map(e => {
        return {
          id: e.payload.doc.id,
          videolink :e.payload.doc.data()['videolink'],
          videoDes :e.payload.doc.data()['videoDes'],
          title :e.payload.doc.data()['Title'],


        
        };
      })
      console.log(this.Live)
    })

    this.StallService.get_allwebinarEvent().subscribe(data => {
      this.webinar = data.map(e => {
        return {
          id: e.payload.doc.id,
          videolink :e.payload.doc.data()['videolink'],
          videoDes :e.payload.doc.data()['videoDes'],
          title :e.payload.doc.data()['Title'],


        
        };
      })
      // console.log(this.Live)
    })


  }

  open(){
    const dialogRef = this.dialog.open(ExstallpopupComponent, {
      width: '900px',
      height: '700px',
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  photoURL(link) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(link);
  }

  openlive(a){
    // console.log(a,"ennna tha nadakattu ")

    const dialogRef = this.dialog.open(LiveComponent, {
      data :{id:a}
       
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });

  }

  // addurl(a){
  //   const url="https://www.youtube.com/embed/"+a
  //   return url
  // }

}
