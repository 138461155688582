<div *ngIf=pindetails.pinyoutube>
    <!-- <button (click)="onNoClick()" style="background:none;border:none;">
        <a  class="fa fa-window-close fa-2x iconsty" style="color: black;z-index:1000px;top:0;right:0;"> </a>
      </button> -->

    <youtube-player videoId="{{pindetails.pinpdfsrc}}"></youtube-player>


</div>

<div style="width: 100%; height: 50%;" *ngIf=!pindetails.pinyoutube>






    <button (click)="EditRecord()" style="background:none;border:none;margin-left:75%;"> 
    <a class="fa fa-save fa-2x iconsty" style="color:black;"></a>
  </button>






    <button (click)="download(pindetails.pinpdfsrc)" style="background:none;border:none;"> 
    <a class="fa fa-download fa-2x iconsty" style="color:black;"></a>
  </button>


    <button (click)="onNoClick()" style="background:none;border:none;">
    <a  class="fa fa-window-close fa-2x iconsty" style="color: black;"> </a>
  </button>



    <!-- <button (click)="onNoClick()" style="background:none;border:none;margin-left:90%">
        <a  class="fa fa-window-close fa-2x iconsty" style="color: black;"> </a>
      </button> -->
    <pdf-viewer (pagechanging)="pagechanging($event)" [(page)]="page" *ngIf=!pindetails.pinyoutube src="{{pindetails.pinpdfsrc}}" [render-text]="false" style="display: block; " [autoresize]="true" [fit-to-page]="true">
    </pdf-viewer>

</div>



<!-- <div class="col-12">
    <div class="card">
        <div class="card-header card-header-warning">
            <h1 class="card-title">Virtual Expo 2020 </h1>
            <h1>{{stallname}} </h1>


            <div class="smcolumn" style="margin-top:-30px;margin-right:-50px;">
                <button (click)="onNoClick()" style="background:none;border:none;">
            <a  class="fa fa-window-close fa-2x iconsty" style="color: black;"> </a>
          </button>

            </div>

            <div class="smcolumn" *ngIf=!pindetails.pinyoutube style="margin-top:-30px;margin-right:30px;">
              
                <button (click)="EditRecord()" style="background:none;border:none;"> 
            <a class="fa fa-save fa-2x iconsty" style="color:black;"></a>
          </button>

            </div>


            <div *ngIf=!pindetails.pinyoutube class="smcolumn" style="margin-top:-30px;margin-right:30px;">
            
                <button (click)="download(pindetails.pinpdfsrc)" style="background:none;border:none;"> 
            <a class="fa fa-download fa-2x iconsty" style="color:black;"></a>
          </button>

            </div>




        </div>


        <div class="card-body table-responsive">


            <div class="stalldocwin ">
                <div class="footer">

                    <div>


                        <div class="smcolumnlf" style="color: white;">



                        </div>




                    </div>

                </div>

            </div>

            <div>

                <div class="contentwid">
                    <h1 style="font-weight:bold;height:0px;line-height:2px;margin-top:-80px;text-transform:uppercase;">{{pindetails.pinname}}</h1>

                    <h4>
                        {{pindetails.pindes}}
                    </h4>

                </div>
                <div *ngIf=pindetails.pinyoutube>
                    <youtube-player videoId=" {{pindetails.pinpdfsrc}} "></youtube-player>
                </div>
                <iframe *ngIf=pindetails.pinyoutube width=" 100% " height="540 " src="https://www.youtube.com/embed/FFvwg5g3J_k ">
                </iframe>


                <div>
                    <pdf-viewer (pagechanging)="pagechanging($event) " [(page)]="page " *ngIf=!pindetails.pinyoutube src="{{pindetails.pinpdfsrc}} " [render-text]="false " style="display: block; " [autoresize]="true " [fit-to-page]="true "></pdf-viewer>


                </div>




                <video controls class="video " (play)="video() " autoplay #videoPlayer>
                    <source src="https://www.youtube.com/watch?v=JqMxTZ2q4Nw " type="video/mp4 ">
                    Your browser does not support the video tag.
                  </video>
            </div>

        </div>
    </div>
</div> -->