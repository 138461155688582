import { Component, OnInit } from '@angular/core';
import * as Chartist from 'chartist';
import { AuthService } from 'src/app/service/auth.service';
import { CrudService } from 'src/app/service/crud.service';
import { MatDialog } from '@angular/material/dialog';
import { StallService} from 'src/app/service/stall.service'
import { StalldocComponent } from '../../stalldoc/stalldoc.component';
import { LiveComponent } from 'src/app/component/live/live.component';

@Component({
  selector: 'app-pfdash',
  templateUrl: './pfdash.component.html',
  styleUrls: ['./pfdash.component.scss']
})
export class PfdashComponent implements OnInit {
  customer: { id: string; email: any; CompanyName: any; Designation: any; Address: any; Country: any; IndiasuppliersName: any; Mobile: any; Telephone: any; Website: any; importing: any; name: any; other: any; password: any; products: any; textilesValueMUS: any; title: any; type: any; }[];
  selectedcustomer: { id: string; email: any; CompanyName: any; Designation: any; Address: any; Country: any; IndiasuppliersName: any; Mobile: any; Telephone: any; Website: any; importing: any; name: any; other: any; password: any; products: any; textilesValueMUS: any; title: any; type: any; };
  save: { id: string; pinname: any; pinid: any; sid: any; sname: any; }[];
  activity: { id: string; des: any; etime: any; stime: any; title: any; }[];
  Live: any;
  webinar: { id: string; videolink: any; videoDes: any; title: any; }[];


  constructor(public authservice: AuthService,public crudservice:CrudService,public dialog: MatDialog,public StallService:StallService ) { }




  startAnimationForLineChart(chart){
    let seq: any, delays: any, durations: any;
    seq = 0;
    delays = 80;
    durations = 500;

    chart.on('draw', function(data) {
      if(data.type === 'line' || data.type === 'area') {
        data.element.animate({
          d: {
            begin: 600,
            dur: 700,
            from: data.path.clone().scale(1, 0).translate(0, data.chartRect.height()).stringify(),
            to: data.path.clone().stringify(),
            easing: Chartist.Svg.Easing.easeOutQuint
          }
        });
      } else if(data.type === 'point') {
            seq++;
            data.element.animate({
              opacity: {
                begin: seq * delays,
                dur: durations,
                from: 0,
                to: 1,
                easing: 'ease'
              }
            });
        }
    });

    seq = 0;
};
startAnimationForBarChart(chart){
    let seq2: any, delays2: any, durations2: any;

    seq2 = 0;
    delays2 = 80;
    durations2 = 500;
    chart.on('draw', function(data) {
      if(data.type === 'bar'){
          seq2++;
          data.element.animate({
            opacity: {
              begin: seq2 * delays2,
              dur: durations2,
              from: 0,
              to: 1,
              easing: 'ease'
            }
          });
      }
    });

    seq2 = 0;
};

ngOnInit() {
  this.StallService.get_allLiveEvent().subscribe(data => {
    this.Live = data.map(e => {
      return {
        id: e.payload.doc.id,
        videolink :e.payload.doc.data()['videolink'],
        videoDes :e.payload.doc.data()['videoDes'],
        title :e.payload.doc.data()['Title'],


      
      };
    })
    console.log(this.Live)
  })

  this.StallService.get_allwebinarEvent().subscribe(data => {
    this.webinar = data.map(e => {
      return {
        id: e.payload.doc.id,
        videolink :e.payload.doc.data()['videolink'],
        videoDes :e.payload.doc.data()['videoDes'],
        title :e.payload.doc.data()['Title'],


      
      };
    })
    // console.log(this.Live)
  })

  this.StallService.get_allactivity().subscribe(data => {
    this.activity = data.map(e => {
      return {
        id: e.payload.doc.id,
        des:e.payload.doc.data()['des'],
        etime :e.payload.doc.data()['etime'],
        stime :e.payload.doc.data()['stime'],
        title :e.payload.doc.data()['title'],


      
      };
    })
    console.log(this.activity)
  })
  
  /* ----------==========     Daily Sales Chart initialization For Documentation    ==========---------- */

  const dataDailySalesChart: any = {
      labels: ['M', 'T', 'W', 'T', 'F', 'S', 'S'],
      series: [
          [12, 17, 7, 17, 23, 18, 38]
      ]
  };

 const optionsDailySalesChart: any = {
      lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0
      }),
      low: 0,
      high: 50, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: { top: 0, right: 0, bottom: 0, left: 0},
  }

  var dailySalesChart = new Chartist.Line('#dailySalesChart', dataDailySalesChart, optionsDailySalesChart);

  this.startAnimationForLineChart(dailySalesChart);


  /* ----------==========     Completed Tasks Chart initialization    ==========---------- */

  const dataCompletedTasksChart: any = {
      labels: ['12p', '3p', '6p', '9p', '12p', '3a', '6a', '9a'],
      series: [
          [230, 750, 450, 300, 280, 240, 200, 190]
      ]
  };

 const optionsCompletedTasksChart: any = {
      lineSmooth: Chartist.Interpolation.cardinal({
          tension: 0
      }),
      low: 0,
      high: 1000, // creative tim: we recommend you to set the high sa the biggest value + something for a better look
      chartPadding: { top: 0, right: 0, bottom: 0, left: 0}
  }

  var completedTasksChart = new Chartist.Line('#completedTasksChart', dataCompletedTasksChart, optionsCompletedTasksChart);

  // start animation for the Completed Tasks Chart - Line Chart
  this.startAnimationForLineChart(completedTasksChart);



  /* ----------==========     Emails Subscription Chart initialization    ==========---------- */

  var datawebsiteViewsChart = {
    labels: ['J', 'F', 'M', 'A', 'M', 'J', 'J', 'A', 'S', 'O', 'N', 'D'],
    series: [
      [542, 443, 320, 780, 553, 453, 326, 434, 568, 610, 756, 895]

    ]
  };
  var optionswebsiteViewsChart = {
      axisX: {
          showGrid: false
      },
      low: 0,
      high: 1000,
      chartPadding: { top: 0, right: 5, bottom: 0, left: 0}
  };
  var responsiveOptions: any[] = [
    ['screen and (max-width: 640px)', {
      seriesBarDistance: 5,
      axisX: {
        labelInterpolationFnc: function (value) {
          return value[0];
        }
      }
    }]
  ];
  var websiteViewsChart = new Chartist.Bar('#websiteViewsChart', datawebsiteViewsChart, optionswebsiteViewsChart, responsiveOptions);

  //start animation for the Emails Subscription Chart
  this.startAnimationForBarChart(websiteViewsChart);
  this.crudservice.get_Allcustomer().subscribe(data => {
    this.customer = data.map(e => {
      return {
        id: e.payload.doc.id,
        email: e.payload.doc.data()['email'],
        CompanyName : e.payload.doc.data()['CompanyName'],
        Designation : e.payload.doc.data()['Designation'],
          Address  : e.payload.doc.data()['Address'],
            Country: e.payload.doc.data()['Country'],
    IndiasuppliersName:e.payload.doc.data()['IndiasuppliersName'],
          Mobile:e.payload.doc.data()['Mobile'],
          Telephone :e.payload.doc.data()['Telephone'],
          Website:e.payload.doc.data()['Website'],
        importing:e.payload.doc.data()['importing'],
            name:e.payload.doc.data()['name'],
            other:e.payload.doc.data()['other'],
          password:e.payload.doc.data()['password'],
          products:e.payload.doc.data()['products'],
    textilesValueMUS:e.payload.doc.data()['textilesValueMUS'],
title:e.payload.doc.data()['textilesValueMUS'],
type:e.payload.doc.data()['type'],
      };})
      // console.log(this.customer)
      for (var i=0;i<this.customer.length;i++){
        if(this.customer[i]['email']==this.authservice.currentUser.email)
        {
          this.selectedcustomer=this.customer[i];
        }
      }
      // console.log(this.selectedcustomer)

      this.crudservice.get_saveddoc(this.selectedcustomer.id).subscribe(data => {
        this.save = data.map(e => {
         
          return {
           
            id: e.payload.doc.id,
            pinname: e.payload.doc.data()['pname'],
            pinid: e.payload.doc.data()['pid'],
            sid: e.payload.doc.data()['sid'],
            sname: e.payload.doc.data()['sname'],
          };})
      
         console.log(this.save)
          
      })
      
    
  
})
 
}

openDialog(a,b,c,d): void {
  const dialogRef = this.dialog.open(StalldocComponent, {
    width: '900px',
    height: '700px',
    data: {stallid : a , stallname:b , pinid:c ,pinname:d}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });
}


openlive(a){
  console.log(a,"ennna tha nadakattu ")

  const dialogRef = this.dialog.open(LiveComponent, {
    data :{id:a}
     
});

dialogRef.afterClosed().subscribe(result => {
  console.log('The dialog was closed');
});

}
}
