import { Component, OnInit, Inject, ViewChild, ElementRef } from '@angular/core';
// import { MatDialog } from '@angular/material/dialog';
// import { AlertDialogComponentComponent } from 'src/app/component/alert-dialog-component/alert-dialog-component.component';
// import {MatSnackBar} from '@angular/material/snack-bar';
import { ActivatedRoute, Router } from '@angular/router'; 
import { StallService} from 'src/app/service/stall.service';
import { AuthService } from 'src/app/service/auth.service';
import { CrudService } from 'src/app/service/crud.service';

import { HomeComponent } from '../home/home.component';
import { MatDialog, MatDialogRef, MatDialogModule, MAT_DIALOG_DATA } from '@angular/material/dialog';


export interface DialogData {
  stallid : string
  stallname : string
  pinid : string
  pinname:string
  res:boolean
}


@Component({
  selector: 'app-stalldoc',
  templateUrl: './stalldoc.component.html',
  styleUrls: ['./stalldoc.component.scss']
})
export class StalldocComponent implements OnInit {

  pindetails: any;
  stallid: string;
  stallname: string;
  details: any;
 
  pinid: any;
  pin: { pinpid: string; pinname: any; pindes: any; pinleft: any; pintop: any; pinpdfsrc: any; pinloc: string; pinyoutube: any; }[];
  res: any;



  constructor(    @Inject(MAT_DIALOG_DATA) public data: DialogData,public dialog: MatDialog,private router: Router,public crudservice:CrudService, public authservice: AuthService,private actRoute: ActivatedRoute,public StallService:StallService) { 
    

 
  }

  ngOnInit(): void {  


    const tag = document.createElement('script');

    tag.src = "https://www.youtube.com/iframe_api";
    document.body.appendChild(tag);



    this.pin=[];
    this.stallname=this.data.stallname
    // this.stallid = this.actRoute.snapshot.paramMap.get('id');
    // this.stallname = this.actRoute.snapshot.paramMap.get('eid');
    // this.pinid= this.actRoute.snapshot.paramMap.get('ename');
    // console.log("aaaaaaaaaaa")
// console.log(this.data.stallid,this.data.stallname,this.data.pinid)
this.res=this.data.res
if(this.res)
{ this.StallService.getrespin(this.data.stallid).subscribe(data => {
  this.pin = data.map(e => {
    return {
      pinpid: e.payload.doc.id,
      pinname: e.payload.doc.data()['name'],
      pindes: e.payload.doc.data()['des'],
      pinleft: e.payload.doc.data()['left'],
      pintop: e.payload.doc.data()['top'],
      pinpdfsrc:e.payload.doc.data()['pdfsrc'],
      pinloc:" top:"+e.payload.doc.data()['top']+"%;  left:"+ e.payload.doc.data()['left']+"%;",
      pinyoutube:e.payload.doc.data()['youtube'],
    };
  })
//  console.log(this.pin)
 for(var i=0;i<this.pin.length;i++)
 {
   if(this.pin[i]['pinpid']==this.data.pinid){
    // console.log(this.pin[i])
    this.pindetails=(this.pin[i])
        console.log( this.pindetails,"aaaaaaaaaaaaaaaaaaaaaaaa")

   }

 }

  
});

}
else{
  this.StallService.getpin(this.data.stallid).subscribe(data => {
    this.pin = data.map(e => {
      return {
        pinpid: e.payload.doc.id,
        pinname: e.payload.doc.data()['name'],
        pindes: e.payload.doc.data()['des'],
        pinleft: e.payload.doc.data()['left'],
        pintop: e.payload.doc.data()['top'],
        pinpdfsrc:e.payload.doc.data()['pdfsrc'],
        pinloc:" top:"+e.payload.doc.data()['top']+"%;  left:"+ e.payload.doc.data()['left']+"%;",
        pinyoutube:e.payload.doc.data()['youtube'],
      };
    })
//  console.log(this.pin)
   for(var i=0;i<this.pin.length;i++)
   {
     if(this.pin[i]['pinpid']==this.data.pinid){
      // console.log(this.pin[i])
      this.pindetails=(this.pin[i])
          console.log( this.pindetails,"aaaaaaaaaaaaaaaaaaaaaaaa")

     }

   }
 
    
});
}
 



  }

  @ViewChild('videoPlayer') videoplayer: any;

  video() {
    console.log('im Play!');
    this.videoplayer?.Play();
  }

  // openAlertDialog() {
  //   const dialogRef = this.dialog.open(AlertDialogComponentComponent,{
      
  //   });

  //   dialogRef.afterClosed().subscribe(result => {
    
  //   });
  
  // }

  // openSnackBar() {
  //   this._snackBar.open("saved","close" ,{
  //     duration: 2000,
  //   });
  // }


  EditRecord()
    
  {let record = {};
  // console.log(this.authservice.currentUserName)
  // console.log(recorddata  )
  record['sid'] = this.data.stallid;
  record['pid'] = this.data.pinid;
  record['sname']=this.data.stallname;
  record['pname']=this.data.pinname;
  // record['address'] = recorddata.editaddress;
  // this.crudservice.update_employee(recorddata.id, record);
  // recorddata.isedit = false;
  console.log(record)

  if(this.authservice.currentUserName=='Guest')
  {
 
    location.replace("/login");
        
  }
  else{
  
    this.crudservice.save(record,this.authservice.currentUserName)  

  }
    

  }



  onNoClick(): void {
    this.dialog.closeAll();
  }

  download(a){
    window.open(a, "_blank");

  }
}
