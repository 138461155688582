import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StallService} from 'src/app/service/stall.service'
import { BriefpopupComponent } from '../adminvisitor/briefpopup/briefpopup.component';
import { BriefpopupsadexComponent } from './briefpopupsadex/briefpopupsadex.component';
import { CreatepopupsadexComponent } from './createpopupsadex/createpopupsadex.component';
import { ViewpopupsadexComponent } from './viewpopupsadex/viewpopupsadex.component';



@Component({
  selector: 'app-adminexhibitor',
  templateUrl: './adminexhibitor.component.html',
  styleUrls: ['./adminexhibitor.component.scss']
})
export class AdminexhibitorComponent implements OnInit {
  exhibitor: any;

  constructor(public StallService:StallService,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.StallService.get_allexhibitor().subscribe(data => {
      this.exhibitor = data.map(e => {
        return {
          id: e.payload.doc.id,
          Address: e.payload.doc.data()['Address'],
          CompanyName: e.payload.doc.data()['CompanyName'],
          title: e.payload.doc.data()['title'],
          ref: e.payload.doc.data()['ref'],
          productsofimport: e.payload.doc.data()['CompanyName'],
          product: e.payload.doc.data()['product'],
          importedvalue: e.payload.doc.data()['importedvalue'],
          intrested: e.payload.doc.data()['intrested'],
          email: e.payload.doc.data()['email'],
          name: e.payload.doc.data()['name'],
          lname: e.payload.doc.data()['lname'],
          Website: e.payload.doc.data()['Website'],
          Telephone: e.payload.doc.data()['Telephone'],
          Mobile: e.payload.doc.data()['Mobile'],
          IndianSupplier: e.payload.doc.data()['IndianSupplier'],
          Designation: e.payload.doc.data()['Designation'],
          Countryofimport: e.payload.doc.data()['Countryofimport'],
          Country: e.payload.doc.data()['Country'],
          acess: e.payload.doc.data()['acess'],
          password: e.payload.doc.data()['password'],
          year: e.payload.doc.data()['year'],

          turnover1617: e.payload.doc.data()['turnover1617'],
          turnover1718: e.payload.doc.data()['turnover1718'],
          turnover1819: e.payload.doc.data()['turnover1819'],
  

export1617: e.payload.doc.data()['export1617'],
export1718: e.payload.doc.data()['export1718'],
export1819: e.payload.doc.data()['export1819'],
       
        
        };
      })
      // console.log(this.exhibitor)
    })
  }
  updateacessforex(id,acess){
    this.StallService.updateexstatus(id,acess)

  }
  openDialog(a,b): void {
    const dialogRef = this.dialog.open(CreatepopupsadexComponent, {
      width: '900px',
      height: '700px',
      data: {email : a , id: b}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openDialogbrief(a): void {
    const dialogRef = this.dialog.open(BriefpopupsadexComponent, {
      width: '900px',
      height: '700px',
      data: { id: a}
 
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

  openDialogview(a): void {
    const dialogRef = this.dialog.open(ViewpopupsadexComponent, {
      width: '900px',
      height: '700px',
      data: { id: a}
 
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }
}
