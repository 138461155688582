import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { LiveComponent } from '../../live/live.component';


@Component({
  selector: 'app-usermgmtfooter',
  templateUrl: './usermgmtfooter.component.html',
  styleUrls: ['./usermgmtfooter.component.scss']
})
export class UsermgmtfooterComponent implements OnInit {




  constructor(public dialog: MatDialog,) { }

  ngOnInit(): void {
  }
  
  openlive(){
    // console.log(a,"ennna tha nadakattu ")

    const dialogRef = this.dialog.open(LiveComponent, {
      data :{id:"aEcnUVo0gtc"}
       
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed');
  });

  }


  }


