import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-vfxheader',
  templateUrl: './vfxheader.component.html',
  styleUrls: ['./vfxheader.component.scss']
})
export class VfxheaderComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
