<div class="container" style="background:none;height:auto;margin-top:5%;">
    <div class=" col-lg-12 col-md-6 " style="margin-left:9%; ">
        <div class="card " style='height:700px;'>
            <div class="card-header card-header-warning ">
                <h4 class="card-title ">Today Activity</h4>

                <!-- <p class="card-category ">New employees on 15th September, 2016</p> -->
            </div>


            <div class="card-body table-responsive text-center table-wrapper-scroll-y my-custom-scrollbar ">
                <div class="container " style=" margin-top: 10px;overflow:hidden; ">
                    <form (ngSubmit)="add()">
                        <div class="row">
                            <div class="form-group col-md-12 ">


                                <div class="input-group ">

                                </div>


                                <input type="text " class="form-control " placeholder="Enter your Title " name="title" [(ngModel)]="title">
                            </div>

                            <div class="form-group col-md-12 ">


                                <div class="input-group ">


                                    <i class=" glyphicon glyphicon-blackboard " style="font-size:24px;margin-right:10%; "></i>
                                </div>


                                <input type="time" class="form-control " name="stime" [(ngModel)]="stime">
                            </div>
                            <div class="form-group col-md-12 ">


                                <div class="input-group ">


                                    <i class=" glyphicon glyphicon-blackboard " style="font-size:24px;margin-right:10%; "></i>
                                </div>


                                <input type="time" class="form-control " name="etime" [(ngModel)]="etime">
                            </div>


                            <div class="form-group col-md-12 ">

                                <input type="text " class="form-control " placeholder="Enter your Description " name="des" [(ngModel)]="des">
                            </div>
                            <div class=" col-md-3 " style="margin-left:38%;margin-top:1%; ">
                                <button class="button button2 ">Add</button>
                                <!-- 
                            <button class="button button2 " style="margin-left:10px; ">Delete</button> -->
                            </div>






                        </div>
                    </form>

                </div>


                <br><br>

                <h2>Current Live Active List</h2>
                <table class="table table-hover ">
                    <thead class="text-warning ">

                        <th>Title</th>
                        <th>Time</th>
                        <th>Description</th>
                        <!-- <th>Edit</th> -->
                        <th>Delete</th>


                    </thead>
                    <tbody *ngFor="let user of activity;">
                        <tr>

                            <td style="text-align:left;">{{user.title}}</td>
                            <td>{{user.stime}} - {{user.etime}}</td>

                            <td>{{user.des}}</td>
                            <!-- 
                            <td><i class="fa fa-pencil-square-o fa-2x "></i></td> -->
                            <td><i class="fa fa-trash fa-2x " (click)="delete(user.id)"></i></td>
                        </tr>


                    </tbody>
                </table>
            </div>
        </div>
    </div>
</div>