import { Component, OnInit } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';
import { StallService} from 'src/app/service/stall.service'

@Component({
  selector: 'app-exproductvideoup',
  templateUrl: './exproductvideoup.component.html',
  styleUrls: ['./exproductvideoup.component.scss']
})
export class ExproductvideoupComponent implements OnInit {
  stallnumber: any;
  pname: any;
  pdes: any;
  product: { name: any; des: any; youtube: boolean; };
  clicked: boolean;
 

  constructor(public fireservices:AngularFirestore,public StallService:StallService) { }

  ngOnInit(): void {
    this.clicked=false
  }

  check(){
    if(this.stallnumber){ //  console.log(this.exname,this.exnumber)
      this.fireservices.collection('stall/').doc(this.stallnumber).get().toPromise().then(function(doc){
       if (doc.exists) {
        
        document.getElementById("upload").style.display = "block";
        //  document.getElementById("already").style.display = "block";
         document.getElementById("checkdetails").style.display = "none";
           // console.log("Document data:", doc.data());
           
       } else {
       
   window.alert("wrong stall id")
          //  document.getElementById("checkdetails").style.display = "none";
           // doc.data() will be undefined in this case
          
       }
       
   }).catch(function(error) {
     window.alert(error);
   });}
   else{
    window.alert("enter correct details");
   }
 
  }


  upload(){

    this.product={
      name: this.pname,
      des:this.pdes,
      youtube:true

    }

       this.StallService.uploadproduct(this.product,this.stallnumber).then(
   
        
       )

       window.alert("product uploaded")


  }

}
