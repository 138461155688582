import { Injectable } from '@angular/core';
import {AngularFirestore} from '@angular/fire/firestore';
import { RaceSubscriber } from 'rxjs/internal/observable/race';

@Injectable({
  providedIn: 'root'
})
export class CrudService {

  employee: any;
  cus: { id: string; }[];
  custdocid: any;
  constructor(public fireservices:AngularFirestore) { }

  // create_Newemployee(Record)
  // {
  //   return this.fireservices.collection('Employee').add(Record);
  // }

  get_Allcustomer()
  {
    return this.fireservices.collection('vrcustomer').snapshotChanges();
    
  }

  get_saveddoc(custid)
  {
    return this.fireservices.collection('vrcustomer/'+custid+'/save/').snapshotChanges();
    
  }

  // get_Allstall(stallid)
  // {
  //   return this.fireservices.collection('Employee/'+stallid+'/stall').snapshotChanges();
  // }
  
//   savestall(stallid,eventid,email){

//    let saved={}
//    saved['stallid']=stallid
//    saved['eventid']=eventid
//     var flag=1
//     this.fireservices.collection('/vrcustomer/').snapshotChanges().subscribe(data => {

//       this.cus = data.map(e => {
//         return {
//           id: e.payload.doc.id,
//             email: e.payload.doc.data()['email'],
//           // isedit: false,
//           // name: e.payload.doc.data()['name'],
//           // age: e.payload.doc.data()['id'],
//           // address: e.payload.doc.data()['address'],
//         };
//       })
//       for (var i=0;i<this.cus.length;i++)
//       if(this.cus[i]['email']==email)
//       {
//           //  console.log(this.cus[i]["id"])
//         this.custdocid=this.cus[i]["id"]
       
//       }
//       this.fireservices.collection('/vrcustomer/'+this.custdocid+'/save/').snapshotChanges().subscribe(data => {

//         this.employee = data.map(e => {
//           return {
//             id: e.payload.doc.id,
//             stallid: e.payload.doc.data()['stallid'],
//             eventid: e.payload.doc.data()['eventid'],
//           };
//         })
//             //  console.log(this.employee)
//                   for (var i=0;i<this.employee.length;i++)
//                   if(this.employee[i]['stallid']==stallid)
//                   {
//                     flag=0;
//                   }
//                      if(flag==0){
//           console.log("already save")
//         }
//         else{
//           this.fireservices.collection('/vrcustomer/'+this.custdocid+'/save/').add(saved)
  
//           console.log("save")
//         }
  
      
//       }
//         );

//     })


//       // console.log(this.employee)
//       // for (var i=0;i<this.employee.length;i++)
//       //  if(this.employee[i]['age']==stallid)
//       //  {
//       //    flag=0;
//       //  }
      
//       // if(flag==0){
//       //   console.log("already there")
//       // }
//       // else{
//         // this.fireservices.collection('/customer/auMgSdZuAKCB0nl4eOsv/save/').add(saved)

//       //   console.log("create")
//       // }
//     // }
    
 

//     // if (this.employee.ag==record.id)
//     // {
//     //   console.log("already exist")

//     // }
//     // else{
//     //   this.fireservices.collection('/customer/auMgSdZuAKCB0nl4eOsv/view/').add(record)
//     //   console.log("created")
//     // }
// }
  
  // update_employee(recordid, record)
  // {
  //   this.fireservices.doc('customer/' + recordid).update(record);
  // }

  // delete_employee(record_id)
  // {
  //   this.fireservices.doc('customer/' + record_id).delete();

  // }

  save(record,email){
    console.log(record)
    var flag=1
    this.fireservices.collection('/vrcustomer/').snapshotChanges().subscribe(data => {

      this.cus = data.map(e => {
        return {
          id: e.payload.doc.id,
            email: e.payload.doc.data()['email'],
        };
      })
  
      for (var i=0;i<this.cus.length;i++)
      if(this.cus[i]['email']==email)
      {
        this.custdocid=this.cus[i]["id"]
      }
    
      this.fireservices.collection('/vrcustomer/'+this.custdocid+'/save/').snapshotChanges().subscribe(data => {
        this.employee = data.map(e => {
          return {
            id: e.payload.doc.id,
            pinid: e.payload.doc.data()['pid'],
        
          };
        })
      
      
        for (var i=0;i<this.employee.length;i++)
        {
          if(this.employee[i]['pinid']==record.pid)
         {
           flag=0;
         }

        }
         
        
        if(flag==0){
          alert("already saved")
        }
        else{
                this.fireservices.collection('/vrcustomer/'+this.custdocid+'/save').add(record)
          alert("saved")
        } 
      }); })
  }


}
