<div class="footer " *ngFor="let item of stall">
    <div class="smpd" *ngIf=" item.id==id ">
        <!--<div class="smcolumn">
            <a href="#" class="fa fa-video-camera fa-2x iconsty"></a>
        </div>
        <div class="smcolumn">
            <a href="" class="fa fa-skype fa-2x iconsty"></a>
        </div> -->
        <div class="smcolumn">
            <a href="{{item.whatsapp}}" class="fa fa-whatsapp fa-2x iconsty"></a>
        </div>
        <div class="smcolumn">
            <a href="{{item.linkedin}}" class="fa fa-linkedin-square fa-2x iconsty"></a>
        </div>
        <div class="smcolumn">
            <a href="{{item.youtube}}" class="fa fa-twitter-square fa-2x iconsty"></a>
        </div>
        <div class="smcolumn">
            <a href="{{item.youtube}}" class="fa fa-youtube-play fa-2x iconsty"></a>
        </div>
        <div class="smcolumn">
            <a href="{{item.facebook}}" class="fa fa-facebook-official fa-2x iconsty"></a>
        </div>



        <!-- <div class="smcolumn">
            <a href="" class="fa fa-instagram fa-2x iconsty"></a>
    </div> -->


    </div>
</div>