import { Component, Inject, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudService } from 'src/app/service/crud.service';
export interface DialogData {
  id : string
}



@Component({
  selector: 'app-viewpopup',
  templateUrl: './viewpopup.component.html',
  styleUrls: ['./viewpopup.component.scss']
})
export class ViewpopupComponent implements OnInit {
  save: any;
  title: string;
  Name: any;
  Designation: any;
  Companyname: any;
  Address: any;
  Country: any;
  Telephone: any;
  Mobile: any;
  Email: any;
  Website: any;
  ref: any;
  importedvalue: any;
  IndianSupplier: any;
  productsofimport: any;
  countryexport: any;
  intrested: any;
  // product: any;
  form:any;
  lname:any;
  year:any;
  otherspecify:any;
  type:any;

  
Tablelinen:any;
 
Bedlinen:any;

Bathlinen:any;

Kitchenlinen:any
FurnishingArticles:any;
Curtains:any;
FloorCovering:any;
Fabrics:any;
FashionAccessories:any;


  turnover1:any;
  turnover2:any;
  turnover3:any;

  otherspecify1:any;
  otherspecify2:any;
  otherspecify3:any;
  otherspecify4:any;
  otherspecify5:any;
  otherspecify6:any;



  faxx:any;
   volumestitle:any;
 
 volumes:any;
 buyername1:any;
 buyercountry1:any;
 buyerproduct1:any;

 buyername2:any;
 buyercountry2:any;
 buyerproduct2:any;

 buyername3:any;
 buyercountry3:any;
 buyerproduct3:any;
 IndianSupplier1:any;
 IndianSupplier2:any;
 IndianSupplier3:any;

tex1:any;
tex2:any;
tex3:any;

requirement:any;


buspart:any;
bustitle:any;
retail:any;
volumetitle1:any;
volumetitle2:any;
volumetitle3:any;
brand:any;

 inr1:any;
 inr2:any;
 inr3:any;
 specify:any;
option:any;
represent:any;
manufacture:any;
// ref:any;





  constructor(public crudservice:CrudService,@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
//     console.log("popup")
// console.log(this.data.id)

this.title=(this.data.id['title'])
this.Name=(this.data.id['name'])
this.Designation=(this.data.id['Designation'])
this.Companyname=(this.data.id['CompanyName'])
this.Address=(this.data.id['Address'])
this.Country=(this.data.id['Country'])
this.Telephone=(this.data.id['Telephone'])
this.Mobile=(this.data.id['Mobile'])
this.Email=(this.data.id['email'])
this.Website=(this.data.id['Website'])
this.ref=(this.data.id['ref'])
this.importedvalue=(this.data.id['importedvalue'])
this.IndianSupplier=(this.data.id['IndianSupplier'])
this.productsofimport=(this.data.id['productsofimport'])
this.countryexport=(this.data.id['countryexport'])
this.intrested=(this.data.id['intrested'])
// this.product=(this.data.id['product'])
this.form=(this.data.id['form'])
this.lname=(this.data.id['lname'])
this.year=(this.data.id['year'])
this.option=(this.data.id['option'])
this.represent=(this.data.id['represent'])

this.Tablelinen=(this.data.id['Tablelinen'])
this.Bedlinen=(this.data.id['Bedlinen'])
this.Bathlinen=(this.data.id['Bathlinen'])
this.Kitchenlinen=(this.data.id['Kitchenlinen'])
this.FurnishingArticles=(this.data.id['FurnishingArticles'])
this.Curtains=(this.data.id['Curtains'])
this.FloorCovering=(this.data.id['FloorCovering'])
this.Fabrics=(this.data.id['Fabrics'])
this.FashionAccessories=(this.data.id['FashionAccessories'])



this.otherspecify=(this.data.id['otherspecify'])
this.type=(this.data.id['type'])
this.turnover1=(this.data.id['turnover1'])
this.turnover2=(this.data.id['turnover2'])
this.turnover3=(this.data.id['turnover3'])
this.otherspecify1=(this.data.id['otherspecify1'])
this.otherspecify2=(this.data.id['otherspecify2'])
this.otherspecify3=(this.data.id['otherspecify3'])
this.otherspecify4=(this.data.id['otherspecify4'])
this.otherspecify5=(this.data.id['otherspecify5'])
this.otherspecify6=(this.data.id['otherspecify6'])

this.IndianSupplier=(this.data.id['IndianSupplier'])

this.faxx=(this.data.id['faxx'])

  this.volumes=(this.data.id['volumes'])
  

 this.volumestitle=(this.data.id['volumestitle'])
 this.buyername1=(this.data.id['buyername1'])
 this.buyercountry1=(this.data.id['buyercountry1'])
 this.buyerproduct1=(this.data.id['buyerproduct1'])




 this.buyername2=(this.data.id['buyername3'])
 this.buyercountry2=(this.data.id['buyercountry3'])
 this.buyerproduct2=(this.data.id['buyerproduct3'])


 this.buyername3=(this.data.id['buyername3'])
 this.buyercountry3=(this.data.id['buyercountry3'])
 this.buyerproduct3=(this.data.id['buyerproduct3'])

 this.IndianSupplier1=(this.data.id['IndianSupplier1'])
 this.IndianSupplier2=(this.data.id['IndianSupplier2'])
 this.IndianSupplier3=(this.data.id['IndianSupplier3'])


 
 this.tex1=(this.data.id['tex1'])
 this.tex2=(this.data.id['tex2'])
 this.tex3=(this.data.id['tex3'])


 
 
 this.requirement=(this.data.id['requirement'])

 

 this.buspart=(this.data.id['buspart'])
 this.bustitle=(this.data.id['bustitle'])
 this.retail=(this.data.id['retail'])
 this.volumetitle1=(this.data.id['volumetitle1'])
 this.volumetitle2=(this.data.id['volumetitle2'])
 this.volumetitle3=(this.data.id['volumetitle3'])

 this.inr1=(this.data.id['inr1'])
 this.inr2=(this.data.id['inr2'])
 this.inr3=(this.data.id['inr3'])
 this.brand=(this.data.id['brand'])
 this.specify=(this.data.id['specify'])

 this.manufacture=(this.data.id['manufacture'])
// this.IndianSupplier=(this.data.id['IndianSupplier'])
// this.IndianSupplier=(this.data.id['IndianSupplier'])
// this.IndianSupplier=(this.data.id['IndianSupplier'])



  }

}
