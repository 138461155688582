import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-visitorregform',
  templateUrl: './visitorregform.component.html',
  styleUrls: ['./visitorregform.component.scss']
})
export class VisitorregformComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
