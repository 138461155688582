<!-- <app-exheader>
</app-exheader> -->



<div class="container " style=" margin-top: 100px;">

    <div class="row">
        <div class="form-group col-12">
            <h1 style="font-weight: 800;">GENERAL INFORMATION OF THE DOMESTIC TRADE VISITOR</h1>

        </div>
        <form (ngSubmit)="register()" ngNativeValidate>

            <div class="form-group col-md-12">
                <div class="row">
                    <div class=" col-md-12">
                        <label for="email" style="font-weight: 800;">Title:</label>
                        <select class="browser-default custom-select" name="title" [(ngModel)]="title">
                    <option selected>MR</option>
                    <option value="1">MS</option>
                    <option value="2">MRS</option>           
                </select>
                    </div>
                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;">First Name</label>
                        <input type="text" class="form-control" name="name" [(ngModel)]="name">
                    </div>
                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;">Last Name</label>
                        <input type="text" class="form-control" name="lname" [(ngModel)]="lname">
                    </div>


                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;">Designation</label>
                        <input type="text" class="form-control" name="Designation" [(ngModel)]="Designation">

                    </div>
                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;">Country</label>
                        <input type="text" class="form-control" name="Country" [(ngModel)]="Country">
                    </div>
                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;">Company name</label>
                        <input type="text" class="form-control" name="CompanyName" [(ngModel)]="CompanyName">
                    </div>
                    <div class="form-group col-md-12 ">
                        <label style="font-weight: 800;margin-top: 10px;"> Address </label>
                        <input type="text" class="form-control" name="Address" [(ngModel)]="Address">
                    </div>


                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;">Tel</label>
                        <input type="number" class="form-control" name="Telephone" [(ngModel)]="Telephone">
                    </div>
                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;"> Fax </label>
                        <input type="text" class="form-control" name="fax" [(ngModel)]="fax">
                    </div>
                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;">Mobile </label>
                        <input type="number" class="form-control" name="Mobile" [(ngModel)]="Mobile">
                    </div>
                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;"> Email </label>
                        <input type="email" class="form-control" name="email" [(ngModel)]="email">
                    </div>

                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;"> Website</label>
                        <input type="text" class="form-control" name="Website" [(ngModel)]="Website">
                    </div>
                    <div class=" col-md-12">
                        <label style="font-weight: 800;margin-top: 10px;">Year of Establishment of the Company * </label>
                        <input type="text" class="form-control" name="year" placeholder="website" [(ngModel)]="year" required>
                    </div>




                </div>
            </div>

            <div class="form-group col-md-12">
                <label style="font-weight: 800;font-size: larger;">Business Particulars</label>
                <div class="row">
                    <div class="col-md-4">
                        <label style="font-weight: 800;">Area of Business ( Tick Appropriate )</label>
                        <select class="browser-default custom-select" name="buspart" [(ngModel)]="buspart">
                            <option selected value="Domestic">Domestic</option>
                            <option value="Export">Export</option>
                            <option value="Both">Both</option>           
                        </select>
                    </div>

                    <div class="col-md-4">
                        <label style="font-weight: 800;">If Domestic Business</label>
                        <select class="browser-default custom-select" name="bustitle" [(ngModel)]="bustitle">
                                <option selected value="Retail Stores">Retail Stores</option>
                                <option value="Boutique">Boutique</option>
                                <option value="Institutional Buyers">Institutional Buyers</option>           
                                <option value="Others">Others</option>    
                            </select>
                    </div>

                    <div class="col-md-4">
                        <label style="font-weight: 800;">No. of retail stores in India * </label>
                        <input type="text" class="form-control" name="retail" [(ngModel)]="retail" placeholder="No. of retail stores in India " required>
                    </div>


                </div>
            </div>




            <div class="form-group col-md-12">

                <div class="row">


                    <div class="col-md-6">
                        <label style="font-weight: 800;">If Others, Please specify </label>
                        <input type="text" class="form-control" name="specify" [(ngModel)]="specify" placeholder="If Others, Please specify ">
                    </div>

                    <div class="col-md-6">
                        <label style="font-weight: 800;">If Domestic business, Name of the Brand </label>
                        <input type="text" class="form-control" name="brand" [(ngModel)]="brand" placeholder="If Domestic business, Name of the Brand ">
                    </div>


                </div>
            </div>



            <div class="form-group col-md-12">

                <div class="row">


                    <div class="col-md-6">
                        <label style="font-weight: 800;">Volume of business per year *</label>
                        <select class="browser-default custom-select" name="volumetitle1" [(ngModel)]="volumetitle1" required>
                                <option selected>Select Year</option>
                                <option value="2017">2017</option>
                                <option value="2018">2018</option>           
                                <option value="2019">2019</option>    
                            </select>
                    </div>

                    <div class="col-md-6">
                        <label style="font-weight: 800;">INR *</label>
                        <input type="text" class="form-control" name="inr1" placeholder="INR" [(ngModel)]="inr1" required>
                    </div>


                </div>
            </div>




            <div class="form-group col-md-12">

                <div class="row">


                    <div class="col-md-6">
                        <label style="font-weight: 800;">Volume of business per year *</label>
                        <select class="browser-default custom-select" name="volumetitle2" [(ngModel)]="volumetitle2" required>
                                <option selected>Select Year</option>
                                <option value="2017">2017</option>
                                <option value="2018">2018</option>           
                                <option value="2019">2019</option>    
                            </select>
                    </div>

                    <div class="col-md-6">
                        <label style="font-weight: 800;">INR *</label>
                        <input type="text" class="form-control" name="inr2" placeholder="INR" [(ngModel)]="inr2" required>
                    </div>


                </div>
            </div>



            <div class="form-group col-md-12">

                <div class="row">


                    <div class="col-md-6">
                        <label style="font-weight: 800;">Volume of business per year *</label>
                        <select class="browser-default custom-select" name="volumetitle3" [(ngModel)]="volumetitle3" required>
                                <option selected>Select Year</option>
                                <option value="2017">2017</option>
                                <option value="2018">2018</option>           
                                <option value="2019">2019</option>    
                            </select>
                    </div>

                    <div class="col-md-6">
                        <label style="font-weight: 800;">INR *</label>
                        <input type="text" class="form-control" name="inr3" placeholder="INR" [(ngModel)]="inr3" required>
                    </div>


                </div>
            </div>








            <div class="form-group col-md-12" style="margin-top: 30px; ">
                <div>
                    <label style="font-weight: 800; font-size: larger;">Products Interested In</label>
                </div>
            </div>

            <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Table linen</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Tablelinen1 type="checkbox" class="custom-control-input" id="Tablelinen1">
                    <label class="custom-control-label" for="Tablelinen1">Table linen</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Tablelinen2 type="checkbox" class="custom-control-input" id="Tablelinen2">
                    <label class="custom-control-label" for="Tablelinen2">Place mats</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Tablelinen3 type="checkbox" class="custom-control-input" id="Tablelinen3">
                    <label class="custom-control-label" for="Tablelinen3">Table runners</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Tablelinen4 type="checkbox" class="custom-control-input" id="Tablelinen4">
                    <label class="custom-control-label" for="Tablelinen4">Napkins</label>
                </div>
            </div>

            <!-- Bed linen Start -->
            <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Bed linen</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen1 type="checkbox" class="custom-control-input" id="Bedlinen1">
                    <label class="custom-control-label" for="Bedlinen1">Bed spreads</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen2 type="checkbox" class="custom-control-input" id="Bedlinen2">
                    <label class="custom-control-label" for="Bedlinen2">Bed covers</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen3 type="checkbox" class="custom-control-input" id="Bedlinen3">
                    <label class="custom-control-label" for="Bedlinen3">Pillows</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen4 type="checkbox" class="custom-control-input" id="Bedlinen4">
                    <label class="custom-control-label" for="Bedlinen4">Quilts</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen5 type="checkbox" class="custom-control-input" id="Bedlinen5">
                    <label class="custom-control-label" for="Bedlinen5">Duvet covers</label>
                </div>

                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bedlinen6 type="checkbox" class="custom-control-input" id="Bedlinen6">
                    <label class="custom-control-label" for="Bedlinen6">Comforters</label>
                </div>
            </div>
            <!-- Bed linen Start -->

            <!-- Bath linen Start -->
            <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Bath linen</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bathlinen1 type="checkbox" class="custom-control-input" id="Bathlinen1">
                    <label class="custom-control-label" for="Bathlinen1">Towels</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Bathlinen2 type="checkbox" class="custom-control-input" id="Bathlinen2">
                    <label class="custom-control-label" for="Bathlinen2">Bath robes</label>
                </div>


            </div>
            <!-- Bath linen End -->


            <!-- Kitchen linen Start -->
            <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Kitchen linen</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen1 type="checkbox" class="custom-control-input" id="Kitchenlinen1">
                    <label class="custom-control-label" for="Kitchenlinen1">Tea towels</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen2 type="checkbox" class="custom-control-input" id="Kitchenlinen2">
                    <label class="custom-control-label" for="Kitchenlinen2">Dish towel</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen3 type="checkbox" class="custom-control-input" id="Kitchenlinen3">
                    <label class="custom-control-label" for="Kitchenlinen3">Hand towel</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen4 type="checkbox" class="custom-control-input" id="Kitchenlinen4">
                    <label class="custom-control-label" for="Kitchenlinen4">Aprons</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen5 type="checkbox" class="custom-control-input" id="Kitchenlinen5">
                    <label class="custom-control-label" for="Kitchenlinen5">Mittens</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Kitchenlinen6 type="checkbox" class="custom-control-input" id="Kitchenlinen6">
                    <label class="custom-control-label" for="Kitchenlinen6">Tea cosy</label>
                </div>
            </div>
            <!-- Kitchen linen End -->


            <!-- Furnishing Articles Start -->
            <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Furnishing Articles</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FurnishingArticles1 type="checkbox" class="custom-control-input" id="FurnishingArticles1">
                    <label class="custom-control-label" for="FurnishingArticles1">Cushions</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FurnishingArticles2 type="checkbox" class="custom-control-input" id="FurnishingArticles2">
                    <label class="custom-control-label" for="FurnishingArticles2">Throws</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FurnishingArticles4 type="checkbox" class="custom-control-input" id="FurnishingArticles4">
                    <label class="custom-control-label" for="FurnishingArticles4">Bolsters</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FurnishingArticles3 type="checkbox" class="custom-control-input" id="FurnishingArticles3">
                    <label class="custom-control-label" for="FurnishingArticles3">Tapestry</label>
                </div>


            </div>
            <!-- Furnishing Articles End -->


            <!-- Curtains Start -->
            <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Curtains</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains1 type="checkbox" class="custom-control-input" id="Curtains1">
                    <label class="custom-control-label" for="Curtains1">Drapers</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains2 type="checkbox" class="custom-control-input" id="Curtains2">
                    <label class="custom-control-label" for="Curtains2">Blinds</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains3 type="checkbox" class="custom-control-input" id="Curtains3">
                    <label class="custom-control-label" for="Curtains3">Window dressings</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains4 type="checkbox" class="custom-control-input" id="Curtains4">
                    <label class="custom-control-label" for="Curtains4">Shower curtains</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Curtains5 type="checkbox" class="custom-control-input" id="Curtains5">
                    <label class="custom-control-label" for="Curtains5">Room dividers</label>
                </div>
            </div>
            <!-- Curtains End -->

            <!-- Floor Covering Start -->
            <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Floor Covering</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FloorCovering1 type="checkbox" class="custom-control-input" id="FloorCovering1">
                    <label class="custom-control-label" for="FloorCovering1">Floor mat</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FloorCovering2 type="checkbox" class="custom-control-input" id="FloorCovering2">
                    <label class="custom-control-label" for="FloorCovering2">Bath mat</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FloorCovering3 type="checkbox" class="custom-control-input" id="FloorCovering3">
                    <label class="custom-control-label" for="FloorCovering3">Floor carpet</label>
                </div>
            </div>
            <!-- Floor Covering End -->

            <!-- Fabrics Start -->
            <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Fabrics</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics1 type="checkbox" class="custom-control-input" id="Fabrics1">
                    <label class="custom-control-label" for="Fabrics1">Lungies</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics2 type="checkbox" class="custom-control-input" id="Fabrics2">
                    <label class="custom-control-label" for="Fabrics2">Sarees</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics3 type="checkbox" class="custom-control-input" id="Fabrics3">
                    <label class="custom-control-label" for="Fabrics3">Dhoties</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics4 type="checkbox" class="custom-control-input" id="Fabrics4">
                    <label class="custom-control-label" for="Fabrics4">Sarongs</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #Fabrics5 type="checkbox" class="custom-control-input" id="Fabrics5">
                    <label class="custom-control-label" for="Fabrics5">RMHK</label>
                </div>
            </div>
            <!-- Fabrics End -->

            <!-- Fashion Accessories Start -->
            <div class="form-group col-md-12" style="margin-top: 10px; margin-bottom: 30px;">
                <div>
                    <label style="font-weight: 800;">Fashion Accessories</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories1 type="checkbox" class="custom-control-input" id="FashionAccessories1">
                    <label class="custom-control-label" for="FashionAccessories1">Shawls</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories2 type="checkbox" class="custom-control-input" id="FashionAccessories2">
                    <label class="custom-control-label" for="FashionAccessories2">Stoles</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories3 type="checkbox" class="custom-control-input" id="FashionAccessories3">
                    <label class="custom-control-label" for="FashionAccessories3">Scarves</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories4 type="checkbox" class="custom-control-input" id="FashionAccessories4">
                    <label class="custom-control-label" for="FashionAccessories4">Bags</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories5 type="checkbox" class="custom-control-input" id="FashionAccessories5">
                    <label class="custom-control-label" for="FashionAccessories5">Wall hangings</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories6 type="checkbox" class="custom-control-input" id="FashionAccessories6">
                    <label class="custom-control-label" for="FashionAccessories6">Gift</label>
                </div>
                <div class="custom-control custom-checkbox custom-control-inline">
                    <input #FashionAccessories7 type="checkbox" class="custom-control-input" id="FashionAccessories7">
                    <label class="custom-control-label" for="FashionAccessories7">Home products</label>
                </div>
            </div>
            <!-- Fashion Accessories End -->



            <!-- start -->

            <div class="form-group col">

                <label style="font-weight: 800;font-size: larger;">Trimmings & Embellishments
                    Others (Please Specify) :</label>
                <div class="row">
                    <div class="col-md-12">
                        <label style="font-weight: 800;">Are you currently sourcing textile products from Indian manufacturers? Yes / No
                        </label>
                    </div>
                    <div class="col-md-6">
                        <div class="custom-control custom-radio" style="margin-left: 10px;">
                            <input #rdefaultChecked11 type="radio" class="custom-control-input" id="rdefaultChecked11" name="a" checked>
                            <label class="custom-control-label" for="rdefaultChecked11">Yes</label>
                        </div>

                        <div class="custom-control custom-radio" style="margin-left: 10px;">
                            <input #rdefaultChecked12 type="radio" class="custom-control-input" id="rdefaultChecked12" name="a" checked>
                            <label class="custom-control-label" for="rdefaultChecked12">No</label>
                        </div>
                    </div>
                </div>


            </div>




            <div class="form-group col-md-12">
                <label style="font-weight: 800;">If So, Please Mention The Names your Top Of 3 Indian Suppliers:</label>
                <div class="row">


                    <div class="col-md-4">
                        <input type="text" class="form-control" name="IndianSupplier1" [(ngModel)]="IndianSupplier1">
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" name="IndianSupplier2" [(ngModel)]="IndianSupplier2">
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" name="IndianSupplier3" [(ngModel)]="IndianSupplier3">
                    </div>
                </div>
            </div>
            <div class="form-group col-md-12">
                <label style="font-weight: 800;">Value of Home Textile / clothing accessories sourced ( In Rupees) :
                </label>
                <div class="row">


                    <div class="col-md-4">
                        <input type="text" class="form-control" name="tex1" [(ngModel)]="tex1">
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" name="tex2" [(ngModel)]="tex2">
                    </div>
                    <div class="col-md-4">
                        <input type="text" class="form-control" name="tex3" [(ngModel)]="tex3">
                    </div>
                </div>
            </div>






            <div class="row" style="margin-left:1%;">
                <div class="col-md-12">
                    <label style="font-weight: 800;">Have you participated in any event organized by HEPC in India ? Yes / No</label>
                </div>

                <div class="col-md-6">
                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #defaultUnchecked1 type="radio" class="custom-control-input" id="defaultUnchecked1" name="defaultExampleRadios" checked>
                        <label class="custom-control-label" for="defaultUnchecked1  ">Yes</label>
                    </div>
                    <!-- Default checked -->
                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #defaultUnchecked2 type="radio" class="custom-control-input" id="defaultUnchecked2" name="defaultExampleRadios" checked>
                        <label class="custom-control-label" for="defaultUnchecked2">No</label>
                    </div>
                </div>
            </div>


            <div class="form-group col-md-12">
                <label style="font-weight: 800;">If Yes, Which Year: </label>
                <input type="text" class="form-control" name="IndianSupplier" [(ngModel)]="IndianSupplier">
            </div>



            <div class="row" style="margin-left:1%;">
                <div class="col-md-12">
                    <label style="font-weight: 800;">How did you know about the event?</label>
                </div>
                <div class="row col-md-12">
                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked1 type="radio" class="custom-control-input" id="rdefaultChecked1" name="r">
                        <label class="custom-control-label" for="rdefaultChecked1">Direct Mail (invitation from HEPC)</label>
                    </div>

                    <!-- Default checked -->
                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked2 type="radio" class="custom-control-input" id="rdefaultChecked2" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked2">PR Agency</label>
                    </div>

                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked3 type="radio" class="custom-control-input" id="rdefaultChecked3" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked3">Magazine</label>
                    </div>

                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked4 type="radio" class="custom-control-input" id="rdefaultChecked4" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked4"> Embassy</label>
                    </div>

                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked5 type="radio" class="custom-control-input" id="rdefaultChecked5" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked5">Associations</label>
                    </div>

                    <div class="custom-control custom-radio" style="margin-left: 10px;">
                        <input #rdefaultChecked6 type="radio" class="custom-control-input" id="rdefaultChecked6" name="r" checked>
                        <label class="custom-control-label" for="rdefaultChecked6">Others</label>
                    </div>
                </div>


            </div>


            <div class="form-group col-md-12">
                <label style="font-weight: 800;">Any other specific interest/requirement:</label>
                <input type="text" class="form-control" name="requirememt" [(ngModel)]="requirement">
            </div>


            <div class="col text-center" style="margin-top: 50px; margin-bottom: 150px;">
                <button type="submit" class="btn btn-primary">Submit</button>
            </div>

        </form>




    </div>



</div>













<app-footer>
</app-footer>