import { Component, OnInit } from '@angular/core';
import { AuthService } from 'src/app/service/auth.service';
import { CrudService } from 'src/app/service/crud.service';
import { ActivatedRoute, Router } from '@angular/router';
import { StallService} from 'src/app/service/stall.service'
import { HomeComponent } from '../home/home.component';
import{CountService} from 'src/app/service/count.service'
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  customer: any;
  vis: any;
  id: string;
  count: number;

  constructor(private actRoute: ActivatedRoute, public countservice:CountService ,private afu: AngularFireAuth ,public StallService:StallService,public authservice: AuthService,public crudservice:CrudService,private router: Router) { }

  //  id="OeUx7yk4uEK2k8e4rfk6";
  ngOnInit(): void {
    // this.id = this.actRoute.snapshot.paramMap.get('id');

    // this.StallService.visitorcount(this.id)
    // .get().toPromise()
    // .then(query => this.count=query.size);

    // this.countservice.get_visitor(this.id).then(data => {
    //   this.vis=data
    //   this.countservice.update_visitor(data,this.id,true)
    // })
  //   this.crudservice.get_Allcustomer().subscribe(data => {
  //     this.customer = data.map(e => {
  //       return {
  //         id: e.payload.doc.id,
  //         email: e.payload.doc.data()['email'],
  //       };})
  // })
  // if (this.authservice.currentUser==null){
  //   console.log("hi")
     
  //             }
  //             else{
                  
              
  //             this.StallService.addvisior(this.authservice.currentUser,this.id)
              
                
  //             } 
  
 }

check()
{
  
  console.log(this.authservice.currentUser)
  if (this.authservice.currentUser==null){
    this.router.navigate(['/login'])
  }
  else{
    
  this.countservice.get_visitor(this.id).then(data => {
    this.countservice.update_visitor(data,this.id,false)
    this.router.navigate(['/profile'])
  })
    
  }  
}

close(url){


  this.countservice.get_visitor(this.id).then(data => {
    this.countservice.update_visitor(data,this.id,false)
    window.location.replace(url);
  })


  
}

checkout(){
  this.afu.signOut().then(function() {
    // Sign-out successful.
  }, function(error) {
    // An error happened.
  });
}














}
