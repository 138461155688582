import { Component, OnInit } from '@angular/core';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-adminheader',
  templateUrl: './adminheader.component.html',
  styleUrls: ['./adminheader.component.scss']
})
export class AdminheaderComponent implements OnInit {

  constructor(private afu: AngularFireAuth) { }

  ngOnInit(): void {
  }
  
checkout(){
  this.afu.signOut().then(function() {
    // this.router.navigate(['/home'])
    window.location.assign("/home")
    // Sign-out successful.
  }, function(error) {
    // An error happened.
  });
}

}
