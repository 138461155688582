<!-- <div style="background: rgba(0, 0, 0, 0.8); width: 100%; height: 100%; z-index: 5; left: 0; top: 0; position: absolute;">
    <button style="background: linear-gradient(60deg, #ffa726, #fb8c00);
    border: none;
    padding: 20px 20px;
    color: white;
    width:200px;
    border-radius: 5px;
    margin-left: -5%;
    margin-top:26%;
    font-size: 25px;">Enter Now</button>
</div> -->
<!-- <button>{{count}}</button> -->
<div class="map" *ngFor="let item of stall">
  <div *ngIf="item.id == id">
    <!-- <pre>{{ item | json }}</pre> -->

    <div class="icon-bar">
      <a
        href="{{ item.facebook }}"
        class="facebook"
        target="_blank"
        *ngIf="item.facebook != ''"
        ><i class="fa fa-facebook"></i
      ></a>
      <a
        href="{{ item.twitter }} "
        class="twitter"
        target="_blank"
        *ngIf="item.twitter != ''"
        ><i class="fa fa-twitter"></i
      ></a>
      <!-- <a href="{{item.whatsapp}} " class="whatsapp " target="_blank" *ngIf="item.whatsapp!='' "><i class="fa fa-whatsapp "></i></a> -->
      <a
        href="{{ item.linkedin }} "
        class="linkedin"
        target="_blank"
        *ngIf="item.linkedin != ''"
        ><i class="fa fa-linkedin"></i
      ></a>
      <a
        href="{{ item.youtube }} "
        class="youtube1"
        target="_blank"
        *ngIf="item.youtube != ''"
        ><i class="fa fa-youtube"></i
      ></a>
    </div>
    <img src="{{ item.bgimage }} " alt=" " style="width: 100%" />
    <div *ngFor="let pin of item.pin" style="margin-top: 10px">
      <div id="pin " class="box" style="{{ pin.pinloc }}">
        <div class="blobs-container">
          <div class="blob red">
            <!-- <div> </div> -->
            <button
              class="btn fa fa-info fa-1x text-center"
              (click)="openDialog(item.id, item.name, pin.pinid, pin.pinname)"
            ></button>
          </div>
        </div>
      </div>
    </div>

    <div class="icon-barmeet">
      <a href="https://meet.google.com/ " target="_blank" class="google"
        ><i class="fa fa-video-camera"></i>
        <p style="margin-top: 10px">Google Meet</p></a
      >
      <a href="https://zoom.us/ " target="_blank" class="zoom"
        ><i class="fa fa-video-camera"></i>
        <p style="margin-top: 10px">Zoom Meet</p></a
      >
    </div>
    <div class="icon-barmeet1">
      <a (click)="openForm(item)" style="margin-left: 0px">
        <img
          src="https://firebasestorage.googleapis.com/v0/b/angboot-49fb1.appspot.com/o/chaticon.png?alt=media&token=e30e2650-4c1c-49fb-a367-3593feddfa0c"
          class="i"
          width="50"
          height="40 "
        />
        <p style="margin-top: 10px; color: black">Chat</p>
      </a>
    </div>
  </div>
</div>

<!-- 
<div class="smcolumn ">
    <a href="https://meet.google.com/ " target="_blank " class=" fa-2x iconsty " style="font-size:40px;margin-left:245px; ">
        <i class="fa fa-video-camera "></i>
        <p style="font-size: 10px;margin-left:240px; ">GoogleMeet</p>
    </a>

</div>


<div class="smcolumn ">
    <a href="https://zoom.us/ " target="_blank " class=" fa-2x iconsty " style="font-size:40px;margin-left:235px; ">
        <i class="fa fa-video-camera "></i>
        <p style="font-size: 10px;margin-left:230px; ">ZoomMeet</p>
    </a>

</div> -->

<!-- (click)="openDialog(item.id,item.name,pin.pinid,pin.pinname) " -->

<!-- style="font-size: small; color: white; " -->
<div class="chat-popup" #myForm>
  <form class="form-container">
    <div style="display: inline-flex">
      <h5>{{ ownerDetails?.name }}</h5>
      <span style="position: absolute; right: 20px" (click)="closeForm()"
        ><i class="fa fa-close"></i
      ></span>
    </div>
    <cdk-virtual-scroll-viewport
      itemSize="40"
      class="msg_history"
      *ngIf="showMessages"
    >
      <div *cdkVirtualFor="let x of messages; let i = index">
        <div class="incoming_msg" *ngIf="x.senderId !== api.currentUser.uid">
          <div class="incoming_msg_img">
            <img
              src="https://ptetutorials.com/images/user-profile.png"
              alt="sunil"
            />
          </div>
          <div class="received_msg">
            <div class="received_withd_msg">
              <p>{{ x?.content }}</p>
            </div>
          </div>
        </div>

        <div class="outgoing_msg" *ngIf="x.senderId == api.currentUser.uid">
          <div class="sent_msg">
            <p>{{ x?.content }}</p>
            <span
              class="time_date"
              id="{{ i == messages.length - 3 ? 'destination' : 'abc' }}"
            >
              {{ x?.timestamp?.toDate() | date: "dd MMM hh:mm" }}</span
            >
          </div>
        </div>
      </div>
    </cdk-virtual-scroll-viewport>
    <div class="type_msg">
      <div class="input_msg_write">
        <input
          type="text"
          class="write_msg"
          name="message"
          placeholder="Type a message"
          (keydown.enter)="sendMessage()"
          [(ngModel)]="message"
        />
        <button class="msg_send_btn" type="button" (click)="sendMessage()">
          <i class="fa fa-paper-plane-o" aria-hidden="true"></i>
        </button>
      </div>
    </div>
  </form>
</div>
