<div class="main-content">


    <div class="container-fluid">
        <div class="container" style=" margin-top: 100px; width: 100%; height: 200px;">
            <div class="container col-md-12" style="margin-bottom: 20px;">
                <div class="row " style="background-color:#ffffff;padding-bottom:20px;padding-top: 20px;margin:0px;border-radius: 5px;">

                    <div class="col-md-12 text-center" style="padding-left:35px;">

                        <h1 id="welcomeheading" style="margin-top:0px;">welcome </h1>
                        <h2>{{authservice.currentUserName}}</h2>
                        <p id="welcomepara">Thanks for registering. This dashboard has the complete details of the event. You can connect with exhibitor or visitor.</p>
                        <!-- <button class="btn buttoncolor">Take a Tour</button> -->

                    </div>
                </div>

            </div>



            <div class="container col-md-12" style="margin-bottom: 20px;">
                <div class="row " style="background-color:#ffffff;padding-bottom:20px;padding-top: 20px;margin:0px;border-radius: 5px;">

                    <div class="col-md-12 text-center" style="padding-left:35px;">
                        <h1>Search Your Stall</h1>

                        <div class="dropdown" style="height: 50px;">
                            <button class="btn  " type="button" data-toggle="dropdown" style="width: 300px;">Search by exhibitor name
                            </button>
                            <button class="btn  dropdown-toggle" type="button" data-toggle="dropdown" style="width: 300px; color: honeydew;">Main Product Categry
                            </button>
                            <button class="btn  dropdown-toggle" type="button" data-toggle="dropdown" style="width: 300px; color: honeydew;">Sub Products
                            </button>

                        </div>

                        <div class="card">
                            <h1 class="text-center" style="padding-top:20px;font-size:25px;">Search Result</h1>

                            <div class="card-body table-responsive">

                                <div class="container ">

                                    <div class="row">
                                        <!-- <h1 class="card-title">Request For a Meeting</h1> -->

                                        <div class="card-header card-header-warning" style="width:100%;">
                                            <h1 class="card-title">IKEA</h1>
                                            <!-- <p class="card-category">Product Name: <span>IKEA</span></p> -->
                                        </div>
                                        <div class="card-header card-header-warning" style="margin-top:20px;width:100%;">
                                            <h1 class="card-title">Fashion World</h1>
                                            <!-- <p class="card-category">Product Name: <span>Fashion World</span></p> -->
                                        </div>
                                        <div class="card-header card-header-warning" style="margin-top:20px;width:100%;">
                                            <h1 class="card-title">Coaster</h1>
                                            <!-- <p class="card-category">Product Name: <span>Coaster</span></p> -->
                                        </div>


                                        <div class="card-header card-header-warning" style="margin-top:20px;width:100%">
                                            <h1 class="card-title">Armani</h1>
                                            <!-- <p class="card-category">Product Name: <span>Aroma</span></p> -->
                                        </div>


                                    </div>


                                </div>



                            </div>


                        </div>










                        <!-- <button class="btn buttoncolor">Take a Tour</button> -->

                    </div>
                </div>

            </div>
            <div class="row ">
                <div class="col ">

                    <div class="container ">
                        <h1 style="top: 0px; left: 0px; ">Live Event Videos</h1>
                    </div>

                    <div class="container col-12 " style="margin-bottom: 8px; left: 0; " *ngFor="let item of Live; index as i ">
                        <div class="row " *ngIf="i<3" style="background-color:white; padding-bottom:10px; padding-top: 10px;margin:0px; border-radius: 5px; ">
                            <div class="col-md-4 " style="padding-right:0px;padding-left:10px; ">
                                <!-- <i class="fa fa-youtube-play" style="font-size: 60px;margin-left:30px;"></i> -->
                                <!-- <iframe width="150 " height="150 " src="https://www.youtube.com/embed/tgbNymZ7vqY ">
                                </iframe> -->
                                <!-- <iframe width="150 " height="150 " src="https://www.youtube.com/embed/{{item.videolink}} " frameborder="0 " allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen>
                                </iframe> -->

                                <div style="width:auto;height:50%;">
                                    <img src="https://lh3.googleusercontent.com/S4wylkvt2jz16hnG9IG0pAZosbB82nWWy8P-rQkb54uH-SCVd5L2j7z7x1Vz5pZvIRc" width="150px" height="150px;">
                                </div>

                            </div>
                            <div class="col-md-8 " style="padding-left: 10px; ">
                                <h1 class="card-title " style="margin-top:0px; ">{{item.title}} </h1>
                                <p class="card-category ">{{item.videoDes}}</p>
                                <!-- <p id="link ">test.com</p> -->
                                <button (click)="openlive(item.videolink) " style="background: linear-gradient(60deg, #ffa726, #fb8c00);border:none;padding:5px 10px;color:white;border-radius:5px;margin-left:85px;margin-bottom:10px;font-size:16px; ">view</button>
                            </div>
                        </div>
                    </div>
                    <!--         
            <div class="container col-12 " style="margin-bottom: 8px; left: 0; ">
                <div class="row " style="background-color:white;padding-bottom:10px;padding-top: 10px;margin:0px;border-radius: 5px; ">
                    <div class="col-md-4 " style="padding-right:0px;padding-left:10px; ">
                        <img src="https://th.bing.com/th/id/OIP.Ldh8xBUyVhoqfJJKhupLGAHaEK?pid=Api&rs=1 " alt="img1 " width="100% " height="auto " style="object-fit: cover; ">
                    </div>
                    <div class="col-md-8 " style="padding-left: 10px; ">
                        <h1 class="card-title " style="margin-top:0px; ">Basic Html projects Lorem  </h1>
                        <p class="card-category ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, explicabo!</p>
                        <p id="link ">test.com</p>
                  </div>
                </div>                
            </div> -->

                </div>

                <div class="col ">

                    <div class="container ">
                        <h1 style="top: 0px; left: 0px; ">Webinar Videos</h1>
                    </div>
                    <div class="container col-12 " style="margin-bottom: 8px; left: 0; " *ngFor="let item of webinar; index as i ">
                        <div class="row " *ngIf="i<3" style="background-color:white; padding-bottom:10px; padding-top: 10px;margin:0px; border-radius: 5px; ">
                            <div class="col-sm-4 " style="padding-right:0px;padding-left:10px; ">
                                <!-- <i class="fa fa-youtube-play" style="font-size: 60px;margin-left:30px;"></i> -->
                                <!-- <iframe width="150 " height="150 " src="https://www.youtube.com/embed/tgbNymZ7vqY ">
                                </iframe> -->
                                <div style="width:auto;height:50%;">
                                    <img src="https://lh3.googleusercontent.com/S4wylkvt2jz16hnG9IG0pAZosbB82nWWy8P-rQkb54uH-SCVd5L2j7z7x1Vz5pZvIRc" width="150px" height="150px;">
                                </div>
                                <!-- <iframe width="150 " height="150 " src="https://www.youtube.com/embed/{{item.videolink}} " frameborder="0 " allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture " allowfullscreen>
                                    </iframe> -->

                            </div>
                            <div class="col-md-8  col-sm-8" style="padding-left: 10px; ">
                                <h1 class="card-title " style="margin-top:0px; ">{{item.title}} </h1>
                                <p class="card-category ">{{item.videoDes}}</p>
                                <!-- <p id="link ">test.com</p> -->
                                <button (click)="openlive(item.videolink) " style="background: linear-gradient(60deg, #ffa726, #fb8c00);border:none;padding:5px 10px;color:white;border-radius:5px;margin-left:85px;margin-bottom:10px;font-size:16px; ">view</button>
                            </div>
                        </div>
                    </div>

                    <!-- <div class="container col-12 " style="margin-bottom: 8px; left: 0; " *ngFor="let item of webinar; ">
                        <div class=" row " style="background-color:white;padding-bottom:10px;padding-top: 10px;margin:0px;border-radius: 5px; ">
                            <div class="col-md-4 " style="padding-right:0px;padding-left:10px; ">
                                <iframe width="150 " height="150 " [src]='photoURL(item.videolink) '>
                                </iframe>
                            </div>
                            <div class="col-md-8 " style="padding-left: 10px; ">
                                <h1 class="card-title " style="margin-top:0px; ">webinar </h1>
                                <p class=" card-category ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, explicabo!</p>
                
                                <button style="background: linear-gradient(60deg, #ffa726, #fb8c00);border:none;padding:5px 10px;color:white;border-radius:5px;margin-left:125px;margin-bottom:10px;font-size:16px; ">view</button>
                            </div>
                        </div>
                    </div> -->

                    <!-- <div class="container col-12 " style="margin-bottom: 8px; left: 0; ">
                <div class="row " style="background-color:white;padding-bottom:10px;padding-top: 10px;margin:0px;border-radius: 5px; ">
                    <div class="col-md-4 " style="padding-right:0px;padding-left:10px; ">
                        <img src="https://th.bing.com/th/id/OIP.Ldh8xBUyVhoqfJJKhupLGAHaEK?pid=Api&rs=1 " alt="img1 " width="100% " height="auto " style="object-fit: cover; ">
                    </div>
                    <div class="col-md-8 " style="padding-left: 10px; ">
                        <h1 class="card-title " style="margin-top:0px; ">Basic Html projects Lorem  </h1>
                        <p class="card-category ">Lorem ipsum dolor sit amet consectetur adipisicing elit. Nesciunt, explicabo!</p>
                        <p id="link ">test.com</p>
                  </div>
                </div>                
            </div> -->

                </div>

            </div>




            <div class="row">
                <div class="col-lg-6 col-md-12">
                    <div class="card">
                        <div class="card-header card-header-warning">
                            <h4 class="card-title">Visited Stalls</h4>
                            <p class="card-category"> 15th September, 2020</p>
                        </div>
                        <div class="card-body table-responsive table-wrapper-scroll-y my-custom-scrollbar ">
                            <table class="table table-hover">
                                <thead class="text-warning">
                                    <!-- <th>No</th> -->
                                    <th> Stall Name</th>
                                    <th>Product Name</th>
                                    <th>Files</th>

                                </thead>
                                <tbody *ngFor="let item of save; let i = index">
                                    <tr>
                                        <!-- <td>{{i+1}}</td> -->
                                        <td>{{item.sname}}</td>
                                        <td>{{item.pinname}}</td>

                                        <button class="fa fa-briefcase fa-2x" (click)="openDialog(item.sid,item.sname,item.pinid,item.pinname)" style="border:none;background:none;"></button>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>


                <div class="col-lg-6 col-md-6">
                    <div class="card">
                        <div class="card-header card-header-warning">
                            <h4 class="card-title">Today Activity</h4>
                            <p class="card-category"> 15th September, 2020</p>
                        </div>
                        <div class="card-body table-responsive table-wrapper-scroll-y my-custom-scrollbar ">
                            <table class="table table-hover">
                                <thead class="text-warning">
                                    <th>Title</th>
                                    <th>Time</th>
                                    <th>des</th>


                                </thead>
                                <tbody *ngFor="let user of activity;">
                                    <tr>

                                        <td>{{user.title}}</td>
                                        <td>{{user.stime}} - {{user.etime}}</td>

                                        <td>{{user.des}}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!--             <div class="col-lg-4 col-md-12">
                <div class="card">
                    <div class="card-header card-header-tabs card-header-primary">
                        <div class="nav-tabs-navigation">
                            <div class="nav-tabs-wrapper">
                                <span class="nav-tabs-title">Tasks:</span>
                                <ul class="nav nav-tabs" data-tabs="tabs">
                                    <li class="nav-item">
                                        <a mat-button class="nav-link active" href="#profile" data-toggle="tab">
                                            <i class="material-icons">bug_report</i> Bugs
                                <div class="ripple-container"></div>
                                </a>
                                </li>
                                <li class="nav-item">
                                    <a mat-button class="nav-link" href="#messages" data-toggle="tab">
                                        <i class="material-icons">code</i> Website
                                        <div class="ripple-container"></div>
                                    </a>
                                </li>
                                <li class="nav-item">
                                    <a mat-button class="nav-link" href="#settings" data-toggle="tab">
                                        <i class="material-icons">cloud</i> Server
                                        <div class="ripple-container"></div>
                                    </a>
                                </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="tab-content">
                            <div class="tab-pane active" id="profile">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" value="" checked>
                                                        <span class="form-check-sign">
                                                            <span class="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>Sign contract for "What are conference organizers afraid of?"</td>
                                            <td class="td-actions text-right">
                                                <button mat-raised-button type="button" matTooltip="Edit Task" class="btn btn-primary btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">edit</i>
                                                </button>
                                                <button mat-raised-button type="button" matTooltip="Remove" class="btn btn-danger btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" value="">
                                                        <span class="form-check-sign">
                                                            <span class="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>Lines From Great Russian Literature? Or E-mails From My Boss?</td>
                                            <td class="td-actions text-right">
                                                <button mat-raised-button type="button" matTooltip="Edit Task" class="btn btn-primary btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">edit</i>
                                                </button>
                                                <button mat-raised-button type="button" matTooltip="Remove" class="btn btn-danger btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" value="">
                                                        <span class="form-check-sign">
                                                            <span class="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit
                                            </td>
                                            <td class="td-actions text-right">
                                                <button mat-raised-button type="button" matTooltip="Edit Task" class="btn btn-primary btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">edit</i>
                                                </button>
                                                <button mat-raised-button type="button" matTooltip="Remove" class="btn btn-danger btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" value="" checked>
                                                        <span class="form-check-sign">
                                                            <span class="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>Create 4 Invisible User Experiences you Never Knew About</td>
                                            <td class="td-actions text-right">
                                                <button mat-raised-button type="button" matTooltip="Edit Task" class="btn btn-primary btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">edit</i>
                                                </button>
                                                <button mat-raised-button type="button" matTooltip="Remove" class="btn btn-danger btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane" id="messages">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" value="" checked>
                                                        <span class="form-check-sign">
                                                            <span class="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit
                                            </td>
                                            <td class="td-actions text-right">
                                                <button mat-raised-button type="button" matTooltip="Edit Task" class="btn btn-primary btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">edit</i>
                                                </button>
                                                <button mat-raised-button type="button" matTooltip="Remove" class="btn btn-danger btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" value="">
                                                        <span class="form-check-sign">
                                                            <span class="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>Sign contract for "What are conference organizers afraid of?"</td>
                                            <td class="td-actions text-right">
                                                <button mat-raised-button type="button" matTooltip="Edit Task" class="btn btn-primary btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">edit</i>
                                                </button>
                                                <button mat-raised-button type="button" matTooltip="Remove" class="btn btn-danger btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <div class="tab-pane" id="settings">
                                <table class="table">
                                    <tbody>
                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" value="">
                                                        <span class="form-check-sign">
                                                            <span class="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>Lines From Great Russian Literature? Or E-mails From My Boss?</td>
                                            <td class="td-actions text-right">
                                                <button mat-raised-button type="button" matTooltip="Edit Task" class="btn btn-primary btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">edit</i>
                                                </button>
                                                <button mat-raised-button type="button" matTooltip="Remove" class="btn btn-danger btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" value="" checked>
                                                        <span class="form-check-sign">
                                                            <span class="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>Flooded: One year later, assessing what was lost and what was found when a ravaging rain swept through metro Detroit
                                            </td>
                                            <td class="td-actions text-right">
                                                <button mat-raised-button type="button" matTooltip="Edit Task" class="btn btn-primary btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">edit</i>
                                                </button>
                                                <button mat-raised-button type="button" matTooltip="Remove" class="btn btn-danger btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="form-check">
                                                    <label class="form-check-label">
                                                        <input class="form-check-input" type="checkbox" value="" checked>
                                                        <span class="form-check-sign">
                                                            <span class="check"></span>
                                                        </span>
                                                    </label>
                                                </div>
                                            </td>
                                            <td>Sign contract for "What are conference organizers afraid of?"</td>
                                            <td class="td-actions text-right">
                                                <button mat-raised-button type="button" matTooltip="Edit Task" class="btn btn-primary btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">edit</i>
                                                </button>
                                                <button mat-raised-button type="button" matTooltip="Remove" class="btn btn-danger btn-link btn-sm btn-just-icon">
                                                    <i class="material-icons">close</i>
                                                </button>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->

            </div>






            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <h1 class="text-center" style="padding-top:20px;font-size:25px;">AI Based Matching</h1>

                    <div class="card-body table-responsive">

                        <div class="container ">

                            <div class="row">
                                <!-- <h1 class="card-title">Request For a Meeting</h1> -->

                                <div class="card-header card-header-warning" style="width:100%;">
                                    <h1 class="card-title">IKEA 90%</h1>
                                    <!-- <p class="card-category">Product Name: <span>IKEA</span></p> -->
                                </div>
                                <div class="card-header card-header-warning" style="margin-top:20px;width:100%;">
                                    <h1 class="card-title">Fashion World 80%</h1>
                                    <!-- <p class="card-category">Product Name: <span>Fashion World</span></p> -->
                                </div>
                                <div class="card-header card-header-warning" style="margin-top:20px;width:100%;">
                                    <h1 class="card-title">Coaster 75%</h1>
                                    <!-- <p class="card-category">Product Name: <span>Coaster</span></p> -->
                                </div>


                                <div class="card-header card-header-warning" style="margin-top:20px;width:100%">
                                    <h1 class="card-title">Armani 60%</h1>
                                    <!-- <p class="card-category">Product Name: <span>Aroma</span></p> -->
                                </div>


                            </div>


                        </div>



                    </div>


                </div>

            </div>

            <div class="col-lg-12 col-md-12 ">
                <div class="card ">
                    <div class="card-header card-header-warning ">
                        <h1 class="card-title ">Request For a Meeting</h1>
                        <p class="card-category ">New employees on 15th September, 2016</p>
                    </div>
                    <div class="card-body table-responsive ">

                        <div class="container " style=" margin-top: 10px; ">

                            <div class="row ">
                                <!-- <h1 class="card-title ">Request For a Meeting</h1> -->

                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; ">Exhibitor</label>
                                    <input type="text " class="form-control ">
                                </div>

                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; ">Subject</label>
                                    <input type="text " class="form-control ">

                                </div>
                                <div class="form-group col-md-12 ">
                                    <label style="font-weight: 800; ">Message</label>
                                    <input type="text " class="form-control ">
                                </div>

                            </div>

                            <div class="col text-center " style="margin-top: 50px; margin-bottom: 50px; ">
                                <button type="submit " class="btn buttoncolor ">Submit</button>
                            </div>
                        </div>



                    </div>


                </div>

            </div>

            <div style="margin-bottom: 100px; color: white; ">
                .
            </div>





        </div>
    </div>