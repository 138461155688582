import { Inject } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { MAT_DIALOG_DATA } from '@angular/material/dialog';
import { CrudService } from 'src/app/service/crud.service';
export interface DialogData {
  id : string
}

@Component({
  selector: 'app-viewpopupsadex',
  templateUrl: './viewpopupsadex.component.html',
  styleUrls: ['./viewpopupsadex.component.scss']
})
export class ViewpopupsadexComponent implements OnInit {

  save: any;
  title: string;
  Name: any;
  Designation: any;
  Companyname: any;
  Address: any;
  Country: any;
  Telephone: any;
  Mobile: any;
  Email: any;
  Website: any;
  ref: any;
  importedvalue: any;
  IndianSupplier: any;
  productsofimport: any;
  Countryofimport: any;
  intrested: any;
  product: any;
  year: any;
  lname: any;
  turnover1617: any;
  turnover1718: any;
  turnover1819: any;
  export1617: any;
  export1718: any;
  export1819: any;


  constructor(public crudservice:CrudService,@Inject(MAT_DIALOG_DATA) public data: DialogData) { }

  ngOnInit(): void {
    // console.log("popup")
// console.log(this.data.id)

this.title=(this.data.id['title'])
this.Name=(this.data.id['name'])
this.Designation=(this.data.id['Designation'])
this.Companyname=(this.data.id['CompanyName'])
this.Address=(this.data.id['Address'])
this.Country=(this.data.id['Country'])
this.Telephone=(this.data.id['Telephone'])
this.Mobile=(this.data.id['Mobile'])
this.Email=(this.data.id['email'])
this.Website=(this.data.id['Website'])
this.ref=(this.data.id['ref'])
this.importedvalue=(this.data.id['importedvalue'])
this.IndianSupplier=(this.data.id['IndianSupplier'])
this.productsofimport=(this.data.id['productsofimport'])
this.Countryofimport=(this.data.id['Countryofimport'])
this.intrested=(this.data.id['intrested'])
this.product=(this.data.id['product'])
this.year=(this.data.id['year'])
this.lname=(this.data.id['lname'])

this.turnover1617=(this.data.id['turnover1617'])
this.turnover1718=(this.data.id['turnover1718'])
this.turnover1819=(this.data.id['turnover1819'])  

this.export1617=(this.data.id['export1617'])
this.export1718=(this.data.id['export1718'])
this.export1819=(this.data.id['export1819'])
  }

}
