import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { CountService } from 'src/app/service/count.service';
import { CrudService } from 'src/app/service/crud.service';
import { StallService} from 'src/app/service/stall.service'
import {Location} from '@angular/common';
import { AngularFireAuth } from '@angular/fire/auth';

@Component({
  selector: 'app-exheader',
  templateUrl: './exheader.component.html',
  styleUrls: ['./exheader.component.scss']
})
export class ExheaderComponent implements OnInit {
  customer: any;
  vis: any;
  id: string;
  count: number;

  constructor(private afu: AngularFireAuth,private actRoute: ActivatedRoute, private _location: Location, public countservice:CountService  ,public StallService:StallService,public authservice: AuthService,public crudservice:CrudService,private router: Router) { }
  //  id="OeUx7yk4uEK2k8e4rfk6";
  ngOnInit(): void {


    
    

    this.id = this.actRoute.snapshot.paramMap.get('id');

    this.StallService.visitorcount(this.id)
    .get().toPromise()
    .then(query => this.count=query.size);



  this.crudservice.get_Allcustomer().subscribe(data => {
    this.customer = data.map(e => {
      return {
        id: e.payload.doc.id,
        email: e.payload.doc.data()['email'],
      };})
})
}

backClicked() {
  this._location.back();
}

check()
{

console.log(this.authservice.currentUser)
if (this.authservice.currentUser==null){
  this.router.navigate(['/exlogin'])
}
else{
  
this.countservice.get_visitor(this.id).then(data => {
  this.countservice.update_visitor(data,this.id,false)
  this.router.navigate(['/exprofile'])
})
  
}  
}

close(url){


this.countservice.get_visitor(this.id).then(data => {
  this.countservice.update_visitor(data,this.id,false)
  window.location.replace(url);
})


}


checkout(){
  this.afu.signOut().then(function() {
    // this.router.navigate(['/home'])
    window.location.assign("/home")
    // Sign-out successful.
  }, function(error) {
    // An error happened.
  });
}
}

