<div class="col-lg-12 col-md-12" style="padding:0px;">

    <div class="card" style="height:590px;">
        <div class="card-header card-header-warning">
            <h4 class="card-title">Product Files</h4>
            <!-- <i class="material-icons" style="float:right;color:black;border:1px solid black;border-radius:5px;margin-left:650px;margin-top:-36px;">close</i> -->


            <div class="smcolumn" style="margin-left:730px;margin-top:-30px;">
                <button (click)="onNoClick()" style="background:none;border:none;">
          <a  class="fa fa-window-close fa-2x iconsty" style="color: black;"> </a>
        </button>

            </div>
            <!-- <p>Username:<span>  Name</span></p> -->
            <!-- <p style="float:right;margin-top:-30px;">No of exbitor:<span> 00</span></p> -->

            <!-- 
            <form action="">
                <label for="filter" style="color:white;">Filter:</label>
                <select name="filter" id="filter">
            
      <option value="volvo">Search </option>
      <option value="volvo">Volvo</option>
      <option value="saab">Saab</option>
      <option value="opel">Opel</option>
      <option value="audi">Audi</option>
    </select>

            </form> -->
            <!--             
<div class="dropdown">
    <button onclick="myFunction()" class="dropbtn">Filter</button>
    <div id="myDropdown" class="dropdown-content">
      <input type="text" placeholder="Search.." id="myInput" onkeyup="filterFunction()">
      <a href="#about">About</a>
      <a href="#base">Base</a>
      <a href="#blog">Blog</a>
      <a href="#contact">Contact</a>
      <a href="#custom">Custom</a>
      <a href="#support">Support</a>
      <a href="#tools">Tools</a>
    </div>
  </div> -->

            <!-- 

  <script>
    /* When the user clicks on the button,
    toggle between hiding and showing the dropdown content */
    function myFunction() {
      document.getElementById("myDropdown").classList.toggle("show");
    }
    
    function filterFunction() {
      var input, filter, ul, li, a, i;
      input = document.getElementById("myInput");
      filter = input.value.toUpperCase();
      div = document.getElementById("myDropdown");
      a = div.getElementsByTagName("a");
      for (i = 0; i < a.length; i++) {
        txtValue = a[i].textContent || a[i].innerText;
        if (txtValue.toUpperCase().indexOf(filter) > -1) {
          a[i].style.display = "";
        } else {
          a[i].style.display = "none";
        }
      }
    }
    </script> -->



            <!-- <p class="card-category">New employees on 15th September, 2016</p> -->
        </div>
        <div class="card-body table-responsive text-center table-wrapper-scroll-y my-custom-scrollbar" style="padding:0px;">
            <table class="table table-hover">
                <thead class="text-warning">

                    <th class="sticky">Product</th>


                </thead>
                <tbody *ngFor="let savedproduct of stall">
                    <tr *ngFor="let pin of savedproduct.pin">
                        <!-- <td>{{savedproduct.stallid}}</td> -->

                        <button class="text-center" style="width:100%;background: linear-gradient(60deg, #ffa726, #fb8c00);border:none;padding:10px 10px;color:white;border-radius:5px;margin-bottom:10px;font-size:16px;" (click)=" openDialog(savedproduct.stallid, 'stall ',pin.pinid,pin.pinname) "> <td>{{pin.pinname}}</td> </button>

                    </tr>


                </tbody>
            </table>
            <!-- <table class="table table-hover ">
                <thead class="text-warning ">

                    <th class="sticky ">Product name </th>


                </thead>
                <tbody *ngFor="let product of pin ">
                    <tr>
                        <button (click)="openDialog(savedproduct.stallid,item.name,product.pinid,product.pinname) "><td>{{product.pinname}}</td></button>



                    </tr>


                </tbody>
            </table> -->
        </div>
    </div>
</div>