import { Component, OnInit } from '@angular/core';
import { StallService} from 'src/app/service/stall.service'

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {
  stall: { id: string; name: any; bgimage: any; }[];
  customer: { acess:string;id: string; Address: any; CompanyName: any; title: any; ref: any; productsofimport: any; product: any; importedvalue: any; email: any; Website: any; Telephone: any; Mobile: any; IndianSupplier: any; Designation: any; Countryofimport: any; Country: any; }[];
  ex: { id: string; intrested: any; Address: any; CompanyName: any; title: any; ref: any; productsofimport: any; product: any; name: any; importedvalue: any; email: any; Website: any; Telephone: any; Mobile: any; IndianSupplier: any; Designation: any; Countryofimport: any; Country: any; }[];


  constructor(public StallService:StallService) { }

  ngOnInit(): void {
    this.StallService.get_allstall().subscribe(data => {
      this.stall = data.map(e => {
        return {
          id: e.payload.doc.id,
          name: e.payload.doc.data()['name'],
          bgimage : e.payload.doc.data()['bgimage'],

          exnumber: e.payload.doc.data()['exnumber'],
          facebook: e.payload.doc.data()['facebook'],
          linkedin: e.payload.doc.data()['linkedin'],
          sdesc: e.payload.doc.data()['sdesc'],
          snumber: e.payload.doc.data()['snumber'],
          stitle: e.payload.doc.data()['stitle'],
        
          whatsapp: e.payload.doc.data()['whatsapp'],
          youtube: e.payload.doc.data()['youtube'],
          zoom: e.payload.doc.data()['zoom'],
        

        };
      })
      // console.log(this.stall)
    })

    this.StallService.get_allvisitor().subscribe(data => {
      this.customer = data.map(e => {
        return {
          id: e.payload.doc.id,
          Address: e.payload.doc.data()['Address'],
          CompanyName: e.payload.doc.data()['CompanyName'],
          title: e.payload.doc.data()['title'],
          ref: e.payload.doc.data()['ref'],
          productsofimport: e.payload.doc.data()['CompanyName'],
          product: e.payload.doc.data()['product'],
          importedvalue: e.payload.doc.data()['importedvalue'],
          intrested: e.payload.doc.data()['intrested'],
          email: e.payload.doc.data()['email'],
          name: e.payload.doc.data()['name'],
          Website: e.payload.doc.data()['Website'],
          Telephone: e.payload.doc.data()['Telephone'],
          Mobile: e.payload.doc.data()['Mobile'],
          IndianSupplier: e.payload.doc.data()['IndianSupplier'],
          Designation: e.payload.doc.data()['Designation'],
          Countryofimport: e.payload.doc.data()['Countryofimport'],
          Country: e.payload.doc.data()['Country'],
          acess: e.payload.doc.data()['acess'],
        
        };
      })
      // console.log(this.customer)
    })


    this.StallService.get_allex().subscribe(data => {
      this.ex = data.map(e => {
        return {
          id: e.payload.doc.id,
          intrested: e.payload.doc.data()['intrested'],
          Address: e.payload.doc.data()['Address'],
          CompanyName: e.payload.doc.data()['CompanyName'],
          title: e.payload.doc.data()['title'],
          ref: e.payload.doc.data()['ref'],
          productsofimport: e.payload.doc.data()['CompanyName'],
          product: e.payload.doc.data()['product'],
          name: e.payload.doc.data()['name'],
          importedvalue: e.payload.doc.data()['importedvalue'],
          email: e.payload.doc.data()['email'],
          Website: e.payload.doc.data()['Website'],
          Telephone: e.payload.doc.data()['Telephone'],
          Mobile: e.payload.doc.data()['Mobile'],
          IndianSupplier: e.payload.doc.data()['IndianSupplier'],
          Designation: e.payload.doc.data()['Designation'],
          Countryofimport: e.payload.doc.data()['Countryofimport'],
          Country: e.payload.doc.data()['Country'],
          acess: e.payload.doc.data()['acess'],
        
        };
      })
      // console.log(this.ex)
    })


  }



  updateacessforvis(id,acess){
    this.StallService.updateuserstatus(id,acess)

  }

  updateacessforex(id,acess){
    this.StallService.updateexstatus(id,acess)

  }

}
