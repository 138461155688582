import { Component, OnInit } from '@angular/core';
import { StallService} from 'src/app/service/stall.service'

@Component({
  selector: 'app-fulllayout',
  templateUrl: './fulllayout.component.html',
  styleUrls: ['./fulllayout.component.scss']
})
export class FulllayoutComponent implements OnInit {
  vis: any;
  id: string;

  constructor(public StallService:StallService) { }

  ngOnInit(): void {
    // this.id="OeUx7yk4uEK2k8e4rfk6";

    //     this.StallService.get_visitor(this.id).then(data => {
    //   this.vis=data
    //   this.StallService.update_visitor(data,this.id,true)
    // })
  }

}
