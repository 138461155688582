import { ApiService } from './../../service/api.service';
import { HelperService } from './../../service/helper.service';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { StallService } from 'src/app/service/stall.service';
import { AngularFirestore } from '@angular/fire/firestore';
import { MatDialog } from '@angular/material/dialog';
import { StalldocComponent } from '../stalldoc/stalldoc.component';
import { async } from 'rxjs/internal/scheduler/async';
import { ActivatedRoute, Router } from '@angular/router';
import { AuthService } from 'src/app/service/auth.service';
import { windowTime } from 'rxjs/operators';
import { ChaticonComponent } from '../chaticon/chaticon.component';
import * as firebase from 'firebase';
import {
  ScrollToService,
  ScrollToConfigOptions,
} from '@nicky-lenaers/ngx-scroll-to';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.css'],
})
export class HomeComponent implements OnInit {
  stall: any;
  pin: { pinid: string; pinname: any }[];
  j = 0;
  selectedstall: any;
  id: any;
  animal: string;
  name: string;
  ethuku: Promise<any>;
  visitor: { id: string; email: any }[];
  flag = 1;
  count: number;
  @ViewChild('myForm', { static: true }) MyDOMElement: ElementRef;
  owner: any;
  showFiller: boolean = false; //sidebar -toggler
  users: Array<any>; // users list.
  public messages: Array<any> = []; // messages array/
  temp: any; // for handling temporory data from observables.
  showMessages = false; //Toggle to select a conversation.
  message: string = ''; // the  message to be sent
  ownerDetails: any;

  constructor(
    public dialog: MatDialog,
    public StallService: StallService,
    public fireservices: AngularFirestore,
    private actRoute: ActivatedRoute,
    public authservice: AuthService,
    private router: Router,
    private helper: HelperService,
    private _scrollToService: ScrollToService,
    public api: ApiService
  ) {}

  ngOnInit() {
    this.id = this.actRoute.snapshot.paramMap.get('id');
    this.api.setCurrentUser(localStorage.getItem('uid'));
    //  this.id="vBB2ZtnLcl5nODkZBDCQ";
    this.StallService.visitorcount(this.id)
      .get()
      .toPromise()
      .then((query) => (this.count = query.size));

    var name = localStorage.getItem('username');

    this.StallService.get_vis(this.id).subscribe((data) => {
      this.visitor = data.map((e) => {
        return {
          id: e.payload.doc.id,
          email: e.payload.doc.data()['email'],
        };
      });

      for (var i = 0; i < this.visitor.length; i++) {
        console.log(this.visitor[i].email);
        if (this.visitor[i].email == name) {
          this.flag = 0;
        }
      }
      if (this.flag == 1) {
        console.log('new');
        this.StallService.addvisior(name, this.id);
      } else {
        console.log('entered');
      }
    });

    //  this.StallService.get_visitor(this.id).then(data => {
    //   this.StallService.update_visitor(data,this.id,true)
    // })

    // else {
    //       // doc.data() will be undefined in this case
    //       console.log("No such document!");
    //     }
    //   }).catch(function(error) {
    //     console.log("Error getting document:", error);
    //   });;

    //   this.selectedstall= this.fireservices.collection("stall/").doc(this.id).get()
    //   .toPromise()
    //   .then(function(doc) {

    //     if (doc.exists) {
    //       const a = doc.data() as StallModule ;
    //      a.id=doc.id
    //       return a
    //     } else {
    //         console.log("No such document!");
    //     }})
    // .catch(function(error) {
    //     console.log("Error getting document:", error);
    // }); AsyncSubject

    this.StallService.get_allstall().subscribe((data) => {
      this.stall = data.map((e) => {
        return {
          id: e.payload.doc.id,
          name: e.payload.doc.data()['name'],
          bgimage: e.payload.doc.data()['bgimage'],
          linkedin: e.payload.doc.data()['linkedin'],
          insta: e.payload.doc.data()['insta'],
          facebook: e.payload.doc.data()['facebook'],
          uid: e.payload.doc.data()['exhibitorstall'],

          twitter: e.payload.doc.data()['twitter'],
          whatsapp: e.payload.doc.data()['whatsapp'],
          zoom: e.payload.doc.data()['zoom'],
          youtube: e.payload.doc.data()['youtube'],
        };
      });
      for (var i = 0; i < this.stall.length; i++) {
        this.StallService.getallpin(this.stall[i]['id']).subscribe((data) => {
          this.pin = data.map((e) => {
            return {
              pinid: e.payload.doc.id,
              pinname: e.payload.doc.data()['name'],
              pinleft: e.payload.doc.data()['left'],
              pintop: e.payload.doc.data()['top'],
              pinyou: e.payload.doc.data()['youtube'],
              pinloc:
                ' top:' +
                e.payload.doc.data()['top'] +
                '%;  left:' +
                e.payload.doc.data()['left'] +
                '%;',
            };
          });

          this.stall[this.j].pin = this.pin;
          this.j = this.j + 1;
          console.log(this.stall);
        });
      }

      // console.log("hi")
      // console.log(this.pin)
    });
  }

  getid() {
    return this.id;
  }

  openDialog(a, b, c, d): void {
    const dialogRef = this.dialog.open(StalldocComponent, {
      width: 'auto',
      height: 'auto',
      data: { stallid: a, stallname: b, pinid: c, pinname: d },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
      this.animal = result;
    });
  }

  openchat(): void {
    const dialogRef = this.dialog.open(ChaticonComponent, {
      width: '900px',
      height: '700px',
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log('The dialog was closed');
    });
  }

  openForm(owner) {
    this.selectUser(owner);
    this.ownerDetails = owner;
    console.log(this.ownerDetails, 'this.ownerDetails');
    this.MyDOMElement.nativeElement.style.display = 'block';
    // document.getElementById("myForm").style.display = "block";
  }

  async selectUser(user) {
    console.log('new user select', user);
    if (this.api.currentUser.conversations == undefined) {
      //means user has no conversations.
      this.api.currentUser.conversations = [];
    }
    let convo = [...this.api.currentUser.conversations]; //spread operators for ensuring type Array.
    let find = convo.find((item) => item.uid == user.uid); // Check If Its the same person who user has talked to before,
    if (find) {
      // Conversation Found
      this.api.getChat(find.chatId).subscribe((m) => {
        this.temp = m;
        // set the service values
        this.api.chat = this.temp[0];
        this.messages =
          this.api.chat.messages == undefined ? [] : this.api.chat.messages;
        this.showMessages = true;
        setTimeout(() => {
          // this.triggerScrollTo(); //scroll to bottom
        }, 1000);
        return;
      });
    } else {
      this.api.addNewChat().then(async () => {
        let b = await this.api.addConvo(user);
      });
    }
  }

  closeForm() {
    this.MyDOMElement.nativeElement.style.display = 'none';
    // document.getElementById("myForm").style.display = "none";
  }

  sendMessage() {
    // If message string is empty
    if (this.message == '') {
      alert('Enter message');
      return;
    }
    //set the message object
    let msg = {
      senderId: this.api.currentUser.uid,
      senderName: this.api.currentUser.name,
      timestamp: new Date(),
      content: this.message,
    };
    //empty message
    this.message = '';
    //update
    this.messages.push(msg);
    console.log('list', this.messages);
    this.api.pushNewMessage(this.messages).then(() => {
      console.log('sent');
    });
  }

  //Scroll to the bottom
  public triggerScrollTo() {
    const config: ScrollToConfigOptions = {
      target: 'destination',
    };
    this._scrollToService.scrollTo(config);
  }

  // Firebase Server Timestamp
  get timestamp() {
    return firebase.firestore.FieldValue.serverTimestamp();
  }

  /* Main Code Logic */
  toggleMessages() {
    this.showMessages = !this.showMessages;
  }
}
