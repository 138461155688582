<!-- <nav class="navbar navbar-expand-lg navbar-light  navbar-default upper-overlay" style="padding: 10px;">
    <a class="navbar-brand" href="#" style="padding-left: 10px;">
        <h2 style="margin-top:10%;">Virtual Expo 2020 </h2>
    </a>

    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link fa fa-home fa-2x" style="font-weight:bold;padding-left: 20px; padding-right: 20px; font-size-adjust: initial;" aria-hidden="true" href="/home" data-toggle="tooltip" data-placement="top" title="Hooray!"></a>
            </li>

            <li class="nav-item">
                <a class="nav-link fa fa-map-marker fa-2x" (click)="close(' /exprofile ')" style="font-weight:bold;color:black;padding-left: 20px; padding-right: 20px; " aria-hidden="true"></a>
            </li>
            {{vis}}
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" (click)="check()" class="nav-link fa fa-user-circle-o  fa-2x" style="font-weight:bold;color:black;padding-left: 20px; padding-right: 20px;" aria-hidden="true"></a>
            </li>
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" (click)="checkout()" class="nav-link fa fa-sign-out  fa-2x" style="font-weight:bold;color:black;padding-left: 20px; padding-right: 20px;" aria-hidden="true"></a>
            </li>
            {{authservice.currentUserName}}

            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" class="nav-link  fa-2x" aria-hidden="true"><span style="font-size:18px;margin-left:400px;padding-left: 20px; padding-right:20px; font-weight:bold;color:black;">Attendies: 50</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" class="nav-link  fa-2x" aria-hidden="true"><span style="font-size:18px;padding-left: 20px; padding-right:20px; font-weight:bold;color:black;"> Your Stall Visitor: 4</span></a>
            </li>


        </ul>
    </div>
</nav> -->

<nav class="navbar navbar-expand-lg navbar-light  navbar-default upper-overlay" style="padding: 10px;">
    <a class="navbar-brand" href="#" style="padding-left: 10px;">
        <h2>Expo 2020 Visitor Dashboard</h2>
    </a>

    <div class="collapse navbar-collapse" id="navbarNav">
        <ul class="navbar-nav">
            <li class="nav-item active">
                <a class="nav-link fa fa-home fa-2x" style="padding-left: 20px; padding-right: 20px; font-size-adjust: initial;" aria-hidden="true" href="/home" data-toggle="tooltip" data-placement="top" title="Hooray!"></a>
            </li>

            <li class="nav-item">
                <a class="nav-link fa-2x" href="https://noticeperiod.xyz/sandbox2/" style="padding-left: 10px; padding-right: 20px;" aria-hidden="true">

                    <img src="https://www.iconfinder.com/data/icons/3d-printer-5/64/10_map_location_pin_3d_printer_technology_gadget-512.png" width="32 " height="29 "></a>

            </li>

            <li class="nav-item">
                <a class="nav-link fa fa-arrow-circle-o-left fa-2x" href="/home" style="padding-left: 10px; padding-right: 20px;" aria-hidden="true"></a>
            </li>
            <li class="nav-item">
                <a href="" class=" nav-link fa-2x " (click)="close( '/exprofile') " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true ">
                    <img src="https://firebasestorage.googleapis.com/v0/b/angboot-49fb1.appspot.com/o/79-797480_markers-clipart-whiteboard-marker-map-icon-png-black.png?alt=media&token=0b403969-3bfb-4642-82fd-593a28ac4a5a" width="28 " height="25 "></a>
            </li>
            {{vis}}
            <li class="nav-item ">
                <a class="nav-link " aria-hidden="true " (click)="check() " class="nav-link fa fa-user-circle-o fa-2x " style="padding-left: 20px; padding-right: 20px; " aria-hidden="true "></a>
            </li>
            {{authservice.currentUserName}}

            <!-- <li class="nav-item">
                <a class="nav-link " aria-hidden="true" class="nav-link  fa-2x" aria-hidden="true"><span style="font-size:18px;margin-left:400px;padding-left: 20px; padding-right:20px; font-weight:bold;color:black;">Attendies</span></a>
            </li> -->
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" class="nav-link  fa-2x" aria-hidden="true"><span style="font-size:18px;padding-left: 20px; padding-right:20px; font-weight:bold;color:black;"> Your Stall Visitor: {{count}}</span></a>
            </li>
            <li class="nav-item">
                <a class="nav-link " aria-hidden="true" (click)="checkout()" class="nav-link fa fa-sign-out  fa-2x" style="font-weight:bold;color:black;padding-left: 20px; padding-right: 20px;" aria-hidden="true"></a>
            </li>


        </ul>
    </div>
</nav>