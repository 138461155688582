import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-socialicon',
  templateUrl: './socialicon.component.html',
  styleUrls: ['./socialicon.component.scss']
})
export class SocialiconComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
