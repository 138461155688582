<div class="footer" style="height:145px;margin-bottom:40px;">

    <div class="smpd" style="margin-left:30%;">

        <!-- <div class="smcolumn">
            <a (click)="open()"><i class="fa fa-briefcase fa-2x iconsty" style="padding-left:42px;font-size:42px;"></i></a>
        </div>
        <div class="smcolumn">
            <a href="" class=" fa fa-envelope fa-2x iconsty " style="font-size:38px;padding-left:42px; "></a>
        </div>



        <div class="smcolumn ">
            <a href="" class="fa-2x iconsty " style="font-size:38px; ">
                <img src="https://www.pinclipart.com/picdir/big/15-154817_blueprint-logo-blueprint-icon-png-clipart.png " width="40 " height="30 " style="margin-top: 10%; "> </a>

        </div>


        <div class="smcolumn ">
            <a href="res/ o1yHRVf4X3VTAZH3eWJV" class=" fa-2x iconsty " style="font-size:37px; ">
                <img src=" https://cdn.iconscout.com/icon/premium/png-512-thumb/form-62-795552.png " width="40 " height="30 " style="margin-top: 10%; "></a>
        </div>
        <div class="smcolumn ">
            <a href="auditorium" class=" fa-2x iconsty " style="font-size:43px;margin-top:-4px; ">
                <img src="https://www.fullbasketproperty.com/uploads/amenities/8befaeea578a051f1ed0db4c7f10b755.png " width="40 " height="33 " style="margin-top:10%; "> </a>
        </div>
        <div class="smcolumn ">
            <a href="https://noticeperiod.xyz/sandbox2/" class=" fa-2x iconsty " style="font-size:41px; ">
                <img src="https://www.iconfinder.com/data/icons/3d-printer-5/64/10_map_location_pin_3d_printer_technology_gadget-512.png " width="40 " height="40 ">
            </a>
        </div> -->


        <!-- <div class="smcolumn ">
            <a href="https://meet.google.com/" target="_blank" class=" fa-2x iconsty " style="font-size:40px;margin-left:245px;">
                <i class="fa fa-video-camera"></i>
                <p style="font-size: 10px;margin-left:240px;">GoogleMeet</p>
            </a>

        </div>


        <div class="smcolumn ">
            <a href="https://zoom.us/" target="_blank" class=" fa-2x iconsty " style="font-size:40px;margin-left:235px;">
                <i class="fa fa-video-camera"></i>
                <p style="font-size: 10px;margin-left:230px;">ZoomMeet</p>
            </a>

        </div> -->



        <div class="smcolumn ">
            <a href="https://api.whatsapp.com/send?phone=+91 9500788278" target="_blank" class=" fa-2x iconsty " style="font-size:40px;margin-left:185px;">
                <i class="fa fa-whatsapp"></i>
                <p style="font-size: 10px;margin-left:170px;">whatsappChat</p>
            </a>

        </div>

        <div class="smcolumn ">
            <a (click)="open()"><i class="fa fa-briefcase fa-2x iconsty" style="font-size:40px;margin-left:150px;"  ></i></a>
            <p style="font-size: 10px;margin-left:149px;">Briefcase</p>
        </div>

        <div class="smcolumn ">

            <a href=""><i class="fa fa-youtube-play fa-2x iconsty" style="font-size:40px;margin-left:105px;"  ></i></a>

            <p style="font-size: 10px;margin-left:112px;">video</p>
        </div>

        <div class="smcolumn ">
            <a href="profile/floorplan" class=" fa-2x iconsty " style="font-size:38px;margin-left:93px;">
                <img src="https://image.flaticon.com/icons/png/512/1484/1484147.png " width="40 " height="40 ">
                <p style="font-size: 10px;margin-left:90px;">FloorPlan</p>
            </a>

        </div>

        <div class="smcolumn ">
            <a href="auditorium" class=" fa-2x iconsty " style="font-size:38px;margin-left:70px;">
                <img src="https://www.fullbasketproperty.com/uploads/amenities/8befaeea578a051f1ed0db4c7f10b755.png" width="40 " height="40 ">
                <p style="font-size: 10px;margin-left:65px;">Auditorium</p>
            </a>

        </div>
        <div class="smcolumn ">
            <a href="profile/res/o1yHRVf4X3VTAZH3eWJV" class=" fa-2x iconsty " style="font-size:40px;margin-left:35px;">
                <img src="https://cdn.iconscout.com/icon/premium/png-512-thumb/form-62-795552.png" width="40 " height="40 ">
                <p style="font-size: 10px;margin-left:20px;margin-top:2.5%;">Resourcecenter</p>
            </a>

        </div>


        <div class="smcolumn ">
            <a href="https://noticeperiod.xyz/sandbox2/" class=" fa-2x iconsty " style="font-size:40px;">
                <img src="https://www.iconfinder.com/data/icons/3d-printer-5/64/10_map_location_pin_3d_printer_technology_gadget-512.png " width="40 " height="40 ">
                <p style="font-size: 10px;">3dstall</p>
            </a>

        </div>

    </div>

</div>