<!-- 
<ul>
<li *ngFor="let user of customer">{{ user.name }} {{ user.id }} <button (click)="updateacessforvis(user.id,user.acess )">{{user.acess}}</button></li>

</ul>


<ul>
    <li *ngFor="let exuser of ex">{{ exuser.name }} {{ exuser.id }} <button (click)="updateacessforex(exuser.id,exuser.acess )">{{exuser.acess}}</button></li>
    
    </ul> -->


<app-adminheader></app-adminheader>
<!-- <mat-drawer-container class="example-container"> -->
    <!-- <mat-drawer mode="side" opened>-->
        <app-adminsidebar></app-adminsidebar>
<!--         
    </mat-drawer>
    <mat-drawer-content  style="overflow: hidden;"> -->
        <!--   -->
        <app-adminbody></app-adminbody>
    <!-- </mat-drawer-content>
</mat-drawer-container> -->