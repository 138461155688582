import { Component } from '@angular/core';
import {CrudService} from './service/crud.service';
import { visitAll } from '@angular/compiler';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'angular9-firebaseapp';


}
