import { OwnerComponent } from './component/owner/owner.component';
import { NgModule } from '@angular/core';
import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { PortalModule } from '@angular/cdk/portal';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CdkStepperModule } from '@angular/cdk/stepper';
import { CdkTableModule } from '@angular/cdk/table';
import { CdkTreeModule } from '@angular/cdk/tree';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatStepperModule } from '@angular/material/stepper';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatNativeDateModule, MatRippleModule } from '@angular/material/core';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { OverlayModule } from '@angular/cdk/overlay';
import { BrowserModule } from '@angular/platform-browser';
import { FormsModule } from '@angular/forms';
import { AngularFireModule } from '@angular/fire';
import { AngularFireDatabaseModule } from '@angular/fire/database';
import { environment } from '../environments/environment';
import { AngularFirestoreModule } from '@angular/fire/firestore';
import { CrudService } from './service/crud.service';
import { YouTubePlayerModule } from '@angular/youtube-player';
import { AppComponent } from './app.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { LoginComponent } from './component/login/login.component';
import { AppRoutingModule } from './app-routing.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FooterComponent } from './component/footer/footer.component';
import { FulllayoutComponent } from './component/fulllayout/fulllayout.component';
import { HeaderComponent } from './component/header/header.component';
import { HomeComponent } from './component/home/home.component';
import { StalldocComponent } from './component/stalldoc/stalldoc.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { UserManagementComponent } from './component/user-management/user-management.component';
import { ProfileComponent } from './component/profile/profile.component';
import { VisitorloginComponent } from './component/visitorlogin/visitorlogin.component';
import { VlbodyComponent } from './component/visitorlogin/vlbody/vlbody.component';
import { PfdashComponent } from './component/profile/pfdash/pfdash.component';
import { VfxComponent } from './component/vfx/vfx.component';
import { VfxheaderComponent } from './component/vfx/vfxheader/vfxheader.component';
import { ExprofileComponent } from './component/exprofile/exprofile.component';
import { ExheaderComponent } from './component/exprofile/exheader/exheader.component';
import { ExstalldocupComponent } from './component/exstalldocup/exstalldocup.component';
import { ExstallpopupComponent } from './component/exstallpopup/exstallpopup.component';
import { PfheaderComponent } from './component/profile/pfheader/pfheader.component';
import { ExfooterComponent } from './component/exprofile/exfooter/exfooter.component';
import { HomefooterComponent } from './component/homefooter/homefooter.component';
import { ExhibitorloginComponent } from './component/login/exhibitorlogin/exhibitorlogin.component';
import { LiveComponent } from './component/live/live.component';
import { ExproductupComponent } from './component/exproductup/exproductup.component';
import { ExproductvideoupComponent } from './component/exproductvideoup/exproductvideoup.component';
import { PbrifecaseComponent } from './component/profile/pbrifecase/pbrifecase.component';
import { HomenavComponent } from './component/home/homenav/homenav.component';
import { UsermgmtfooterComponent } from './component/user-management/usermgmtfooter/usermgmtfooter.component';
import { ExbodyComponent } from './component/exprofile/exbody/exbody.component';
import { SocialiconComponent } from './component/socialicon/socialicon.component';
import { FloorplanComponent } from './component/floorplan/floorplan.component';
import { ExresourceComponent } from './component/user-management/exresource/exresource.component';
import { ViewiconComponent } from './component/exprofile/viewicon/viewicon.component';
import { ChaticonComponent } from './component/chaticon/chaticon.component';
import { ScrollToModule } from '@nicky-lenaers/ngx-scroll-to';
import { FilterPipeModule } from 'ngx-filter-pipe';
import { AdminheaderComponent } from './component/admin/adminheader/adminheader.component';
import { AdminsidebarComponent } from './component/admin/adminsidebar/adminsidebar.component';
import { AdminbodyComponent } from './component/admin/adminbody/adminbody.component';
import { AdminvisitorComponent } from './component/admin/adminvisitor/adminvisitor.component';
import { AdminexhibitorComponent } from './component/admin/adminexhibitor/adminexhibitor.component';
import { AdminliveComponent } from './component/admin/adminlive/adminlive.component';
import { AdminactivityComponent } from './component/admin/adminactivity/adminactivity.component';
import { AdminattendComponent } from './component/admin/adminattend/adminattend.component';
import { AdminComponent } from './component/admin/admin.component';
import { AdminloginComponent } from './component/login/adminlogin/adminlogin.component';
import { VisitorregformComponent } from './component/visitorregform/visitorregform.component';
import { VisitoragentformComponent } from './component/visitorregform/visitoragentform/visitoragentform.component';
import { VisitoroverseasformComponent } from './component/visitorregform/visitoroverseasform/visitoroverseasform.component';
import { VisitordomesticformComponent } from './component/visitorregform/visitordomesticform/visitordomesticform.component';
import { ExhibitorsComponent } from './component/exhibitors/exhibitors.component';
import { MapiconComponent } from './component/mapicon/mapicon.component';

import { ExauditoriumComponent } from './component/auditorium/exauditorium/exauditorium.component';
import { AuditoriumComponent } from './component/auditorium/auditorium.component';
import { VisauditoriumComponent } from './component/auditorium/visauditorium/visauditorium.component';

// >>>>>>> f7f24ef8cb4cef950a5420ecc51100e6e414d8fa


@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,

    FooterComponent,
    FulllayoutComponent,
    HeaderComponent,
    HomeComponent,
    StalldocComponent,
    UserManagementComponent,
    ProfileComponent,

    VisitorloginComponent,
    VlbodyComponent,

    PfdashComponent,
    VfxComponent,
    VfxheaderComponent,
    ExprofileComponent,
    ExheaderComponent,
    ExstalldocupComponent,
    ExstallpopupComponent,
    PfheaderComponent,
    ExfooterComponent,
    HomefooterComponent,
    ExhibitorloginComponent,
    LiveComponent,
    ExproductupComponent,
    ExproductvideoupComponent,
    PbrifecaseComponent,
    HomenavComponent,

    UsermgmtfooterComponent,

    ExbodyComponent,
    AuditoriumComponent,
    ExauditoriumComponent,
    SocialiconComponent,

    FloorplanComponent,

    ExresourceComponent,

    ViewiconComponent,

    ChaticonComponent,
    // >>>>>>> f7f24ef8cb4cef950a5420ecc51100e6e414d8fa
    OwnerComponent,
    AdminComponent,
    AdminloginComponent,
    AdminheaderComponent,
    AdminsidebarComponent,
    AdminbodyComponent,
    AdminvisitorComponent,
    AdminexhibitorComponent,
    AdminliveComponent,
    AdminactivityComponent,
    AdminattendComponent,
    VisitorregformComponent,
    VisitoragentformComponent,
    VisitoroverseasformComponent,
    VisitordomesticformComponent,
    ExhibitorsComponent,

    MapiconComponent,

    VisauditoriumComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    YouTubePlayerModule,
    FormsModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFireDatabaseModule,
    AngularFirestoreModule,
    NgbModule,
    PdfViewerModule,
    BrowserAnimationsModule,
    ScrollToModule.forRoot(),
    A11yModule,
    ClipboardModule,
    CdkStepperModule,
    CdkTableModule,
    CdkTreeModule,
    DragDropModule,
    MatAutocompleteModule,
    MatBadgeModule,
    MatBottomSheetModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatStepperModule,
    MatDatepickerModule,
    MatDialogModule,
    MatDividerModule,
    MatExpansionModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatTreeModule,
    OverlayModule,
    PortalModule,
    ScrollingModule,
    FilterPipeModule,
  ],
  providers: [CrudService],
  bootstrap: [AppComponent],
})
export class AppModule {}
