import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { StallService} from 'src/app/service/stall.service'
import { BriefpopupComponent } from './briefpopup/briefpopup.component';
import { CreatepopupComponent } from './createpopup/createpopup.component';
import { ViewpopupComponent } from './viewpopup/viewpopup.component';


@Component({
  selector: 'app-adminvisitor',
  templateUrl: './adminvisitor.component.html',
  styleUrls: ['./adminvisitor.component.scss']
})
export class AdminvisitorComponent implements OnInit {
  customer: any;


  constructor(public StallService:StallService,public dialog: MatDialog) { }

  ngOnInit(): void {
    this.StallService.get_allvisitor().subscribe(data => {
      this.customer = data.map(e => {
        return {
          id: e.payload.doc.id,
          Address: e.payload.doc.data()['Address'],
          CompanyName: e.payload.doc.data()['CompanyName'],
          title: e.payload.doc.data()['title'],
          ref: e.payload.doc.data()['ref'],
          productsofimport: e.payload.doc.data()['CompanyName'],
          product: e.payload.doc.data()['product'],
          importedvalue: e.payload.doc.data()['importedvalue'],
          intrested: e.payload.doc.data()['intrested'],
          email: e.payload.doc.data()['email'],
          name: e.payload.doc.data()['name'],
          Website: e.payload.doc.data()['Website'],
          Telephone: e.payload.doc.data()['Telephone'],
          Mobile: e.payload.doc.data()['Mobile'],
          IndianSupplier: e.payload.doc.data()['IndianSupplier'],
          Designation: e.payload.doc.data()['Designation'],
          countryexport: e.payload.doc.data()['countryexport'],
          Country: e.payload.doc.data()['Country'],
          acess: e.payload.doc.data()['acess'],
          password: e.payload.doc.data()['password'],
          form: e.payload.doc.data()['form'],
          
          lname:e.payload.doc.data()['lname'],
          year:e.payload.doc.data()['year'],
        
           turnover1:e.payload.doc.data()['turnover1'],
           turnover2:e.payload.doc.data()['turnover2'],
           turnover3:e.payload.doc.data()['turnover3'],
          
          otherspecify:e.payload.doc.data()['otherspecify'],
          type:e.payload.doc.data()['type'],
          faxx:e.payload.doc.data()['faxx'],
          volumestitle:e.payload.doc.data()['volumestitle'],


          otherspecify1:e.payload.doc.data()['otherspecify1'],
          otherspecify2:e.payload.doc.data()['otherspecify2'],
          otherspecify3:e.payload.doc.data()['otherspecify3'],
          otherspecify4:e.payload.doc.data()['otherspecify4'],
          otherspecify5:e.payload.doc.data()['otherspecify5'],
          otherspecify6:e.payload.doc.data()['otherspecify6'],

          Tablelinen:e.payload.doc.data()['Tablelinen'],
          Bedlinen:e.payload.doc.data()['Bedlinen'],
          Bathlinen:e.payload.doc.data()['Bathlinen'],
          Kitchenlinen:e.payload.doc.data()['Kitchenlinen'],
          FurnishingArticles:e.payload.doc.data()['FurnishingArticles'],
          Curtains:e.payload.doc.data()['Curtains'],
          FloorCovering:e.payload.doc.data()['FloorCovering'],
          Fabrics:e.payload.doc.data()['Fabrics'],
          FashionAccessories:e.payload.doc.data()['FashionAccessories'],
          option:e.payload.doc.data()['option'],
       

     








           volumes:e.payload.doc.data()['volumes'],

           buyerproduct1:e.payload.doc.data()['buyerproduct1'],
           buyername1:e.payload.doc.data()['buyername1'],

           buyercountry1:e.payload.doc.data()['buyercountry1'],

           buyerproduct2:e.payload.doc.data()['buyerproduct1'],
           buyername2:e.payload.doc.data()['buyername1'],

           buyercountry2:e.payload.doc.data()['buyercountry1'],

           buyerproduct3:e.payload.doc.data()['buyerproduct1'],
           buyername3:e.payload.doc.data()['buyername1'],

           buyercountry3:e.payload.doc.data()['buyercountry1'],


           IndianSupplier1:e.payload.doc.data()['IndianSupplier1'],
           
           IndianSupplier2:e.payload.doc.data()['IndianSupplier2'],
           
           IndianSupplier3:e.payload.doc.data()['IndianSupplier3'],

           tex1:e.payload.doc.data()['tex1'],
           tex2:e.payload.doc.data()['tex2'],
           tex3:e.payload.doc.data()['tex3'],
           requirement:e.payload.doc.data()['requirement'],
           buspart:e.payload.doc.data()['buspart'],
           bustitle:e.payload.doc.data()['bustitle'],
           retail:e.payload.doc.data()['retail'],


           

           volumetitle1:e.payload.doc.data()['volumetitle1'],
           volumetitle2:e.payload.doc.data()['volumetitle2'],
           volumetitle3:e.payload.doc.data()['volumetitle3'],
           inr1:e.payload.doc.data()['inr1'],
           inr2:e.payload.doc.data()['inr2'],
           inr3:e.payload.doc.data()['inr3'],
           brand:e.payload.doc.data()['brand'],
           specify:e.payload.doc.data()['specify'],
     
           a:e.payload.doc.data()['a'],


           represent:e.payload.doc.data()['represent'],
           manufacture:e.payload.doc.data()['manufacture'],
          // fax:e.payload.doc.data()['fax'],

           // fax:e.payload.doc.data()['fax'],
          // fax:e.payload.doc.data()['fax'],
          // fax:e.payload.doc.data()['fax'],


          
          
        
        
        
        };
      })
      // console.log(this.customer)
    })
  }

  updateacessforvis(id,acess){
    this.StallService.updateuserstatus(id,acess)

  }

  openDialog(a,b): void {
    const dialogRef = this.dialog.open(CreatepopupComponent, {
      width: '900px',
      height: '700px',
      data: {email : a , id: b}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  openDialogbrief(a): void {
    // console.log("sddddddddddd")
    // console.log(a)
    const dialogRef = this.dialog.open(BriefpopupComponent, {
      width: '900px',
      height: '700px',
      data:{id:a}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }


  openDialogview(a): void {
  
   
    const dialogRef = this.dialog.open(ViewpopupComponent, {
      width: '900px',
      height: '700px',
     data:{id:a}
    });
  
    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed');
    });
  }

}
